import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        images: {
            height: '100%',
            '& *': {
                outline: 'none',
                height: '100%',
            },
            '& .slick-prev, & .slick-next': {
                zIndex: 1,
            },
            '& .slick-prev': {
                left: 12.5,
                transform: 'translateY(-75%)',
            },
            '& .slick-next': {
                right: 12.5,
                transform: 'translateY(-75%)',
            },
            '& .slick-arrow': {
                opacity: 0,
                tranisiton: '.3s',
                height: 'unset',
                '& *': {
                    height: 'unset',
                },
            },
            '&:hover .slick-arrow': {
                opacity: 1,
            },
            '& .slick-arrow::before': {
                content: 'unset',
            },
            '& .slick-arrow.slick-disabled': {
                cursor: 'default',
            },
            '& .slick-arrow.slick-disabled button': {
                transition: '.3s transform',
                transform: 'scale(0)',
            },
            '& img, & video': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: '.3s',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
            },
        },
        arrow: {
            color: '#fff',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    };
});
