import { darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        container: {
            width: 'calc(100% + 2px)',
            margin: -1,
            '& .MuiGrid-item': {
                padding: 1,
            },
        },
        image: {
            position: 'relative',

            '& img, & video': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: '.3s filter',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
            },
        },
        spinner: {
            width: '24px !important',
            height: '24px !important',
        },
        deleteButton: {
            position: 'absolute',
            padding: 6,
            paddingRight: 4,
            paddingTop: 4,
            top: 0,
            right: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
            '&:hover': {
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.1)
                    : darken(theme.palette.background.paper, 0.1),
            },
        },
        reorderIconContainer: {
            display: 'flex',
            position: 'absolute',
            padding: 8,
            paddingLeft: 6,
            top: 0,
            left: 0,
            borderBottomRightRadius: 8,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
        },
        uploader: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
            cursor: 'pointer',
            transition: '.3s background-color',
            '&:hover': {
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.1)
                    : darken(theme.palette.background.paper, 0.1),
            },
        },
    };
});
