import { Box, Button, Card, SvgIcon, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as TrashCanOutline } from 'assets/community-icons/trash-can-outline.svg';
import { ActionMenu, Actions } from 'components/action-menu';
import { BookmarkEventButton } from 'components/bookmark-event-button';
import { EventPayment } from 'components/event-payment';
import { EventSlider } from 'components/event-slider';
import { EventTags } from 'components/event-tags';
import { Link } from 'components/link';
import { RatioBox } from 'components/ratio-box';
import { RepostEventButton } from 'components/repost-event-button';
import { useAuthState } from 'context/auth/store';
import { useNotifications } from 'context/NotificationsContext';
import { useSettings } from 'context/SettingsContext';
import { Event, useDeleteEventMutation } from 'graphql/generated';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import browserHistory from 'utils/browser-utils';
import { getTranslationContent } from 'utils/translation-helper';
import { EventContent } from './event-content';
import { useStyles } from './event-item.style';
import { EventSliderFooter } from './event-slider-footer';

type EventItemProps = { event: Event; upcoming?: boolean; onDelete?: (eventId: string) => void };

export const EventItem: React.FC<EventItemProps> = ({ event, onDelete }) => {
    const classes = useStyles();
    const images = event.images.edges.map(({ node }) => node.image);
    const { language } = useSettings();
    const translations = event.translations.edges.map(({ node }) => node);
    const { isManager, user } = useAuthState();
    const hasActionPermissions = isManager || event.createdById === user?.id;
    const { confirm, notify } = useNotifications();
    const { title, description, address } = getTranslationContent({
        translations,
        language,
        requiredFields: ['title', 'description'],
    });

    const [deleteEvent] = useDeleteEventMutation({
        onCompleted({ deleteEvent }) {
            onDelete?.(deleteEvent.id);
            notify({
                type: 'success',
                message: 'Event deleted succesfully!',
                duration: 1000,
            });
        },
    });

    const handleDelete = () => {
        confirm({
            onConfirm: () => deleteEvent({ variables: { id: event.id } }),
            title: 'Delete Event',
            confirmText: 'Are you sure you want to delete this Event?',
        });
    };

    const handleEdit = () => {
        browserHistory.push(`/d/event/${event.id}/edit`);
    };

    const actions: Actions = [
        {
            primaryText: 'Edit event',
            startIcon: <EditIcon />,
            onClick: handleEdit,
            hidden: !hasActionPermissions,
        },
        {
            primaryText: 'Delete event',
            startIcon: <SvgIcon component={TrashCanOutline} />,
            onClick: handleDelete,
            hidden: !hasActionPermissions,
            divider: true,
        },
    ];

    const shouldShowPayment = event.price !== null && event.price !== undefined;
    const portalUUID = uniqueId('event-portal');

    const upcoming = event.startTime && DateTime.fromISO(event.startTime).diffNow('seconds').seconds > 0;

    return (
        <Card className={classes.root}>
            <Box display="flex" className={classes.headerContainer} alignItems="center" justifyContent="space-between">
                <Box className={classes.title}>
                    <Typography variant="h4">{upcoming ? 'Upcoming' : 'Past'} events</Typography>
                    <Typography className={classes.city} variant="subtitle1">
                        {address}
                    </Typography>
                </Box>
                <RepostEventButton eventId={event.id} type={event.type} />
                <BookmarkEventButton eventId={event.id} isBookmarked={event.isBookmarked} />
                {hasActionPermissions && <ActionMenu actions={actions} />}
            </Box>
            <RatioBox>
                <Link to={`/d/event/${event.id}`}>
                    <EventSlider portalID={portalUUID} files={images} ligthBoxDisabled />
                </Link>
            </RatioBox>
            <EventSliderFooter portalID={portalUUID} eventId={event.id} startTime={event.startTime} />
            <Box className={classes.detail}>
                <EventContent title={title} description={description} />
                <EventTags tags={event.tags} />
            </Box>

            {shouldShowPayment && (
                <Box mx={2} my={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="h5" style={{ fontSize: '1.1rem', fontWeight: 700 }}>
                        {event.price && event.price > 0 ? `${event?.price} €` : 'free'}
                    </Typography>

                    <EventPayment id={event.id} title={title} price={event.price} purchased={event.purchased} />
                </Box>
            )}

            {!shouldShowPayment && event.externalLink && (
                <Box mx={2} my={2} display="flex" justifyContent="flex-end">
                    <a
                        href={event.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'unset', textDecoration: 'none' }}
                    >
                        <Button size="small" variant="outlined" color="primary">
                            Buy ticket
                        </Button>
                    </a>
                </Box>
            )}
        </Card>
    );
};
