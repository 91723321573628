import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { LoadingButton, Spinner } from 'components';
import { useAuthState } from 'context/auth/store';
import { useUserCommunitiesState } from 'context/user-communities/store';
import { Formik } from 'formik';
import {
    LanguageCode,
    MemberStatus,
    Post,
    PostVisibility,
    SharePostInput,
    useSharePostMutation,
} from 'graphql/generated';
import React from 'react';
import { ReadonlyPostItem } from '../post-item';
import { UserHeader } from '../user-header';
import { SharePostDialogForm } from './share-post-dialog-form';
import { useStyles } from './share-post-dialog.style';

type SharePostDialogProps = {
    post: Post;
    open: boolean;
    onClose: () => void;
};

export const SharePostDialog: React.FC<SharePostDialogProps> = ({ post, open, onClose }) => {
    const classes = useStyles();
    const { user, isManager } = useAuthState();
    const { communityId, communitiesLoading, communitiesLoadingError } = useUserCommunitiesState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const canPostPublicly = isManager || user?.member?.status === MemberStatus.Approved;

    const [sharePost, { loading }] = useSharePostMutation({
        onCompleted() {
            onClose();
        },
    });

    if (communitiesLoading) {
        return (
            <Dialog classes={{ paper: classes.dialog }} open={open} fullScreen={isMobile}>
                <Spinner style={{ width: '100%', height: 500 }} />
            </Dialog>
        );
    }

    if (!!communitiesLoadingError) {
        return (
            <Dialog classes={{ paper: classes.dialog }} open={open} fullScreen={isMobile}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h4">There was a problem. Please try again.</Typography>
                </Box>
            </Dialog>
        );
    }

    const initialValues: SharePostInput = {
        communityId,
        files: [],
        visibility: canPostPublicly ? PostVisibility.Public : PostVisibility.Followers,
        postId: post.id,
        translations: isManager
            ? Object.values(LanguageCode).map((lang) => ({ lang, body: '' }))
            : [{ lang: LanguageCode.En, body: '' }],
    };

    const onSubmit = (values): void => {
        sharePost({ variables: { post: values } });
    };

    return (
        <Dialog classes={{ paper: classes.dialog }} open={open} fullScreen={isMobile}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ submitForm, errors, touched }): React.ReactElement => (
                    <>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Box className={classes.dialogHeader}>
                                <Box width={36} />
                                <Typography variant="h4">Share Post</Typography>
                                <IconButton size="small" disabled={loading} onClick={onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Box>
                            <Box>
                                <Divider className={classes.divider} />
                                <UserHeader />
                            </Box>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <SharePostDialogForm {...{ errors, touched }} />
                            <ReadonlyPostItem post={post} />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <LoadingButton
                                loading={loading}
                                text="Share"
                                loadingText="Sharing"
                                size="large"
                                color="primary"
                                style={{ marginLeft: 0 }}
                                onClick={submitForm}
                                variant="contained"
                                fullWidth
                            />
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};
