import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'config';
import { cardP, cardPMobile } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            overflow: 'visible',
            paddingBottom: theme.spacing(2),
            width: '100%',
            [theme.breakpoints.down(mobileSize)]: {
                borderRadius: 0,
                backgroundColor: theme.palette.background.default,
                boxShadow: 'none',
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        body: {
            ...theme.typography.body1,
            fontWeight: 500,
            wordWrap: 'break-word',
            paddingTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            display: 'inline',
        },
        bodyWithoutFilesContainer: {
            padding: theme.spacing(cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(cardPMobile),
            },
        },
        bodyWithoutFiles: {
            marginLeft: 0,
            paddingTop: 0,
            [theme.breakpoints.down(mobileSize)]: {
                padding: 0,
            },
        },
        footer: {
            padding: theme.spacing(0, cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, cardPMobile),
            },
        },
        actions: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: theme.spacing(1),
            },
        },
        comments: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: theme.spacing(2),
            },
        },
        publishDate: {
            marginTop: theme.spacing(2),
            fontStyle: 'italic',
            fontWeight: 400,
        },
    };
});
