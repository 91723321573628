import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { CroppableImage } from 'components/formik/gallery-field/croppable-image';
import { LoadingButton } from 'components/loading-button';
import { RatioBox } from 'components/ratio-box';
import { imageTypes, videoTypes } from 'config';
import React, { useCallback, useRef, useState } from 'react';
import { useStyles } from './media-upload-dialog.style';

const fileStyle: React.CSSProperties = { width: '100%', height: '100%', objectFit: 'cover' };

type MediaUploadDialogProps = Omit<DialogProps, 'onSubmit'> & {
    onSubmit?: (files: File[]) => void;
    loading?: boolean;
};

export const MediaUploadDialog: React.FC<MediaUploadDialogProps> = ({ onSubmit, loading, ...props }) => {
    const classes = useStyles();
    const [files, setFiles] = useState<File[]>([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const onSaveCrop = useCallback(
        (croppedFile: File | null, index: number) => {
            if (croppedFile) {
                const newFiles = [...files];
                newFiles[index] = croppedFile;
                setFiles(newFiles);
            }
        },
        [files],
    );

    const openFileSelector = () => !loading && inputRef.current?.click();

    return (
        <Dialog maxWidth="md" fullWidth {...props} TransitionProps={{ onExited: () => setFiles([]) }}>
            <DialogTitle disableTypography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3">Upload images</Typography>
                    <IconButton disabled={loading} size="small" onClick={() => props.onClose?.({}, 'backdropClick')}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <input
                        accept={[...imageTypes, ...videoTypes].join(', ')}
                        type="file"
                        hidden
                        ref={inputRef}
                        multiple
                        onChange={(e) => {
                            if (e.target.files) {
                                const newFiles = Array.from(e.target.files);
                                setFiles((prev) => [...prev, ...newFiles]);
                            }
                        }}
                    />
                    <Grid item xs={6} sm={4} md={3}>
                        <RatioBox ratio={1}>
                            <Box
                                onClick={openFileSelector}
                                className={clsx(classes.uploader, loading && classes.uploaderDisabled)}
                            >
                                <AddIcon />
                            </Box>
                        </RatioBox>
                    </Grid>
                    {files.map((file, i) => (
                        <Grid item xs={6} sm={4} md={3} key={i}>
                            <RatioBox ratio={1}>
                                <Box className={classes.imageContainer}>
                                    {videoTypes.includes(file.type) ? (
                                        <video style={fileStyle} src={URL.createObjectURL(file)} />
                                    ) : (
                                        <CroppableImage
                                            image={file}
                                            index={i}
                                            onSaveCrop={onSaveCrop}
                                            disabled={loading}
                                        />
                                    )}
                                    <IconButton
                                        onClick={(): void => {
                                            const filteredImages = files.filter((_, index) => index !== i);
                                            setFiles(filteredImages);
                                        }}
                                        disabled={loading}
                                        disableRipple
                                        color="secondary"
                                        size="small"
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </RatioBox>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    text="Upload"
                    loadingText="Uploading"
                    onClick={() => onSubmit?.(files)}
                    loading={!!loading}
                />
            </DialogActions>
        </Dialog>
    );
};
