import { Box, Button } from '@material-ui/core';
import { EventPaymentDialog } from 'components';
import { Event, EventTranslation } from 'graphql/generated';
import React, { useCallback, useState } from 'react';

type EventPaymentProps = {
    id: Event['id'];
    title: EventTranslation['title'];
    price: Event['price'];
    purchased: boolean;
};

export const EventPayment: React.FC<EventPaymentProps> = ({ id, title, price, purchased }) => {
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [booked, setBooked] = useState<boolean>(purchased);

    const hasPrice = price !== undefined && price !== null;
    const isFree = price === 0;

    const handlePayment = useCallback(() => setPaymentDialogOpen(true), []);

    const closePaymentDialog = useCallback(() => setPaymentDialogOpen(false), []);

    if (isFree || !hasPrice) {
        return null;
    }

    if (booked) {
        return (
            <Button size="small" variant="outlined" color="primary" disabled>
                Booked
            </Button>
        );
    }

    return (
        <Box display="flex" justifyContent="center">
            <Box display="flex" alignItems="center" flexDirection="column">
                <Button size="small" variant="outlined" color="primary" onClick={handlePayment}>
                    Buy ticket
                </Button>
                <EventPaymentDialog
                    open={paymentDialogOpen}
                    onClose={closePaymentDialog}
                    eventId={id}
                    productName={title}
                    setBooked={setBooked}
                    price={price}
                />
            </Box>
        </Box>
    );
};
