import { Box, IconButton, IconButtonProps, SvgIcon } from '@material-ui/core';
import { SharePostDialog } from 'components/post/share-post-dialog';
import { usePostForEventLazyQuery, Post, Event } from 'graphql/generated';
import { useState } from 'react';
import React from 'react';
import { ReactComponent as SendIcon } from 'assets/community-icons/export-variant.svg';

type EventItemProps = Omit<IconButtonProps, 'type'> & { eventId: Event['id']; type: Event['type'] };

export const RepostEventButton: React.FC<EventItemProps> = ({ eventId, type, ...props }) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const openShareDialog = (): void => setShareDialogOpen(true);
    const closeShareDialog = (): void => setShareDialogOpen(false);

    const [getPostForEvent, { data }] = usePostForEventLazyQuery({
        onCompleted() {
            openShareDialog();
        },
    });
    const postForEvent = data?.postForEvent;
    return (
        <Box>
            <IconButton
                size="small"
                onClick={() => getPostForEvent({ variables: { eventId, eventType: type } })}
                {...props}
            >
                <SvgIcon component={SendIcon} />
            </IconButton>
            {postForEvent && (
                <SharePostDialog post={postForEvent as Post} open={shareDialogOpen} onClose={closeShareDialog} />
            )}
        </Box>
    );
};
