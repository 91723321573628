import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { Helmet } from 'react-helmet';
import { useStyles } from './page.style';

export const Page: React.FC<HTMLAttributes<HTMLDivElement> & { noPadding?: boolean; noMobilePadding?: boolean }> = ({
    children,
    noPadding = false,
    noMobilePadding = false,
    title = '',
    className,
    ...props
}) => {
    const classes = useStyles();

    const pageTitle = title ? `THE - ${title}` : 'THE';

    return (
        <div
            className={clsx(
                classes.root,
                noPadding && classes.noPadding,
                noMobilePadding && classes.noMobilePadding,
                className,
            )}
            {...props}
        >
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {children}
        </div>
    );
};
