import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down(mobileSize)]: {
                paddingBottom: theme.spacing(2),
                paddingTop: theme.spacing(2),
            },
        },
        noPadding: {
            padding: 0,
        },
        noMobilePadding: {
            [theme.breakpoints.down(mobileSize)]: {
                padding: 0,
            },
        },
    };
});
