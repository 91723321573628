import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        filters: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            maxWidth: '100%',
            overflowX: 'auto',
            '& .MuiChip-root': {
                marginRight: 12,
                marginBottom: 12,
                cursor: 'pointer',
                '&:last-child': {
                    margin: 0,
                },
            },
        },
    };
});
