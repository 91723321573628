import { Box, Button, Grid } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { CircleIcon, EmptyState, EventSkeleton } from 'components';
import { EventItem } from 'components/event-item';
import { Event, PageInfo } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';

type EventListProps = {
    events: Event[];
    loading: boolean;
    onDeleteEvent?: (eventId: string) => void;
    pageInfo?: PageInfo;
    onLoadMore?: () => void;
    emptyStateText?: string;
};

export const EventList: React.FC<EventListProps> = ({
    events,
    loading,
    onDeleteEvent,
    pageInfo,
    onLoadMore,
    emptyStateText,
}) => {
    const { t } = useTranslation();

    const isEmpty = !loading && events.length === 0;
    const canLoadMore = pageInfo?.hasNextPage && !loading;

    if (isEmpty) {
        return (
            <EmptyState
                media={<CircleIcon radius={80} icon={<EventBusyIcon style={{ fontSize: 70 }} />} />}
                primaryText={emptyStateText || t('emptyState:event')}
            />
        );
    }

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Grid container spacing={3}>
                {events.map((event) => (
                    <Grid key={event.id} item xs={12}>
                        <EventItem onDelete={onDeleteEvent} event={event} />
                    </Grid>
                ))}
                {loading && (
                    <>
                        {Array(5)
                            .fill(0)
                            .map((_, index) => (
                                <Grid key={index} item xs={12}>
                                    <EventSkeleton />
                                </Grid>
                            ))}
                    </>
                )}
                {canLoadMore && (
                    <Grid item xs={12} container justifyContent="center">
                        <Button onClick={onLoadMore} color="primary">
                            Load more
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
