import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        searchBar: {
            width: '100%',
            '& .MuiInputBase-root': {
                '& fieldset': {
                    border: 'none',
                },
                '& input': {
                    fontSize: 16,
                    padding: 14,
                },
            },
        },
        searchIcon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5),
            pointerEvents: 'none',
        },
    };
});
