import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        button: {
            ...theme.typography.h5,
            width: '100%',
            padding: theme.spacing(2, 2.5),
            borderRadius: 0,
            boxShadow: theme.shadows[1],
        },
        label: {
            marginBottom: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        details: {
            padding: theme.spacing(4, 3),
        },
    };
});
