import { Box, Typography } from '@material-ui/core';
import { EventTranslation } from 'graphql/generated';
import { uniqueId } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './event-content.style';

type EventContentProps = { title: EventTranslation['title']; description: EventTranslation['description'] };

export const EventContent: React.FC<EventContentProps> = ({ title, description }) => {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState<boolean>(true);

    const uuid = uniqueId('collapse-button');
    const detailsRef = useRef<HTMLDivElement>(null);

    const normalizedDescription = useMemo(() => {
        if (description.length < 150) {
            return description;
        } else {
            if (collapsed) {
                return description.replace(/<\/?a[^>]*>/g, '').slice(0, 150) + `<a id="${uuid}"> ...more</a>`;
            } else {
                return description + `<a id="${uuid}"> show less</a>`;
            }
        }
    }, [collapsed, description, uuid]);

    useLayoutEffect(() => {
        const collapseButton = document.getElementById(uuid);
        if (collapseButton) {
            const previousElementSibling = collapseButton.previousElementSibling;
            if (previousElementSibling) {
                (collapseButton.previousElementSibling as any).style.display = 'inline';
            }
            collapseButton.className = classes.showMore;
            collapseButton.addEventListener('click', () => {
                if (collapsed) {
                    if (detailsRef.current) {
                        const y = (detailsRef.current.getBoundingClientRect().top || 0) + window.pageYOffset - 92;
                        setTimeout(() => window.scrollTo({ top: y, behavior: 'smooth' }), 100);
                    }
                }
                setCollapsed((prev) => !prev);
            });
        }
    }, [collapsed, description, uuid, classes]);

    return (
        <div ref={detailsRef} className={classes.detail}>
            <Typography className={classes.title} variant="h2">
                {title}
            </Typography>
            <Box className={classes.description} dangerouslySetInnerHTML={{ __html: normalizedDescription }} />
        </div>
    );
};
