import { Box, Card, Grid, Icon, Typography } from '@material-ui/core';
import { RatioBox, Slider } from 'components';
import { VideoPlayer } from 'components/video-player';
import { imageExtensions, videoExtensions } from 'config';
import { Post } from 'graphql/generated';
import React from 'react';

type DefaultPostContentProps = {
    post: Post;
    portalPrefix?: string;
};

export const DefaultPostContent: React.FC<DefaultPostContentProps> = ({ post, portalPrefix }) => {
    const { files } = post;

    const fileNodes = files.edges.map(({ node }) => node) || [];

    const photos = fileNodes.filter((file) => imageExtensions.includes(file.extension));

    const videos = fileNodes
        .filter((file) => videoExtensions.includes(file.extension))
        .map((video) => ({ id: video.id, url: video.url }));

    const attachments = fileNodes
        .filter((file) => !imageExtensions.includes(file.extension) && !videoExtensions.includes(file.extension))
        .map((file) => file);

    return (
        <Box display="flex" flexDirection="column">
            {(photos.length > 0 || attachments.length > 0 || videos.length > 0) && (
                <Box display="flex" flexDirection="column">
                    <Grid container spacing={1}>
                        {photos.length > 0 && (
                            <Grid item xs={12}>
                                <Slider
                                    files={photos}
                                    portalID={`${portalPrefix || ''}post-portal-${post.id}`}
                                    ratio={1}
                                />
                            </Grid>
                        )}
                        {videos.length > 0 && (
                            <Grid item xs={12}>
                                <Box>
                                    <Grid container spacing={1}>
                                        {videos.map((video) => (
                                            <Grid key={video.id} item xs={12} sm={videos.length > 1 ? 6 : 12}>
                                                <RatioBox>
                                                    <VideoPlayer light={false} url={video.url} />
                                                </RatioBox>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                        {attachments.length > 0 && (
                            <Grid item xs={12}>
                                <Box p={2}>
                                    <Grid container spacing={1}>
                                        {attachments.map((attachment) => (
                                            <Grid item xs={12} sm={6} key={attachment.id}>
                                                <a
                                                    style={{ textDecoration: 'none' }}
                                                    target="_blank"
                                                    href={attachment.url}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Card style={{ borderRadius: 4 }} variant="outlined">
                                                        <Box p={1.5} display="flex" alignItems="center">
                                                            <Icon fontSize="small">attach_file</Icon>
                                                            <Typography
                                                                style={{
                                                                    wordBreak: 'break-all',
                                                                    marginLeft: 8,
                                                                }}
                                                            >
                                                                {attachment.name}
                                                            </Typography>
                                                        </Box>
                                                    </Card>
                                                </a>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};
