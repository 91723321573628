import { Box, BoxProps, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { PartnerCategory } from 'graphql/generated';
import React from 'react';
import { useStyles } from './partner-category-chip-selector.style';

export enum PartnerCategorySorted {
    Hotel = 'HOTEL',
    Restaurant = 'RESTAURANT',
    Healthcare = 'HEALTHCARE',
    Shop = 'SHOP',
    Other = 'OTHER',
}

type PartnerCategoryChipSelectorProps = Omit<BoxProps, 'onChange'> & {
    selectedCategories: PartnerCategory[];
    onChange: (categories: PartnerCategory[]) => void;
};

export const PartnerCategoryChipSelector: React.FC<PartnerCategoryChipSelectorProps> = ({
    selectedCategories,
    onChange,
    className,
    ...props
}) => {
    const classes = useStyles();

    const onChipClick = (category: string) => {
        const value: PartnerCategory = PartnerCategorySorted[category];
        const isSelected = selectedCategories.includes(value);
        if (isSelected) {
            onChange(selectedCategories.filter((c) => c !== value));
        } else {
            onChange([...selectedCategories, value]);
        }
    };

    return (
        <Box className={clsx(classes.filters, className)} {...props}>
            {Object.keys(PartnerCategorySorted).map((category) => {
                const value = PartnerCategorySorted[category];
                const isSelected = selectedCategories.includes(value);
                return (
                    <Chip
                        key={category}
                        color={isSelected ? 'primary' : 'default'}
                        label={category}
                        onClick={() => onChipClick(category)}
                    />
                );
            })}
            {selectedCategories.length > 0 && <Chip variant="outlined" label="Clear" onClick={() => onChange([])} />}
        </Box>
    );
};
