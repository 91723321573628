import { Box, Button, Grid, GridProps } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { CircleIcon, EmptyState, UserListItem } from 'components';
import { UserListItemProps } from 'components/user-list-item';
import { UserSkeleton } from 'components/user-skeleton';
import { PageInfo, User } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';

type UserListProps = {
    users: User[];
    loading: boolean;
    onDeleteEvent?: (eventId: string) => void;
    pageInfo?: PageInfo;
    onLoadMore?: () => void;
    ListItemProps?: Omit<UserListItemProps, 'user' | 'right'>;
    GridItemSize?: {
        xs?: GridProps['xs'];
        sm?: GridProps['sm'];
        md?: GridProps['md'];
        lg?: GridProps['lg'];
        xl?: GridProps['xl'];
    };
};

export const UserList: React.FC<UserListProps> = ({
    users: users,
    loading,
    pageInfo,
    onLoadMore,
    ListItemProps,
    GridItemSize,
}) => {
    const { t } = useTranslation();

    const isEmpty = !loading && users.length === 0;
    const canLoadMore = pageInfo?.hasNextPage && !loading;

    if (isEmpty) {
        return (
            <EmptyState
                media={<CircleIcon radius={80} icon={<PersonIcon style={{ fontSize: 70 }} />} />}
                primaryText={t('emptyState:noUsersFound')}
            />
        );
    }

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Grid container spacing={1}>
                {users.map((user) => (
                    <Grid key={user.id} item xs={12} {...GridItemSize}>
                        <UserListItem user={user} {...ListItemProps} />
                    </Grid>
                ))}
                {loading && (
                    <>
                        {Array(3)
                            .fill(0)
                            .map((_, index) => (
                                <Grid key={index} item xs={12} {...GridItemSize}>
                                    <UserSkeleton />
                                </Grid>
                            ))}
                    </>
                )}
                {canLoadMore && (
                    <Grid item xs={12} container justifyContent="center">
                        <Button onClick={onLoadMore} color="primary">
                            Load more
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
