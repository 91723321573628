import { Box, Card, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { RatioBox } from 'components/ratio-box';
import { mobileSize } from 'layout-config';
import React from 'react';
import { useStyles } from './event-skeleton.style';

export const EventSkeleton: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));

    return (
        <Card className={classes.root}>
            <Box className={classes.header}>
                <Skeleton variant="text" width={120} height={25} />
                <Skeleton variant="text" width={70} height={25} style={{ marginLeft: 40 }} />
            </Box>
            <RatioBox>
                <Skeleton variant="rect" width="100%" height="100%" />
            </RatioBox>
            <Box className={classes.footer}>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Skeleton variant="circle" width={8} height={8} style={{ margin: '0 2px' }} />
                            <Skeleton variant="circle" width={8} height={8} style={{ margin: '0 2px' }} />
                            <Skeleton variant="circle" width={8} height={8} style={{ margin: '0 2px' }} />
                            <Skeleton variant="circle" width={8} height={8} style={{ margin: '0 2px' }} />
                            <Skeleton
                                variant="circle"
                                width={8}
                                height={8}
                                style={{ margin: '0 2px', transform: 'scale(0.5)' }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        {!isMobile && (
                            <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                                <Skeleton variant="rect" width={120} height={36} style={{ borderRadius: 16 }} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Skeleton variant="text" width="40%" height={25} style={{ marginBottom: 16 }} />
                    <Skeleton variant="text" width="30%" height={25} style={{ marginBottom: 12 }} />
                    <Skeleton variant="text" width="100%" height={25} />
                    <Skeleton variant="text" width="70%" height={25} />
                </Box>
                {isMobile && (
                    <Box mt={3}>
                        <Skeleton variant="rect" width="100%" height={36} style={{ borderRadius: 16 }} />
                    </Box>
                )}
            </Box>
        </Card>
    );
};
