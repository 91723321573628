import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { CircleIcon, EmptyState, ExperienceSkeleton } from 'components';
import { ExperienceItem } from 'components/experience-item';
import { ExperienceItemMobile } from 'components/experience-item-mobile';
import { Event, PageInfo } from 'graphql/generated';
import { mobileSize } from 'layout-config';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ExperienceListProps = {
    experiences: Event[];
    loading: boolean;
    onDeleteExperience?: (eventId: string) => void;
    pageInfo?: PageInfo;
    onLoadMore?: () => void;
    emptyStateText?: string;
};

export const ExperienceList: React.FC<ExperienceListProps> = ({
    experiences,
    loading,
    onDeleteExperience,
    pageInfo,
    onLoadMore,
    emptyStateText,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));

    const isEmpty = !loading && experiences.length === 0;
    const canLoadMore = pageInfo?.hasNextPage && !loading;

    if (isEmpty) {
        return (
            <EmptyState
                media={<CircleIcon radius={80} icon={<EventBusyIcon style={{ fontSize: 70 }} />} />}
                primaryText={emptyStateText || t('emptyState:experiences')}
            />
        );
    }

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Grid container spacing={3}>
                {experiences.map((experience) => (
                    <Grid key={experience.id} item xs={12}>
                        {isMobile ? (
                            <ExperienceItemMobile onDelete={onDeleteExperience} experience={experience as Event} />
                        ) : (
                            <ExperienceItem experience={experience as Event} />
                        )}
                    </Grid>
                ))}
                {loading && (
                    <>
                        {Array(isMobile ? 5 : 2)
                            .fill(0)
                            .map((_, index) => (
                                <Grid key={index} item xs={12}>
                                    <ExperienceSkeleton />
                                </Grid>
                            ))}
                    </>
                )}
                {canLoadMore && (
                    <Grid item xs={12} container justifyContent="center">
                        <Button onClick={onLoadMore} color="primary">
                            Load more
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
