import { Card } from '@material-ui/core';
import { Link } from 'components/link';
import { Post } from 'graphql/generated';
import React from 'react';
import { PostItem } from '../post-item';
import { useStyles } from './readonly-post-item.style';

type ReadonlyPostItemProps = {
    post: Post;
    readonly?: boolean;
    portalPrefix?: string;
};

export const ReadonlyPostItem: React.FC<ReadonlyPostItemProps> = ({ post, portalPrefix, readonly = true }) => {
    const classes = useStyles();

    return (
        <>
            {readonly ? (
                <Card variant="outlined" elevation={0} className={classes.root}>
                    <PostItem post={post} hideActions hideComments portalPrefix={portalPrefix} />
                </Card>
            ) : (
                <Link to={`/d/post/${post.id}`} style={{ overflow: 'hidden' }}>
                    <Card variant="outlined" elevation={0} className={classes.root}>
                        <PostItem post={post} hideActions hideComments portalPrefix={portalPrefix} />
                    </Card>
                </Link>
            )}
        </>
    );
};
