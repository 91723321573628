import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        actions: {
            marginLeft: -6,
            '& .MuiIconButton-root:not(:last-child)': {
                marginRight: theme.spacing(0.3),
            },
        },
        likeActive: {
            width: 22,
            height: 22,
            '& path, & circle, & rect:not(:last-child)': {
                fill: theme.palette.error.main,
            },
        },
        likeCount: {
            fontStyle: 'italic',
            fontSize: '1rem',
        },
        icon: {
            width: 22,
            height: 22,
            '& path, & circle, & rect:not(:last-child)': {
                fill: isDarkMode ? theme.palette.text.primary : theme.palette.text.secondary,
            },
        },
    };
});
