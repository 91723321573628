import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

type ContactItemProps = {
    href: string;
    icon: React.ReactNode;
    title: string;
};

export const ContactItem: React.FC<ContactItemProps> = ({ href, icon, title }) => {
    return (
        <a href={href} target="_blank" rel="noreferrer noopener">
            <ListItem button>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        </a>
    );
};
