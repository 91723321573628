import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Grid, IconButton, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import { ReactComponent as ImageRemoveIcon } from 'assets/community-icons/image-remove.svg';
import { CircleIcon, EmptyState, Lightbox, MediaUploadDialog, RatioBox, Spinner } from 'components';
import { BlurhashImage } from 'components/image-blurhash';
import { imageExtensions, mobileSize, videoExtensions } from 'config';
import { FileInfo } from 'graphql/generated';
import React, { useState } from 'react';
import { VideoItem } from './components';
import { useStyles } from './gallery.style';

type GalleryProps = {
    files: FileInfo[];
    deletable?: boolean;
    onDelete?: (id: string) => void;
    deleting?: boolean;
    onMediaAdded?: (
        files: File[],
        closeUploadDialog: () => void,
    ) => Promise<void> | ((files: File[], closeUploadDialog: () => void) => void);
    mediaUploading?: boolean;
    editModeEnabled?: boolean;
    onSortEnd?: (files: FileInfo[]) => void;
    sortDisabled?: boolean;
};

const INITIAL_LIGHTBOX_STATE = { open: false, index: 0 };

export const Gallery: React.FC<GalleryProps> = ({
    editModeEnabled,
    files,
    deletable,
    deleting,
    onDelete,
    onMediaAdded,
    mediaUploading,
    onSortEnd,
    sortDisabled,
}) => {
    const classes = useStyles();

    const images = files.filter((file) => imageExtensions.includes(file.extension));

    const [lightboxState, setLightboxState] = useState(INITIAL_LIGHTBOX_STATE);
    const [mediaUploadDialogOpen, setMediaUploadDialogOpen] = useState(false);

    const [sorting, setSorting] = useState(false);

    const openMediaUploadDialog = () => setMediaUploadDialogOpen(true);
    const closeMediaUploadDialog = () => setMediaUploadDialogOpen(false);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 200,
                tolerance: 0,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
            scrollBehavior: 'smooth',
        }),
    );

    if (files.length === 0 && !editModeEnabled) {
        return (
            <EmptyState
                media={
                    <CircleIcon radius={80} icon={<SvgIcon component={ImageRemoveIcon} style={{ fontSize: 70 }} />} />
                }
                primaryText="No images uploaded yet"
            />
        );
    }

    return (
        <div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => {
                    const { active, over } = event;

                    if (active.id !== over?.id) {
                        const oldIndex = files.findIndex((image) => image.id === active.id);
                        const newIndex = files.findIndex((image) => image.id === over?.id);

                        const sortedFiles = arrayMove(files, oldIndex, newIndex);
                        onSortEnd?.(sortedFiles);
                    }
                    setSorting(false);
                }}
                onDragStart={() => setSorting(true)}
            >
                <SortableContext items={files} disabled={!editModeEnabled || sortDisabled}>
                    <SortableFileList
                        files={files}
                        onClickMedia={(file) => {
                            if (editModeEnabled) {
                                return;
                            }
                            const isVideo = videoExtensions.includes(file.extension);
                            if (isVideo) {
                                window.open(file.url, '_blank');
                            } else {
                                const imageIndex = images.findIndex((image) => image.id === file.id);
                                setLightboxState({ open: true, index: imageIndex });
                            }
                        }}
                        deletable={deletable}
                        deleting={deleting}
                        onDelete={onDelete}
                        editModeEnabled={editModeEnabled}
                        sorting={sorting}
                        sortDisabled={sortDisabled}
                    >
                        {editModeEnabled && (
                            <>
                                <Grid item xs={4}>
                                    <RatioBox ratio={1}>
                                        <Box onClick={openMediaUploadDialog} className={classes.uploader}>
                                            <AddIcon fontSize="large" />
                                        </Box>
                                    </RatioBox>
                                </Grid>
                                <MediaUploadDialog
                                    open={mediaUploadDialogOpen}
                                    onClose={closeMediaUploadDialog}
                                    loading={mediaUploading}
                                    onSubmit={(files) => onMediaAdded?.(files, closeMediaUploadDialog)}
                                />
                            </>
                        )}
                    </SortableFileList>
                </SortableContext>
            </DndContext>
            {lightboxState.open && (
                <Lightbox
                    mainSrc={images[lightboxState.index].url}
                    nextSrc={images[(lightboxState.index + 1) % images.length].url}
                    prevSrc={images[(lightboxState.index + images.length - 1) % images.length].url}
                    onCloseRequest={() => setLightboxState(INITIAL_LIGHTBOX_STATE)}
                    onMovePrevRequest={() =>
                        setLightboxState((prev) => ({
                            ...prev,
                            index: (lightboxState.index + images.length - 1) % images.length,
                        }))
                    }
                    onMoveNextRequest={() =>
                        setLightboxState((prev) => ({ ...prev, index: (lightboxState.index + 1) % images.length }))
                    }
                />
            )}
        </div>
    );
};

const SortableFileList = ({
    files,
    editModeEnabled,
    sortDisabled,
    children,
    ...props
}: Omit<SortableFileProps, 'file' | 'id'> & {
    files: FileInfo[];
    sortDisabled?: boolean;
    children?: React.ReactNode;
}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.container}>
            {files.map((file) => (
                <SortableFile
                    key={file.id}
                    id={file.id}
                    file={file}
                    editModeEnabled={editModeEnabled}
                    sortDisabled={sortDisabled}
                    {...props}
                />
            ))}
            {children}
        </Grid>
    );
};

type SortableFileProps = {
    id: string;
    file: FileInfo;
    onClickMedia: (file: FileInfo) => void;
    deletable?: boolean;
    onDelete?: (id: string) => void;
    deleting?: boolean;
    editModeEnabled?: boolean;
    sorting: boolean;
    sortDisabled?: boolean;
};

const SortableFile = ({
    id,
    file,
    deletable,
    onDelete,
    deleting,
    onClickMedia,
    editModeEnabled,
    sorting,
    sortDisabled,
}: SortableFileProps) => {
    const classes = useStyles();

    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: active?.id === id ? 1 : 0,
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileSize));

    const isVideo = videoExtensions.includes(file.extension);

    const getCursor = () => {
        if (isMobile) return 'default';
        if (editModeEnabled) {
            if (sortDisabled) return 'default';

            if (sorting) return 'grabbing';
            return 'grab';
        }
        return 'pointer';
    };

    return (
        <Grid ref={setNodeRef} style={style} {...attributes} {...listeners} item xs={4} className={classes.image}>
            <RatioBox ratio={1}>
                <Box
                    width="100%"
                    height="100%"
                    style={{
                        cursor: getCursor(),
                    }}
                    onClick={() => onClickMedia(file)}
                >
                    {isVideo ? <VideoItem file={file} /> : <BlurhashImage image={file} />}
                </Box>
            </RatioBox>
            {deletable && (
                <IconButton
                    className={classes.deleteButton}
                    onClick={() => onDelete?.(file.id)}
                    disabled={deleting}
                    disableRipple
                    color="secondary"
                    size="small"
                >
                    {deleting ? (
                        <Spinner containerClassName={classes.spinner} spinnerClassName={classes.spinner} />
                    ) : (
                        <CloseIcon fontSize="small" />
                    )}
                </IconButton>
            )}

            {isMobile && editModeEnabled && (
                <Box style={{ cursor: 'grab' }} className={classes.reorderIconContainer}>
                    <OpenWithRoundedIcon />
                </Box>
            )}
        </Grid>
    );
};
