import { IconButton, Paper, TextField } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './searchbar.style';

type SearchbarProps = {
    value: string;
    onChange: (value: string) => void;
};

export const Searchbar: React.FC<SearchbarProps> = ({ value, onChange }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.searchBar} variant="outlined">
            <TextField
                color="primary"
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Search for something..."
                InputProps={{
                    startAdornment: <SearchIcon className={classes.searchIcon} />,
                    endAdornment: value ? (
                        <IconButton size="small" onClick={() => onChange('')}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ) : null,
                }}
            />
        </Paper>
    );
};
