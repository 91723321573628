import { Box, ContainerProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { PageContent, PageContentProps } from 'components/page-content';
import React, { ReactNode } from 'react';
import { useStyle } from './page-header.style';

type PageHeaderProps = {
    title: string | ReactNode;
};

export const PageHeader: React.FC<Omit<ContainerProps, 'title' | 'children'> & PageHeaderProps & PageContentProps> = ({
    title,
    children,
    padding,
    ...props
}) => {
    const classes = useStyle();

    return (
        <PageContent padding={padding} className={classes.container} noMobilePadding={false} {...props}>
            <Typography variant="h3">{title}</Typography>
            <Box className={classes.rightContainer}>{children}</Box>
        </PageContent>
    );
};
