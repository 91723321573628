import { Box, Typography } from '@material-ui/core';
import { ColorIDAvatar } from 'components';
import { Link } from 'components/link';
import { UserInfoCard } from 'components/user-info-card';
import { Post } from 'graphql/generated';
import React from 'react';
import { getActionText } from '../../utils';
import { PostHeaderActions } from './post-header-actions';
import { useStyles } from './post-header.style';

type PostHeaderProps = {
    post: Post;
    hideActions?: boolean;
};

export const PostHeader: React.FC<PostHeaderProps> = ({ post, hideActions }) => {
    const { author } = post;

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <UserInfoCard user={author}>
                <Link to={`/d/profile/${author.id}`}>
                    <ColorIDAvatar
                        id={author.id}
                        text={author.fullName}
                        fontSize="sm"
                        className={classes.avatar}
                        src={author.avatar?.url}
                    />
                </Link>
            </UserInfoCard>

            <Box ml={1.5}>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                    <UserInfoCard user={author}>
                        <Link style={{ width: 'fit-content' }} link to={`/d/profile/${author.id}`}>
                            <Typography className={classes.authorName} color="primary">
                                {author.fullName}
                            </Typography>
                        </Link>
                    </UserInfoCard>
                    &nbsp;{getActionText(post)}
                </Box>
            </Box>
            <Box flexGrow={1} />
            {!hideActions && <PostHeaderActions post={post} />}
        </Box>
    );
};
