import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'enum';

export const useStyles = ({ length }: { length: number }) =>
    makeStyles((theme) => {
        const isDarkMode = theme.palette.type === Theme.Dark;
        return {
            root: {
                '& .react-carousel-dots-holder ': {
                    justifyContent: length < 5 ? 'center' : 'flex-start',
                },
                '& .dot-holder': {
                    '& .react-carousel-dots-dot': {
                        backgroundColor: isDarkMode ? '#333' : '#777',
                        borderColor: 'transparent',
                        '&.active': {
                            backgroundColor: isDarkMode ? '#fff' : '#000',
                        },
                    },
                },
            },
        };
    });
