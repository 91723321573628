import { Box, Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useStyles } from './community-skeleton.style';

export const CommunitySkeleton: React.FC = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box display="flex" flexDirection="column">
                <Skeleton variant="rect" className={classes.cover} />
                <Box className={classes.content}>
                    <Skeleton variant="circle" className={classes.logo} />
                    <Skeleton variant="text" width={100} height={22} style={{ marginBottom: 6.25 }} />
                    <Skeleton variant="text" width={80} height={18} />
                </Box>
            </Box>
        </Card>
    );
};
