import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginBottom: theme.spacing(2),
            wordBreak: 'break-word',
        },
        detail: {
            '& .LinesEllipsis': {
                display: 'inline',
                '& div': {
                    display: 'inline',
                    '& p': {
                        display: 'inline',
                    },
                },
            },
        },
        description: {
            ...theme.typography.body1,
            fontSize: '1.075rem',
            margin: theme.spacing(2, 0),
            flex: 1,
            overflowY: 'auto',
            wordBreak: 'break-word',
        },
        showMore: {
            display: 'inline',
            color: theme.palette.text.secondary,
            cursor: 'pointer',
        },
    };
});
