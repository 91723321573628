import { Box, darken, Grid, lighten, makeStyles, SvgIcon } from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { ReactComponent as ImageRemoveIcon } from 'assets/community-icons/image-remove.svg';
import { CircleIcon, EmptyState, Link, RatioBox } from 'components';
import { BlurhashImage } from 'components/image-blurhash';
import { imageExtensions } from 'config';
import { Theme } from 'enum';
import { Post } from 'graphql/generated';
import React from 'react';

type PostGridProps = {
    posts: Post[];
};

const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        container: {
            width: 'calc(100% + 2px)',
            margin: -1,
            '& .MuiGrid-item': {
                padding: 1,
            },
        },
        image: {
            position: 'relative',
            '& img, & video': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: '.3s filter',
                '&:hover': {
                    filter: 'brightness(70%)',
                },
            },
        },
        spinner: {
            width: '24px !important',
            height: '24px !important',
        },
        deleteButton: {
            position: 'absolute',
            padding: 4,
            top: 0,
            right: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.05),
            '&:hover': {
                backgroundColor: isDarkMode
                    ? lighten(theme.palette.background.paper, 0.1)
                    : darken(theme.palette.background.paper, 0.1),
            },
        },
        iconMultiple: {
            position: 'absolute',
            top: theme.spacing(1.5),
            right: theme.spacing(1.5),
            color: '#fff',
            pointerEvents: 'none',
        },
    };
});

export const PostGrid: React.FC<PostGridProps> = ({ posts }) => {
    const classes = useStyles();

    if (posts.length === 0) {
        return (
            <EmptyState
                media={
                    <CircleIcon radius={80} icon={<SvgIcon component={ImageRemoveIcon} style={{ fontSize: 70 }} />} />
                }
                primaryText="No images uploaded yet"
            />
        );
    }

    return (
        <Box>
            <Grid container spacing={1} className={classes.container}>
                {posts.map((post) => {
                    const files = post.files.edges.map(({ node }) => node) || [];
                    const images = files.filter((file) => imageExtensions.includes(file.extension));

                    const hasMultipleMedia = images.length > 1;

                    return (
                        <Grid key={post.id} item xs={4} className={classes.image}>
                            <RatioBox ratio={1}>
                                <Box width="100%" height="100%" position="relative">
                                    <Link to={`/d/post/${post.id}`}>
                                        <BlurhashImage image={images[0]} />
                                        {hasMultipleMedia && <PhotoLibraryIcon className={classes.iconMultiple} />}
                                    </Link>
                                </Box>
                            </RatioBox>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};
