import { Box, Card, Grid, Icon, IconButton, MenuItem, Typography } from '@material-ui/core';
import { LanguageTabs } from 'components';
import { BaseField } from 'components/base-field';
import { RichTextEditor } from 'components/formik';
import { RatioBox } from 'components/ratio-box';
import { VideoPlayer } from 'components/video-player';
import { imageTypes, videoTypes } from 'config';
import { useAuthState } from 'context/auth/store';
import { useSettings } from 'context/SettingsContext';
import { useUserCommunitiesState } from 'context/user-communities/store';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { CreatePostInput, LanguageCode, usePartnersQuery } from 'graphql/generated';
import React, { useState } from 'react';
import { getTranslationContent } from 'utils/translation-helper';
import { useStyles } from './create-post-dialog-form.style';

type CreatePostDialogFormProps = {
    loading: boolean;
};

export const CreatePostDialogForm: React.FC<CreatePostDialogFormProps> = ({ loading }) => {
    const classes = useStyles();
    const { user, isManager } = useAuthState();

    const { language } = useSettings();
    const { values, setFieldValue, errors, touched } = useFormikContext<CreatePostInput>();

    const { communities } = useUserCommunitiesState();

    const { data } = usePartnersQuery({
        fetchPolicy: 'network-only',
        variables: { where: { communityId: values.communityId, noPagination: true } },
    });

    const partners = data?.partners.edges.map(({ node }) => node) || [];

    const partnerExist = !!partners.length;

    const [langIndex, setLangIndex] = useState(0);
    const defaultLanguageIndex = Object.values(LanguageCode).findIndex((lang) => lang === LanguageCode.En);
    const fieldName = `translations[${isManager ? langIndex : defaultLanguageIndex}].body`;

    return (
        <Grid container style={{ marginBottom: '8px', marginTop: '8px' }} spacing={2}>
            <Grid item xs={12}>
                <Field component={TextField} select label="Community" variant="outlined" fullWidth name="communityId">
                    {communities.map((community) => {
                        const translations = community.translations.edges.map(({ node }) => node) || [];
                        const communityContent = getTranslationContent({
                            translations,
                            language,
                            requiredFields: ['name'],
                        });
                        return (
                            <MenuItem key={community.id} value={community.id}>
                                {communityContent.name}
                            </MenuItem>
                        );
                    })}
                </Field>
            </Grid>
            {isManager && (
                <Grid item xs={12}>
                    <BaseField
                        component={TextField}
                        select
                        label={partnerExist ? 'Partner' : 'Community doesn’t have partners'}
                        disabled={!partnerExist}
                        variant="outlined"
                        fullWidth
                        name="partnerId"
                        allowClear
                    >
                        {partners.map((partner) => {
                            const translations = partner.translations.edges.map(({ node }) => node) || [];
                            const communityContent = getTranslationContent({
                                translations,
                                language,
                                requiredFields: ['name'],
                            });
                            return (
                                <MenuItem key={partner.id} value={partner.id}>
                                    {communityContent.name}
                                </MenuItem>
                            );
                        })}
                    </BaseField>
                </Grid>
            )}
            <Grid item xs={12}>
                {isManager && (
                    <LanguageTabs
                        value={langIndex}
                        onChange={(index): void => setLangIndex(index)}
                        errors={errors}
                        touched={touched}
                    />
                )}
                <Field
                    component={RichTextEditor}
                    name={fieldName}
                    placeholder={`What's on your mind, ${user?.firstName}?`}
                    noBorderTop={isManager}
                />
            </Grid>
            <Grid item xs={12}>
                {values.files?.map((file, i) => {
                    if (imageTypes.includes(file.type)) {
                        return (
                            <RatioBox key={i} containerClassName={classes.imageContainer}>
                                <img
                                    className={classes.image}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    src={URL.createObjectURL(file)}
                                />
                                <IconButton
                                    disabled={loading}
                                    onClick={(): void => {
                                        const files = values.files?.filter((_, index) => index !== i);
                                        setFieldValue('files', files);
                                    }}
                                    className={classes.photoDeleteButton}
                                >
                                    <Icon style={{ fontSize: 16 }}>close</Icon>
                                </IconButton>
                            </RatioBox>
                        );
                    } else if (videoTypes.includes(file.type)) {
                        return (
                            <RatioBox key={i} containerClassName={classes.imageContainer}>
                                <VideoPlayer light={false} url={URL.createObjectURL(file)} />
                                <IconButton
                                    disabled={loading}
                                    onClick={(): void => {
                                        const files = values.files?.filter((_, index) => index !== i);
                                        setFieldValue('files', files);
                                    }}
                                    className={classes.photoDeleteButton}
                                >
                                    <Icon style={{ fontSize: 16 }}>close</Icon>
                                </IconButton>
                            </RatioBox>
                        );
                    } else {
                        return (
                            <Card key={i} className={classes.fileContainer} variant="outlined">
                                <Box display="flex" alignItems="center">
                                    <Icon fontSize="small">attach_file</Icon>
                                    <Typography className={classes.fileName}>{file.name}</Typography>
                                </Box>
                                <IconButton
                                    disabled={loading}
                                    hidden={loading}
                                    onClick={(): void => {
                                        const files = values.files?.filter((_, index) => index !== i);
                                        setFieldValue('files', files);
                                    }}
                                    className={classes.fileDeleteButton}
                                >
                                    <Icon className={classes.fileDeleteIcon}>close</Icon>
                                </IconButton>
                            </Card>
                        );
                    }
                })}
            </Grid>
        </Grid>
    );
};
