import { Box, Card, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useStyles } from './experience-skeleton.style';

export const ExperienceSkeleton: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card className={classes.root}>
            <Box className={classes.slider}>
                <Skeleton variant="rect" width="100%" height="100%" />
            </Box>
            <Box className={classes.content}>
                <Skeleton variant="text" width="40%" height={20} style={{ marginBottom: 12 }} />
                <Skeleton variant="text" width="95%" height={30} />
                <Skeleton variant="text" width="35%" height={30} style={{ marginTop: -6, marginBottom: 4 }} />
                <Skeleton variant="text" width="30%" height={25} style={{ marginBottom: 12 }} />
                <Box className={classes.description}>
                    {Array(isSm ? 6 : 12)
                        .fill(0)
                        .map((_el, index) => (
                            <Skeleton key={index} variant="text" width="100%" height={20} style={{ marginBottom: 2 }} />
                        ))}
                </Box>
                <Skeleton variant="rect" width={134} height={35} style={{ borderRadius: 50 }} />
                <Box my={3} display="flex" flexWrap="wrap">
                    <Skeleton variant="text" width={55} height={22} style={{ marginRight: 8, marginBottom: 8 }} />
                    <Skeleton variant="text" width={60} height={22} style={{ marginRight: 8, marginBottom: 8 }} />
                    <Skeleton variant="text" width={50} height={22} style={{ marginRight: 8, marginBottom: 8 }} />
                </Box>
                <Box my={2.5} style={{ margin: '20px 0' }}>
                    <Skeleton variant="text" width="90%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Skeleton variant="text" width={120} height={30} />
                    <Skeleton variant="rect" width={110} height={35} style={{ borderRadius: 50 }} />
                </Box>
            </Box>
        </Card>
    );
};
