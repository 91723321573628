import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { dashboardHorizontalPadding, mobileSize } from 'config';
import React from 'react';

export type PageContentProps = {
    padding?: 'large' | 'medium' | 'small';
    noMobilePadding?: boolean;
};

const useStyles = makeStyles((theme) => {
    return {
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingRight: dashboardHorizontalPadding,
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, 2),
            },
        },
        paddingSmall: {
            maxWidth: 'unset',
        },
        paddingMedium: {
            maxWidth: theme.breakpoints.values.md,
            marginRight: 0,
        },
        paddingLarge: {
            maxWidth: 'unset',
            paddingLeft: 191,
            transition: '.3s padding-left',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 80,
            },
            [theme.breakpoints.down(mobileSize)]: {
                paddingLeft: theme.spacing(2),
            },
        },
        noMobilePadding: {
            [theme.breakpoints.down(mobileSize)]: {
                padding: 0,
                overflow: 'hidden',
            },
        },
    };
});

export const PageContent: React.FC<Omit<ContainerProps, 'maxWidth'> & PageContentProps> = ({
    padding = 'medium',
    className,
    children,
    noMobilePadding = true,
    ...other
}) => {
    const classes = useStyles();

    const paddingClassName = {
        small: classes.paddingSmall,
        medium: classes.paddingMedium,
        large: classes.paddingLarge,
    }[padding];

    return (
        <Container
            className={clsx(classes.root, paddingClassName, noMobilePadding && classes.noMobilePadding, className)}
            {...other}
        >
            {children}
        </Container>
    );
};
