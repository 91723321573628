import { makeStyles } from '@material-ui/core/styles';
import { cardP, cardPMobile, mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(cardP),
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(cardPMobile),
            },
        },
        avatar: {
            cursor: 'pointer',
            width: 40,
            height: 40,
        },
        authorName: {
            position: 'relative',
            cursor: 'pointer',
            fontWeight: 700,
            width: 'fit-content',
            lineHeight: 1,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
});
