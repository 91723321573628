import { Box, ListItem, ListItemAvatar, ListItemProps, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { ColorIDAvatar, Link } from 'components';
import { LinkProps } from 'components/link';
import { User } from 'graphql/generated';
import React, { ReactNode } from 'react';

export type UserListItemProps = ListItemProps & { user: User; right?: ReactNode; LinkProps?: LinkProps };

export const UserListItem: React.FC<UserListItemProps> = ({ user, right, LinkProps, ...props }) => {
    return (
        <Link {...LinkProps} to={`/d/profile/${user.id}`}>
            <ListItem {...props} button={true as any}>
                <ListItemAvatar>
                    <ColorIDAvatar id={user.id} text={user.fullName} fontSize="sm" src={user.avatar?.url} />
                </ListItemAvatar>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <ListItemText primary={user.fullName} />
                    {!!right && <ListItemSecondaryAction>{right}</ListItemSecondaryAction>}
                </Box>
            </ListItem>
        </Link>
    );
};
