import { Box, Button, Card, SvgIcon, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as TrashCanOutline } from 'assets/community-icons/trash-can-outline.svg';
import { ActionMenu, Actions } from 'components/action-menu';
import { BookmarkEventButton } from 'components/bookmark-event-button';
import { EventContent, EventSliderFooter } from 'components/event-item';
import { EventPayment } from 'components/event-payment';
import { EventSlider } from 'components/event-slider';
import { RatioBox } from 'components/ratio-box';
import { RepostEventButton } from 'components/repost-event-button';
import { useAuthState } from 'context/auth/store';
import { useNotifications } from 'context/NotificationsContext';
import { useSettings } from 'context/SettingsContext';
import { experienceTitleMap } from 'data/experiences';
import { Event, useDeleteEventMutation } from 'graphql/generated';
import { uniqueId } from 'lodash';
import React from 'react';
import browserHistory from 'utils/browser-utils';
import { getTranslationContent } from 'utils/translation-helper';
import { useStyles } from './experience-item-mobile.style';

type ExperienceItemProps = { experience: Event; onDelete?: (experienceId: string) => void };

export const ExperienceItemMobile: React.FC<ExperienceItemProps> = ({ experience, onDelete }) => {
    const classes = useStyles();
    const { language } = useSettings();
    const { isManager, user } = useAuthState();
    const { confirm, notify } = useNotifications();

    const images = experience.images.edges.map(({ node }) => node.image);
    const translations = experience.translations.edges.map(({ node }) => node);
    const tags = experience.tags || [];
    const hasActionPermissions = isManager || experience.createdById === user?.id;

    const [deleteExperience] = useDeleteEventMutation({
        onCompleted({ deleteEvent }) {
            onDelete?.(deleteEvent.id);
            notify({
                type: 'success',
                message: 'Experience deleted succesfully!',
                duration: 1000,
            });
        },
    });

    const handleDelete = () => {
        confirm({
            onConfirm: () => deleteExperience({ variables: { id: experience.id } }),
            title: 'Delete Experience',
            confirmText: 'Are you sure you want to delete this Experience?',
        });
    };

    const handleEdit = () => {
        browserHistory.push(`/d/experience/${experience.id}/edit`);
    };

    const actions: Actions = [
        {
            primaryText: 'Edit experience',
            startIcon: <EditIcon />,
            onClick: handleEdit,
            hidden: !hasActionPermissions,
        },
        {
            primaryText: 'Delete experience',
            startIcon: <SvgIcon component={TrashCanOutline} />,
            onClick: handleDelete,
            hidden: !hasActionPermissions,
            divider: true,
        },
    ];

    const { title, description, address } = getTranslationContent({
        translations,
        language,
        requiredFields: ['title', 'description'],
    });

    const shouldShowPayment = experience.price !== null && experience.price !== undefined;
    const portalUUID = uniqueId('experience-portal');

    return (
        <Card className={classes.root}>
            <Box display="flex" className={classes.headerContainer} alignItems="center" justifyContent="space-between">
                <Box className={classes.title}>
                    <Typography variant="h4">{experienceTitleMap[tags[0]]}</Typography>
                    {address && (
                        <Typography className={classes.city} variant="subtitle1">
                            {address}
                        </Typography>
                    )}
                </Box>
                <RepostEventButton eventId={experience.id} type={experience.type} />
                <BookmarkEventButton eventId={experience.id} isBookmarked={experience.isBookmarked} />
                {hasActionPermissions && <ActionMenu actions={actions} />}
            </Box>
            <RatioBox>
                <EventSlider portalID={portalUUID} files={images} ligthBoxDisabled />
            </RatioBox>
            <EventSliderFooter
                portalID={portalUUID}
                eventId={experience.id}
                startTime={experience.startTime}
                type="experience"
            />
            <Box className={classes.detail}>
                <EventContent title={title} description={description} />
            </Box>

            {shouldShowPayment && (
                <Box mx={2} my={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary" variant="h5" style={{ fontSize: '1.1rem', fontWeight: 700 }}>
                        {experience.price && experience.price > 0 ? `${experience?.price} €` : 'free'}
                    </Typography>

                    <EventPayment
                        id={experience.id}
                        title={title}
                        price={experience.price}
                        purchased={experience.purchased}
                    />
                </Box>
            )}

            {!shouldShowPayment && experience.externalLink && (
                <Box mx={2} my={2} display="flex" justifyContent="flex-end">
                    <a
                        href={experience.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'unset', textDecoration: 'none' }}
                    >
                        <Button size="small" variant="outlined" color="primary">
                            Buy ticket
                        </Button>
                    </a>
                </Box>
            )}
        </Card>
    );
};
