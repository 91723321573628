export const rootMaxWidth = 1400;
export const headerHeight = 80;
export const headerMobileHeight = 64;
export const sidebarWidth = 350;
export const sidebarCollapsedWidth = 144;
export const bottomBarHeight = 64;
export const mobileSize = 'sm';
export const cardP = 2.5;
export const cardPMobile = 1.5;
export const dashboardHorizontalPadding = 48;
