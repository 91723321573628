import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { LoadingButton, Spinner } from 'components';
import { useAuthState } from 'context/auth/store';
import { useFeed } from 'context/feed/store';
import { useUserCommunitiesState } from 'context/user-communities/store';
import { Formik } from 'formik';
import {
    CreatePostInput,
    LanguageCode,
    MemberStatus,
    Post,
    PostVisibility,
    useCreatePostMutation,
} from 'graphql/generated';
import React, { useRef } from 'react';
import { UserHeader } from '../user-header';
import { CreatePostDialogForm } from './create-post-dialog-form';
import { useStyles } from './create-post-dialog.style';

export const CreatePostDialog: React.FC = () => {
    const classes = useStyles();
    const { user, isManager } = useAuthState();
    const [{ createPostDialogOpen }, dispatch] = useFeed();
    const { communitiesLoading, communityId, communitiesLoadingError } = useUserCommunitiesState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const canPostPublicly = isManager || user?.member?.status === MemberStatus.Approved;

    const closeDialog = (): void => dispatch({ type: 'CLOSE_CREATE_POST_DIALOG' });

    const [createPost, { loading }] = useCreatePostMutation();

    if (communitiesLoading) {
        return (
            <Dialog classes={{ paper: classes.dialog }} open={createPostDialogOpen} fullScreen={isMobile}>
                <Spinner style={{ width: '100%', height: 500 }} />
            </Dialog>
        );
    }

    if (!!communitiesLoadingError) {
        return (
            <Dialog classes={{ paper: classes.dialog }} open={createPostDialogOpen} fullScreen={isMobile}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h4">There was a problem. Please try again.</Typography>
                </Box>
            </Dialog>
        );
    }

    const initialValues: CreatePostInput = {
        communityId,
        files: [],
        visibility: canPostPublicly ? PostVisibility.Public : PostVisibility.Followers,
        translations: isManager
            ? Object.values(LanguageCode).map((lang) => ({ lang, body: '' }))
            : [{ lang: LanguageCode.En, body: '' }],
        partnerId: '',
    };

    const onSubmit = async (values: CreatePostInput) => {
        const normalizedValues: CreatePostInput = {
            ...values,
            partnerId: values.partnerId === '' ? undefined : values.partnerId,
        };
        const createPostResult = await createPost({ variables: { post: normalizedValues } });
        const post = createPostResult.data?.createPost.post as Post;
        if (!values.partnerId) {
            dispatch({ type: 'ADD_POST', post });
        }
        closeDialog();
    };

    return (
        <Dialog classes={{ paper: classes.dialog }} open={createPostDialogOpen} fullScreen={isMobile}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} isInitialValid={false}>
                {({ values, submitForm, setFieldValue }): React.ReactElement => {
                    const submitAllowed =
                        values.translations.some((translation) => !!translation.body) ||
                        (values.files || []).length > 0;
                    return (
                        <>
                            <DialogTitle className={classes.dialogTitle} disableTypography>
                                <Box className={classes.dialogHeader}>
                                    <Box width={36} />
                                    <Typography variant="h4">Create Post</Typography>
                                    <IconButton size="small" disabled={loading} onClick={closeDialog}>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </Box>
                                <Divider className={classes.divider} />
                                <UserHeader />
                            </DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <CreatePostDialogForm loading={loading} />
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Card
                                    className={classes.attachments}
                                    variant="outlined"
                                    onClick={(): void => fileInputRef.current?.click()}
                                >
                                    <Box flexGrow={1}>
                                        <Typography variant="h6">Add to your post</Typography>
                                    </Box>
                                    <Box>
                                        <Tooltip arrow placement="top" title="File">
                                            <IconButton
                                                disabled={loading}
                                                onClick={(): void => fileInputRef.current?.click()}
                                                className={classes.fileButton}
                                            >
                                                <Icon>attach_file</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <input
                                            hidden
                                            multiple
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={(e): void => {
                                                if (e.target.files) {
                                                    setFieldValue('files', [
                                                        ...(values.files || []),
                                                        ...Array.from(e.target.files),
                                                    ]);
                                                    e.target.value = '';
                                                }
                                            }}
                                        />
                                    </Box>
                                </Card>
                                <LoadingButton
                                    size="large"
                                    color="primary"
                                    style={{ marginLeft: 0 }}
                                    onClick={submitForm}
                                    disabled={!submitAllowed || loading}
                                    variant="contained"
                                    fullWidth
                                    loading={loading}
                                    text="Post"
                                    loadingText="Posting"
                                />
                            </DialogActions>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
};
