import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        root: {
            display: 'flex',
            overflow: 'hidden',
            borderRadius: 0,
            boxShadow: 'none',
            height: 650,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
                height: 450,
            },
        },
        slider: {
            width: '55%',
            borderRadius: 4,
            borderBottomRightRadius: 150,
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                borderRadius: 0,
            },
        },
        content: {
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(5),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            minHeight: '100%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: theme.spacing(3),
            },
        },
        icon: {
            '& path, & circle, & rect:not(:last-child)': {
                fill: isDarkMode ? theme.palette.text.primary : theme.palette.text.secondary,
            },
        },
        description: {
            ...theme.typography.body1,
            fontSize: '1.075rem',
            margin: theme.spacing(4, 0),
            flex: 1,
        },
        locationIcon: {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1),
            '& path': {
                fill: `${theme.palette.text.secondary} !important`,
            },
        },
        learnMore: {
            fontSize: '0.6rem',
            color: theme.palette.text.secondary,
        },
    };
});
