import { IconButton, IconButtonProps } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { useNotifications } from 'context/NotificationsContext';
import { Event, useBookmarkEventMutation } from 'graphql/generated';
import React, { useState } from 'react';

type BookmarkEventButtonProps = IconButtonProps & {
    eventId: Event['id'];
    isBookmarked: Event['isBookmarked'];
};

export const BookmarkEventButton: React.FC<BookmarkEventButtonProps> = ({ eventId, isBookmarked, ...props }) => {
    const { notify } = useNotifications();
    const [bookmarked, setBookmarked] = useState(isBookmarked);

    const [bookmarkEvent] = useBookmarkEventMutation({
        onCompleted({ bookmarkEvent: { event } }) {
            setBookmarked(event.isBookmarked);
            notify({
                type: 'success',
                message: event.isBookmarked ? 'Bookmarked successfully!' : 'Bookmark removed successfully!',
            });
        },
        onError(error) {
            notify({
                type: 'error',
                message: error.message,
            });
        },
    });

    const onBookmarked = () => {
        bookmarkEvent({ variables: { eventId: { eventId } } });
    };

    return (
        <IconButton onClick={onBookmarked} {...props} size="small">
            {!bookmarked ? <BookmarkBorderIcon /> : <BookmarkIcon color="primary" />}
        </IconButton>
    );
};
