import React from 'react';
import { Box, BoxProps, Typography } from '@material-ui/core';
import { Link } from 'components/link';
import { Event } from 'graphql/generated';

type EventTagsProps = BoxProps & {
    tags: Event['tags'];
};

export const EventTags: React.FC<EventTagsProps> = ({ tags = [], ...props }) => {
    if (tags?.length === 0) {
        return null;
    }

    return (
        <Box display="flex" flexWrap="wrap" {...props}>
            {tags?.map((item, i) => (
                <Box mr={1} mb={1} key={i}>
                    <Link to={`/d/event/search?tags=${item}`}>
                        <Typography color="primary">#{item}</Typography>
                    </Link>
                </Box>
            ))}
        </Box>
    );
};
