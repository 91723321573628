import { Box, Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'components/link';
import { UserInfoCard } from 'components/user-info-card';
import { useSettings } from 'context/SettingsContext';
import { Post } from 'graphql/generated';
import React, { useRef } from 'react';
import { timeAgo } from 'utils/dateTimeHelper';
import { getTranslationContent } from 'utils/translation-helper';
import { getPostContent, getPostDescription } from '../utils';
import { PostActions } from './post-actions';
import { PostComments } from './post-comments';
import { PostHeader } from './post-header';
import { useStyles } from './post-item.style';

type PostItemProps = {
    post: Post;
    hideActions?: boolean;
    hideComments?: boolean;
    portalPrefix?: string;
};

const getRelativeFontSize = (text: string) => {
    const length = text.replace(/<[^>]+>/g, '').trim().length;
    if (length < 25) {
        return '3.5rem';
    } else if (length >= 25 && length < 75) {
        return '1.75rem';
    } else if (length >= 75 && length < 150) {
        return '1.25rem';
    } else {
        return '0.875rem';
    }
};

export const PostItem: React.FC<PostItemProps> = ({
    post,
    hideActions = false,
    hideComments = false,
    portalPrefix,
}) => {
    const classes = useStyles();
    const { id } = post;
    const { language } = useSettings();
    const inputRef = useRef<HTMLInputElement>(null);

    const publishDate = timeAgo(post.createdAt);

    const topComments = post.topComments.edges.map(({ node }) => node);

    const translations = post.translations.edges.map(({ node }) => node) || [];

    const { body } = getTranslationContent({ language, translations, requiredFields: ['body'] });

    const postMeta = post.meta.__typename;
    const hidePostAuthor = postMeta === 'EventMeta' || postMeta === 'ExperienceMeta';
    const isDefaultPost = postMeta === 'DefaultPostMeta';
    const hasFiles = post.files.edges.length > 0;

    const isDefaultPostWithoutFiles = isDefaultPost && !hasFiles;

    return (
        <Card className={classes.root}>
            <PostHeader post={post} hideActions={hideActions} />

            <Box overflow="hidden">
                {isDefaultPostWithoutFiles && body ? (
                    <Box className={classes.bodyWithoutFilesContainer}>
                        <Box
                            className={clsx(classes.body, classes.bodyWithoutFiles)}
                            style={{ fontSize: getRelativeFontSize(body) }}
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                    </Box>
                ) : (
                    getPostContent({ post, portalPrefix })
                )}
            </Box>

            <Box className={classes.footer}>
                {!hideActions && (
                    <Box className={classes.actions}>
                        <PostActions post={post} inputRef={inputRef} portalPrefix={portalPrefix} />
                    </Box>
                )}
                <Box mt={1}>
                    {!hidePostAuthor && !isDefaultPostWithoutFiles && (
                        <UserInfoCard user={post.author}>
                            <Link style={{ width: 'fit-content' }} link to={`/d/profile/${post.author.id}`}>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    style={{ whiteSpace: 'nowrap', fontWeight: 700 }}
                                >
                                    {post.author.fullName}
                                </Typography>
                            </Link>
                        </UserInfoCard>
                    )}
                    {getPostDescription(post, language)}

                    {!isDefaultPostWithoutFiles && body && (
                        <Box className={classes.body} dangerouslySetInnerHTML={{ __html: body }} />
                    )}
                </Box>

                {!hideComments && (
                    <Box className={classes.comments}>
                        <PostComments
                            totalCount={post.comments.totalCount}
                            initialComments={topComments}
                            postId={id}
                            authorId={post.author.id}
                            inputRef={inputRef}
                        />
                    </Box>
                )}

                <Typography variant="body1" color="textSecondary" className={classes.publishDate}>
                    {publishDate}
                </Typography>
            </Box>
        </Card>
    );
};
