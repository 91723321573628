import { Box, Button, Card, Typography } from '@material-ui/core';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { BookmarkEventButton } from 'components/bookmark-event-button';
import { EventPayment } from 'components/event-payment';
import { EventSlider } from 'components/event-slider';
import { Link } from 'components/link';
import { RepostEventButton } from 'components/repost-event-button';
import { useSettings } from 'context/SettingsContext';
import { experienceTitleMap } from 'data/experiences';
import { Event } from 'graphql/generated';
import React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { getTranslationContent } from 'utils/translation-helper';
import { useStyles } from './experience-item.style';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

type ExperienceItemProps = { experience: Event; refetch?: any };

export const ExperienceItem: React.FC<ExperienceItemProps> = ({ experience }) => {
    const classes = useStyles();
    const { language } = useSettings();

    const translations = experience.translations.edges.map(({ node }) => node);

    const { address, title, description } = getTranslationContent({
        translations,
        language,
        requiredFields: ['title', 'description'],
    });

    const images = experience.images.edges.map(({ node }) => node.image);
    const tags = experience.tags || [];

    const shouldShowPayment = experience.price !== null && experience.price !== undefined;

    return (
        <Card className={classes.root}>
            <Box className={classes.slider}>
                <Link to={`/d/experience/${experience.id}`}>
                    <EventSlider files={images} ligthBoxDisabled />
                </Link>
            </Box>
            <Box className={classes.content}>
                <Box display="flex" alignItems="center" justifyContent="end">
                    <RepostEventButton eventId={experience.id} type={experience.type} />
                    <BookmarkEventButton eventId={experience.id} isBookmarked={experience.isBookmarked} />
                </Box>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h5">{experienceTitleMap[tags[0]]}</Typography>
                        {address && (
                            <Box mt={1} display="flex" alignItems="flex-end">
                                <LocationIcon className={classes.locationIcon} />
                                <Typography color="textSecondary">{address}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Typography variant="h3" style={{ textTransform: 'uppercase' }}>
                    {title}
                </Typography>

                <ResponsiveEllipsis
                    maxLine={5}
                    className={classes.description}
                    unsafeHTML={description}
                    ellipsis="..."
                    basedOn="letters"
                />

                <Box>
                    <Link to={`/d/experience/${experience.id}`}>
                        <Button
                            color="primary"
                            style={{ width: 'fit-content', textTransform: 'uppercase', padding: '8px 24px' }}
                            variant="outlined"
                        >
                            <Typography className={classes.learnMore} variant="body2">
                                Learn more
                            </Typography>
                        </Button>
                    </Link>
                </Box>

                {tags.length > 0 && (
                    <Box my={3} display="flex" flexWrap="wrap">
                        {tags.map((tag, i) => (
                            <Link key={i} to={`/d/experience/search?tags=${tag}`}>
                                <Typography
                                    style={{
                                        fontStyle: 'italic',
                                        marginRight: 8,
                                        marginBottom: 8,
                                    }}
                                    variant="h5"
                                    color="primary"
                                >
                                    #{tag}
                                </Typography>
                            </Link>
                        ))}
                    </Box>
                )}

                {shouldShowPayment && (
                    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography color="textSecondary" variant="h5" style={{ fontSize: '1.1rem', fontWeight: 700 }}>
                            {experience.price && experience.price > 0 ? `${experience?.price} €` : 'free'}
                        </Typography>

                        <EventPayment
                            id={experience.id}
                            title={title}
                            price={experience.price}
                            purchased={experience.purchased}
                        />
                    </Box>
                )}

                {!shouldShowPayment && experience.externalLink && (
                    <Box mb={2} display="flex" justifyContent="flex-end">
                        <a
                            href={experience.externalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'unset', textDecoration: 'none' }}
                        >
                            <Button size="small" variant="outlined" color="primary">
                                Buy ticket
                            </Button>
                        </a>
                    </Box>
                )}
            </Box>
        </Card>
    );
};
