import { EventSlider, RatioBox } from 'components';
import { EventSliderFooter } from 'components/event-item';
import { Event } from 'graphql/generated';
import { uniqueId } from 'lodash';
import React from 'react';

type ExperiencePostContentProps = {
    experience: Event;
    portalPrefix?: string;
};

export const ExperiencePostContent: React.FC<ExperiencePostContentProps> = ({ experience, portalPrefix }) => {
    const files = experience?.images.edges.map(({ node }) => node.image) || [];
    const portalUUID = uniqueId(`${portalPrefix || ''}experience-post-portal`);

    return (
        <>
            <RatioBox ratio={1}>
                <EventSlider portalID={portalUUID} files={files} />
            </RatioBox>
            <EventSliderFooter portalID={portalUUID} eventId={experience.id} type="experience" />
        </>
    );
};
