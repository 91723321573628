import { Box, Card, Typography } from '@material-ui/core';
import { ColorIDAvatar, Link } from 'components';
import { imageExtensions } from 'config';
import { useSettings } from 'context/SettingsContext';
import { Community } from 'graphql/generated';
import React from 'react';
import { getTranslationContent } from 'utils/translation-helper';
import { isCommunityPartner } from 'views/dashboard/shared/community/community-detail/utils';
import { useStyles } from './community-item.style';

type CommunityItemProps = {
    community: Community;
};

export const CommunityItem: React.FC<CommunityItemProps> = ({ community }) => {
    const classes = useStyles();
    const { language } = useSettings();

    const translations = community.translations.edges.map(({ node }) => node) || [];
    const communityContent = getTranslationContent({
        language,
        requiredFields: ['name', 'location'],
        translations,
    });

    const isPartner = isCommunityPartner(community.category);
    const firstImage = community.images.edges
        .map(({ node }) => node)
        .filter((image) => imageExtensions.includes(image.extension))[0];

    return (
        <Link to={`/d/community/${community.id}`}>
            <Card className={classes.root}>
                <Box display="flex" flexDirection="column">
                    <Box className={classes.cover}>
                        {isPartner && firstImage && <img src={firstImage.url} className={classes.coverImage} />}
                    </Box>
                    <Box className={classes.content}>
                        <ColorIDAvatar
                            id={community.id}
                            text={communityContent.name}
                            src={community.logo?.url}
                            className={classes.logo}
                            fontSize="xl"
                        />
                        <Typography variant="h4" gutterBottom>
                            {communityContent.name}
                        </Typography>
                        <Typography color="textSecondary">{communityContent.location}</Typography>
                    </Box>
                </Box>
            </Card>
        </Link>
    );
};
