import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'config';

export const useStyles = makeStyles((theme) => {
    return {
        row: {
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: 0,
                '& .MuiGrid-item': {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                },
            },
        },
    };
});
