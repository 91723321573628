import { SvgIcon } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { ReactComponent as TrashCanOutline } from 'assets/community-icons/trash-can-outline.svg';
import { ActionMenu, ActionMenuRef, Actions } from 'components/action-menu';
import { EditPostDialog } from 'components/post/edit-post-dialog';
import { useAuthState } from 'context/auth/store';
import { useFeedDispatch } from 'context/feed/store';
import { useNotifications } from 'context/NotificationsContext';
import { Post, useBookmarkPostMutation, useDeletePostMutation } from 'graphql/generated';
import React, { useRef, useState } from 'react';

type PostHeaderActionsProps = {
    post: Post;
};

export const PostHeaderActions: React.FC<PostHeaderActionsProps> = ({ post }) => {
    const { isBookmarked: initialIsBookmarked } = post;

    const { confirm, notify } = useNotifications();
    const { isManager, user } = useAuthState();
    const dispatch = useFeedDispatch();

    const actionMenuRef = useRef<ActionMenuRef>(null);

    const closeMenu = () => actionMenuRef.current?.closeMenu();

    const [isBookmarked, setIsBookmarked] = useState(initialIsBookmarked);

    const [bookmarkPost] = useBookmarkPostMutation({
        onCompleted({
            bookmarkPost: {
                post: { isBookmarked: bookmarked },
            },
        }) {
            setIsBookmarked(bookmarked);
        },
    });

    const [deletePost] = useDeletePostMutation({
        onCompleted() {
            dispatch({ type: 'DELETE_POST', postId: post.id });
            notify({ type: 'success', message: 'Post deleted successfully!' });
        },
        onError(error) {
            notify({ type: 'error', message: error.message });
        },
    });

    const handleDelete = () => {
        confirm({
            onConfirm: () => deletePost({ variables: { id: post.id } }),
            title: 'Delete Post',
            confirmText: 'Are you sure you want to delete this post?',
        });
    };

    const hasActionPermissions = isManager || post.author.id === user?.id;

    const typename = post.meta.__typename;

    const isEditable =
        typename !== 'EventMeta' &&
        typename !== 'ExperienceMeta' &&
        typename !== 'CoverPhotoChangeMeta' &&
        typename !== 'ProfilePictureChangeMeta';

    const actions: Actions = [
        {
            primaryText: isBookmarked ? 'Unsave post' : 'Save post',
            secondaryText: isBookmarked ? 'Remove this from your saved items' : 'Add this to your saved items',
            startIcon: isBookmarked ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />,
            onClick: () => bookmarkPost({ variables: { post: { postId: post.id } } }),
            disableMenuDismiss: true,
        },
        {
            primaryText: 'Delete post',
            startIcon: <SvgIcon component={TrashCanOutline} />,
            onClick: handleDelete,
            hidden: !hasActionPermissions,
            divider: true,
        },
    ];

    return (
        <ActionMenu ref={actionMenuRef} actions={actions}>
            {hasActionPermissions && isEditable && <EditPostDialog post={post} closeMenu={closeMenu} />}
        </ActionMenu>
    );
};
