import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const UserSkeleton = () => {
    return (
        <Box display="flex" alignItems="center" px={2} py={1}>
            <Box mr={2}>
                <Skeleton variant="circle" width={40} height={40} />
            </Box>
            <Skeleton variant="text" width={Math.floor(Math.random() * (150 - 80 + 1) + 80)} />
        </Box>
    );
};
