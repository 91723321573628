import { EventSlider, RatioBox } from 'components';
import { EventSliderFooter } from 'components/event-item';
import { Event } from 'graphql/generated';
import { uniqueId } from 'lodash';
import React from 'react';

type EventPostContentProps = {
    event: Event;
    portalPrefix?: string;
};

export const EventPostContent: React.FC<EventPostContentProps> = ({ event, portalPrefix }) => {
    const files = event?.images.edges.map(({ node }) => node.image) || [];
    const portalUUID = uniqueId(`${portalPrefix || ''}event-post-portal`);

    return (
        <>
            <RatioBox ratio={1}>
                <EventSlider portalID={portalUUID} files={files} />
            </RatioBox>
            <EventSliderFooter portalID={portalUUID} eventId={event.id} startTime={event.startTime} />
        </>
    );
};
