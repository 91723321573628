import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Upload: any;
};

export type AcceptFollowInput = {
    followerId: Scalars['String'];
};

export type AcceptFollowPayload = {
    __typename?: 'AcceptFollowPayload';
    follow: Follow;
};

export type AcceptMembershipRequestInput = {
    requestId: Scalars['String'];
};

export type AcceptMembershipRequestPayload = {
    __typename?: 'AcceptMembershipRequestPayload';
    community: Community;
};

export type ActivateAccountInput = {
    token: Scalars['String'];
};

export type ActivateAccountPayload = {
    __typename?: 'ActivateAccountPayload';
    success: Scalars['Boolean'];
};

export type Activity = RelayNode & {
    __typename?: 'Activity';
    author: User;
    authorId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    meta: ActivityMeta;
    seen: Scalars['Boolean'];
    targetUserId: Scalars['String'];
    type: ActivityType;
    updatedAt: Scalars['DateTime'];
};

export type ActivityConnection = {
    __typename?: 'ActivityConnection';
    edges: Array<ActivityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ActivityEdge = {
    __typename?: 'ActivityEdge';
    node: Activity;
};

export type ActivityMeta = {
    __typename?: 'ActivityMeta';
    post: Post;
    postId: Scalars['String'];
    type: ActivityType;
};

export enum ActivityType {
    Comment = 'COMMENT',
    Like = 'LIKE',
    Repost = 'REPOST',
}

export type AddPaymentMethodInput = {
    isDefault: Scalars['Boolean'];
    paymentMethod: Scalars['String'];
};

export type AddPaymentMethodPayload = {
    __typename?: 'AddPaymentMethodPayload';
    paymentMethod: PaymentMethod;
};

export type Benefit = {
    __typename?: 'Benefit';
    icon: Scalars['String'];
    text: Scalars['String'];
};

export type BenefitInput = {
    icon: Scalars['String'];
    text: Scalars['String'];
};

export type BlurhashMeta = {
    __typename?: 'BlurhashMeta';
    hash: Scalars['String'];
    height: Scalars['Int'];
    width: Scalars['Int'];
};

export type BookmarkEventInput = {
    eventId: Scalars['String'];
};

export type BookmarkEventPayload = {
    __typename?: 'BookmarkEventPayload';
    event: Event;
};

export type BookmarkPostInput = {
    postId: Scalars['String'];
};

export type BookmarkPostPayload = {
    __typename?: 'BookmarkPostPayload';
    post: Post;
};

export type CancelFollowInput = {
    followingUserId: Scalars['String'];
};

export type CancelMembershipRequestInput = {
    communityId: Scalars['String'];
};

export type CancelMembershipRequestPayload = {
    __typename?: 'CancelMembershipRequestPayload';
    community: Community;
};

export type ChangePasswordInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
    __typename?: 'ChangePasswordPayload';
    success: Scalars['Boolean'];
};

export type ChatableUsersWhereInput = {
    keyword?: InputMaybe<Scalars['String']>;
};

export type City = RelayNode & {
    __typename?: 'City';
    country: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CityConnection = {
    __typename?: 'CityConnection';
    edges: Array<CityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CityEdge = {
    __typename?: 'CityEdge';
    node: City;
};

export type Community = RelayNode & {
    __typename?: 'Community';
    benefits?: Maybe<Array<Benefit>>;
    category: CommunityCategory;
    city: City;
    cityId: Scalars['String'];
    id: Scalars['ID'];
    images: FileInfoConnection;
    invitations: CommunityInvitationConnection;
    isInternal: Scalars['Boolean'];
    joinRule?: Maybe<JoinRule>;
    logo?: Maybe<FileInfo>;
    logoId?: Maybe<Scalars['String']>;
    managers: ManagerConnection;
    memberCount: Scalars['Int'];
    membershipStatus?: Maybe<CommunityMembershipStatus>;
    pendingMembershipsCount: Scalars['Int'];
    translations: CommunityTranslationConnection;
};

export enum CommunityCategory {
    Club = 'CLUB',
    Community = 'COMMUNITY',
    Healthcare = 'HEALTHCARE',
    Hotel = 'HOTEL',
    Other = 'OTHER',
    Restaurant = 'RESTAURANT',
    Shop = 'SHOP',
}

export type CommunityConnection = {
    __typename?: 'CommunityConnection';
    edges: Array<CommunityEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityEdge = {
    __typename?: 'CommunityEdge';
    node: Community;
};

export type CommunityImageOrderInput = {
    id: Scalars['String'];
    order: Scalars['Int'];
};

export type CommunityInvitation = RelayNode & {
    __typename?: 'CommunityInvitation';
    community: Community;
    communityId: Scalars['String'];
    createdById: Scalars['String'];
    expiresAt: Scalars['DateTime'];
    id: Scalars['ID'];
    usageLimit: Scalars['Int'];
    usedCount: Scalars['Int'];
};

export type CommunityInvitationConnection = {
    __typename?: 'CommunityInvitationConnection';
    edges: Array<CommunityInvitationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityInvitationEdge = {
    __typename?: 'CommunityInvitationEdge';
    node: CommunityInvitation;
};

export type CommunityMembersWhereInput = {
    communityId: Scalars['String'];
};

export type CommunityMembership = RelayNode & {
    __typename?: 'CommunityMembership';
    community: Community;
    communityId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    member: Member;
    memberId: Scalars['String'];
    rejectReason?: Maybe<Scalars['String']>;
    status: CommunityMembershipStatus;
    updatedAt: Scalars['DateTime'];
};

export type CommunityMembershipConnection = {
    __typename?: 'CommunityMembershipConnection';
    edges: Array<CommunityMembershipEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityMembershipEdge = {
    __typename?: 'CommunityMembershipEdge';
    node: CommunityMembership;
};

export enum CommunityMembershipStatus {
    Banned = 'BANNED',
    Joined = 'JOINED',
    None = 'NONE',
    PendingJoin = 'PENDING_JOIN',
    Rejected = 'REJECTED',
}

export type CommunityTranslation = RelayNode & {
    __typename?: 'CommunityTranslation';
    communityId: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type CommunityTranslationConnection = {
    __typename?: 'CommunityTranslationConnection';
    edges: Array<CommunityTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CommunityTranslationEdge = {
    __typename?: 'CommunityTranslationEdge';
    node: CommunityTranslation;
};

export type CommunityWhereInput = {
    keyword?: InputMaybe<Scalars['String']>;
};

export type Country = RelayNode & {
    __typename?: 'Country';
    code: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type CountryConnection = {
    __typename?: 'CountryConnection';
    edges: Array<CountryEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CountryEdge = {
    __typename?: 'CountryEdge';
    node: Country;
};

export type CoverPhotoChangeMeta = {
    __typename?: 'CoverPhotoChangeMeta';
    coverPhotoUrl: Scalars['String'];
    type: PostType;
    user: User;
    userId: Scalars['String'];
};

export type CreateCommunityInput = {
    benefits?: InputMaybe<Array<BenefitInput>>;
    category: CommunityCategory;
    cityId: Scalars['String'];
    images?: InputMaybe<Array<Scalars['Upload']>>;
    isInternal: Scalars['Boolean'];
    joinRule?: InputMaybe<JoinRule>;
    logo?: InputMaybe<Scalars['Upload']>;
    partnerIds?: InputMaybe<Array<Scalars['String']>>;
    translations: Array<CreateCommunityTranslationInput>;
};

export type CreateCommunityInvitationInput = {
    communityId: Scalars['String'];
    expiresAt: Scalars['DateTime'];
    usageLimit: Scalars['Int'];
};

export type CreateCommunityInvitationPayload = {
    __typename?: 'CreateCommunityInvitationPayload';
    invitation: CommunityInvitation;
};

export type CreateCommunityPayload = {
    __typename?: 'CreateCommunityPayload';
    community: Community;
};

export type CreateCommunityTranslationInput = {
    description?: InputMaybe<Scalars['String']>;
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type CreateEventInput = {
    communityId: Scalars['String'];
    externalLink?: InputMaybe<Scalars['String']>;
    images?: InputMaybe<Array<EventImageInput>>;
    isDraft: Scalars['Boolean'];
    isExternal: Scalars['Boolean'];
    location?: InputMaybe<PointInput>;
    price?: InputMaybe<Scalars['Float']>;
    startTime?: InputMaybe<Scalars['DateTime']>;
    tags?: InputMaybe<Array<Scalars['String']>>;
    translations: Array<CreateEventTranslationInput>;
};

export type CreateEventPayload = {
    __typename?: 'CreateEventPayload';
    event: Event;
};

export type CreateEventPostInput = {
    eventId: Scalars['String'];
    type: PostType;
};

export type CreateEventPostPayload = {
    __typename?: 'CreateEventPostPayload';
    success: Scalars['Boolean'];
};

export type CreateEventTranslationInput = {
    address?: InputMaybe<Scalars['String']>;
    description: Scalars['String'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type CreateMemberProfileInput = {
    bio?: InputMaybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: InputMaybe<Gender>;
    lastName: Scalars['String'];
    maritalStatus?: InputMaybe<MaritalStatus>;
    phoneNumber: Scalars['String'];
};

export type CreateMemberProfilePayload = {
    __typename?: 'CreateMemberProfilePayload';
    memberProfile: MemberProfile;
};

export type CreatePostCommentInput = {
    postId: Scalars['String'];
    text: Scalars['String'];
};

export type CreatePostCommentPayload = {
    __typename?: 'CreatePostCommentPayload';
    comment: PostComment;
};

export type CreatePostInput = {
    communityId: Scalars['String'];
    files?: InputMaybe<Array<Scalars['Upload']>>;
    partnerId?: InputMaybe<Scalars['String']>;
    translations: Array<CreatePostTranslationInput>;
    type?: InputMaybe<Scalars['String']>;
    visibility: PostVisibility;
};

export type CreatePostPayload = {
    __typename?: 'CreatePostPayload';
    post: Post;
};

export type CreatePostTranslationInput = {
    body?: InputMaybe<Scalars['String']>;
    lang: LanguageCode;
};

export type CredentialsInput = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type DefaultPostMeta = {
    __typename?: 'DefaultPostMeta';
    type: PostType;
};

export type DeleteCommunityImageInput = {
    communityId: Scalars['String'];
    imageId: Scalars['String'];
};

export type DeleteCommunityImagePayload = {
    __typename?: 'DeleteCommunityImagePayload';
    community: Community;
};

export type DeleteCommunityInvitationPayload = {
    __typename?: 'DeleteCommunityInvitationPayload';
    invitation: CommunityInvitation;
};

export type DeleteCommunityInvitationyInput = {
    invitationId: Scalars['String'];
};

export type DeleteCommunityPayload = {
    __typename?: 'DeleteCommunityPayload';
    id: Scalars['String'];
};

export type DeleteEventImageInput = {
    eventId: Scalars['String'];
    imageId: Scalars['String'];
};

export type DeleteEventImagePayload = {
    __typename?: 'DeleteEventImagePayload';
    event: Event;
};

export type DeleteEventPayload = {
    __typename?: 'DeleteEventPayload';
    id: Scalars['String'];
};

export type DeleteManagerInput = {
    communityId: Scalars['String'];
    managerId: Scalars['String'];
};

export type DeleteManagerPayload = {
    __typename?: 'DeleteManagerPayload';
    id: Scalars['String'];
};

export type DeletePostCommentPayload = {
    __typename?: 'DeletePostCommentPayload';
    comment: PostComment;
};

export type DeletePostPayload = {
    __typename?: 'DeletePostPayload';
    id: Scalars['String'];
};

export type DeleteUserImageInput = {
    imageId: Scalars['String'];
};

export type DislikePostInput = {
    postId: Scalars['String'];
};

export type DislikePostPayload = {
    __typename?: 'DislikePostPayload';
    likeCount: Scalars['Int'];
};

export type Event = RelayNode & {
    __typename?: 'Event';
    community: Community;
    communityId: Scalars['String'];
    createdById: Scalars['String'];
    externalLink?: Maybe<Scalars['String']>;
    featured: Scalars['Boolean'];
    id: Scalars['ID'];
    images: EventImageConnection;
    isBookmarked?: Maybe<Scalars['Boolean']>;
    isDraft?: Maybe<Scalars['Boolean']>;
    isExternal: Scalars['Boolean'];
    location?: Maybe<Point>;
    pinned: Scalars['Boolean'];
    price?: Maybe<Scalars['Float']>;
    purchased: Scalars['Boolean'];
    startTime?: Maybe<Scalars['DateTime']>;
    tags?: Maybe<Array<Scalars['String']>>;
    translations: EventTranslationConnection;
    type: EventType;
};

export type EventConnection = {
    __typename?: 'EventConnection';
    edges: Array<EventEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventEdge = {
    __typename?: 'EventEdge';
    node: Event;
};

export type EventImage = RelayNode & {
    __typename?: 'EventImage';
    id: Scalars['ID'];
    image: FileInfo;
    order: Scalars['Int'];
};

export type EventImageConnection = {
    __typename?: 'EventImageConnection';
    edges: Array<EventImageEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventImageEdge = {
    __typename?: 'EventImageEdge';
    node: EventImage;
};

export type EventImageInput = {
    image: Scalars['Upload'];
    order: Scalars['Int'];
};

export type EventImageOrderInput = {
    id: Scalars['String'];
    order: Scalars['Int'];
};

export type EventMeta = {
    __typename?: 'EventMeta';
    event: Event;
    eventId: Scalars['String'];
    type: PostType;
};

export enum EventOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    StartTimeAsc = 'START_TIME_ASC',
    StartTimeDesc = 'START_TIME_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
}

export type EventPaymentInput = {
    eventId: Scalars['String'];
    paymentMethodId: Scalars['String'];
};

export type EventPaymentPayload = {
    __typename?: 'EventPaymentPayload';
    result: Scalars['Boolean'];
};

export type EventTranslation = RelayNode & {
    __typename?: 'EventTranslation';
    address?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    eventId: Scalars['String'];
    id: Scalars['ID'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type EventTranslationConnection = {
    __typename?: 'EventTranslationConnection';
    edges: Array<EventTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EventTranslationEdge = {
    __typename?: 'EventTranslationEdge';
    node: EventTranslation;
};

export enum EventType {
    Event = 'EVENT',
    Experience = 'EXPERIENCE',
}

export type EventWhereInput = {
    communityId?: InputMaybe<Scalars['String']>;
    keyword?: InputMaybe<Scalars['String']>;
    tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ExperienceMeta = {
    __typename?: 'ExperienceMeta';
    experience: Event;
    experienceId: Scalars['String'];
    type: PostType;
};

export type FileInfo = RelayNode & {
    __typename?: 'FileInfo';
    blurhash?: Maybe<BlurhashMeta>;
    createdAt: Scalars['DateTime'];
    extension: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    objectName?: Maybe<Scalars['String']>;
    size: Scalars['Int'];
    updatedAt: Scalars['DateTime'];
    url: Scalars['String'];
    version: Scalars['Int'];
};

export type FileInfoConnection = {
    __typename?: 'FileInfoConnection';
    edges: Array<FileInfoEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type FileInfoEdge = {
    __typename?: 'FileInfoEdge';
    node: FileInfo;
};

export type Follow = RelayNode & {
    __typename?: 'Follow';
    accepted: Scalars['Boolean'];
    acceptedAt?: Maybe<Scalars['DateTime']>;
    follower: User;
    followingUser: User;
    fromId: Scalars['String'];
    id: Scalars['ID'];
    toId: Scalars['String'];
};

export type FollowConnection = {
    __typename?: 'FollowConnection';
    edges: Array<FollowEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type FollowEdge = {
    __typename?: 'FollowEdge';
    node: Follow;
};

export enum FollowStatus {
    Following = 'FOLLOWING',
    FollowRequestSent = 'FOLLOW_REQUEST_SENT',
    None = 'NONE',
}

export type FollowUserInput = {
    followingUserId: Scalars['String'];
};

export type FollowUserPayload = {
    __typename?: 'FollowUserPayload';
    follow: Follow;
};

export type FollowWhereInput = {
    userId?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
    email: Scalars['String'];
};

export type ForgotPasswordPayload = {
    __typename?: 'ForgotPasswordPayload';
    sent: Scalars['Boolean'];
};

export enum Gender {
    Female = 'FEMALE',
    Male = 'MALE',
}

export type JoinCommunityInput = {
    communityId: Scalars['String'];
};

export type JoinCommunityPayload = {
    __typename?: 'JoinCommunityPayload';
    community: Community;
};

export enum JoinRule {
    Invite = 'INVITE',
    Knock = 'KNOCK',
    Public = 'PUBLIC',
}

export enum LanguageCode {
    En = 'EN',
    Lv = 'LV',
    Ru = 'RU',
}

export type LeaveCommunityInput = {
    communityId: Scalars['String'];
};

export type LeaveCommunityPayload = {
    __typename?: 'LeaveCommunityPayload';
    community: Community;
};

export type LikePostInput = {
    postId: Scalars['String'];
};

export type LikePostPayload = {
    __typename?: 'LikePostPayload';
    likeCount: Scalars['Int'];
};

export type LoginPayload = {
    __typename?: 'LoginPayload';
    accessToken: Scalars['String'];
    matrixAuth?: Maybe<MatrixAuthData>;
};

export type Manager = RelayNode & {
    __typename?: 'Manager';
    avatarId?: Maybe<Scalars['String']>;
    coverPhotoId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: ManagerType;
    user: User;
    userId: Scalars['String'];
};

export type ManagerConnection = {
    __typename?: 'ManagerConnection';
    edges: Array<ManagerEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ManagerEdge = {
    __typename?: 'ManagerEdge';
    node: Manager;
};

export enum ManagerType {
    Admin = 'ADMIN',
    BranchManager = 'BRANCH_MANAGER',
    OperationalManager = 'OPERATIONAL_MANAGER',
    PartnerManager = 'PARTNER_MANAGER',
}

export enum MaritalStatus {
    HavePartner = 'HAVE_PARTNER',
    Married = 'MARRIED',
    Single = 'SINGLE',
}

export type MatrixAuthData = {
    __typename?: 'MatrixAuthData';
    access_token: Scalars['String'];
    device_id: Scalars['String'];
    home_server: Scalars['String'];
    user_id: Scalars['String'];
};

export type Member = RelayNode & {
    __typename?: 'Member';
    cityOfResidence: Scalars['String'];
    communities: CommunityConnection;
    countryOfResidence: Country;
    countryOfResidenceId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    maritalStatus?: Maybe<MaritalStatus>;
    profiles: MemberProfileConnection;
    status: MemberStatus;
    updatedAt: Scalars['DateTime'];
    user: User;
    userId: Scalars['String'];
    version: Scalars['Int'];
};

export type MemberConnection = {
    __typename?: 'MemberConnection';
    edges: Array<MemberEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type MemberEdge = {
    __typename?: 'MemberEdge';
    node: Member;
};

export enum MemberOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type MemberProfile = RelayNode & {
    __typename?: 'MemberProfile';
    avatarId?: Maybe<Scalars['String']>;
    bio?: Maybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidence: Country;
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    maritalStatus?: Maybe<MaritalStatus>;
    memberId: Scalars['String'];
    middleName?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
};

export type MemberProfileConnection = {
    __typename?: 'MemberProfileConnection';
    edges: Array<MemberProfileEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type MemberProfileEdge = {
    __typename?: 'MemberProfileEdge';
    node: MemberProfile;
};

export enum MemberStatus {
    Approved = 'APPROVED',
    Guest = 'GUEST',
    PendingApproval = 'PENDING_APPROVAL',
    PendingPayment = 'PENDING_PAYMENT',
}

export type MemberWhereInput = {
    keyword?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    acceptFollow: AcceptFollowPayload;
    acceptMembershipRequest: AcceptMembershipRequestPayload;
    activateAccount: ActivateAccountPayload;
    addPaymentMethod: AddPaymentMethodPayload;
    bookmarkEvent: BookmarkEventPayload;
    bookmarkPost: BookmarkPostPayload;
    cancelFollow: Scalars['Boolean'];
    cancelMembershipRequest: CancelMembershipRequestPayload;
    cancelSubscription: Scalars['Boolean'];
    changePassword: ChangePasswordPayload;
    createCommunity: CreateCommunityPayload;
    createCommunityInvitation: CreateCommunityInvitationPayload;
    createEvent: CreateEventPayload;
    createEventPost: CreateEventPostPayload;
    createExperience: CreateEventPayload;
    createMemberProfile: CreateMemberProfilePayload;
    createPost: CreatePostPayload;
    createPostComment: CreatePostCommentPayload;
    deleteCommunity: DeleteCommunityPayload;
    deleteCommunityImage: DeleteCommunityImagePayload;
    deleteCommunityInvitation: DeleteCommunityInvitationPayload;
    deleteEvent: DeleteEventPayload;
    deleteEventImage: DeleteEventImagePayload;
    deleteManager: DeleteManagerPayload;
    deleteMemberProfile: MemberProfile;
    deletePaymentMethod: Scalars['String'];
    deletePost: DeletePostPayload;
    deletePostComment: DeletePostCommentPayload;
    deleteUserImage: Scalars['String'];
    dislikePost: DislikePostPayload;
    eventPayment: EventPaymentPayload;
    followUser: FollowUserPayload;
    joinCommunity: JoinCommunityPayload;
    leaveCommunity: LeaveCommunityPayload;
    likePost: LikePostPayload;
    login: LoginPayload;
    registerManager: Manager;
    registerMatrixUser: MatrixAuthData;
    registerMember: Scalars['String'];
    rejectFollow: RejectFollowPayload;
    rejectMembershipRequest: RejectMembershipRequestPayload;
    removeFollower: Scalars['Boolean'];
    reorderCommunityImages: ReorderCommunityImagesPayload;
    reorderEventImages: ReorderEventImagesPayload;
    resetPassword: ResetPasswordPayload;
    sendPasswordResetEmail: ForgotPasswordPayload;
    sendVerificationEmail: SendVerifyEmailPayload;
    setDefaultPaymentMethod: SetDefaultPaymentMethodPayload;
    sharePost: SharePostPayload;
    subscription: SubscriptionPayload;
    togglePin: TogglePinEventPayload;
    unfollow: Scalars['Boolean'];
    updateCommunity: UpdateCommunityPayload;
    updateCommunityPartners: UpdateCommunityPartnersPayload;
    updateEvent: UpdateEventPayload;
    updateManager: UpdateManagerPayload;
    updateMember: UpdateMemberPayload;
    updateMemberProfile: UpdateMemberProfilePayload;
    updatePost: UpdatePostPayload;
    uploadCommunityImages: UploadCommunityImagesPayload;
    uploadCommunityLogo: UploadCommunityLogoPayload;
    uploadEventImages: UploadEventImagesPayload;
    uploadUserAvatar: UploadUserAvatarPayload;
    uploadUserCoverPhoto: UploadUserCoverPhotoPayload;
    uploadUserImages: UploadUserImagesPayload;
};

export type MutationAcceptFollowArgs = {
    input: AcceptFollowInput;
};

export type MutationAcceptMembershipRequestArgs = {
    input: AcceptMembershipRequestInput;
};

export type MutationActivateAccountArgs = {
    input: ActivateAccountInput;
};

export type MutationAddPaymentMethodArgs = {
    input: AddPaymentMethodInput;
};

export type MutationBookmarkEventArgs = {
    eventId: BookmarkEventInput;
};

export type MutationBookmarkPostArgs = {
    post: BookmarkPostInput;
};

export type MutationCancelFollowArgs = {
    input: CancelFollowInput;
};

export type MutationCancelMembershipRequestArgs = {
    input: CancelMembershipRequestInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationCreateCommunityArgs = {
    community: CreateCommunityInput;
};

export type MutationCreateCommunityInvitationArgs = {
    input: CreateCommunityInvitationInput;
};

export type MutationCreateEventArgs = {
    event: CreateEventInput;
};

export type MutationCreateEventPostArgs = {
    post: CreateEventPostInput;
};

export type MutationCreateExperienceArgs = {
    experience: CreateEventInput;
};

export type MutationCreateMemberProfileArgs = {
    memberProfile: CreateMemberProfileInput;
};

export type MutationCreatePostArgs = {
    post: CreatePostInput;
};

export type MutationCreatePostCommentArgs = {
    comment: CreatePostCommentInput;
};

export type MutationDeleteCommunityArgs = {
    id: Scalars['String'];
};

export type MutationDeleteCommunityImageArgs = {
    community: DeleteCommunityImageInput;
};

export type MutationDeleteCommunityInvitationArgs = {
    input: DeleteCommunityInvitationyInput;
};

export type MutationDeleteEventArgs = {
    id: Scalars['String'];
};

export type MutationDeleteEventImageArgs = {
    event: DeleteEventImageInput;
};

export type MutationDeleteManagerArgs = {
    input: DeleteManagerInput;
};

export type MutationDeleteMemberProfileArgs = {
    id: Scalars['String'];
};

export type MutationDeletePaymentMethodArgs = {
    id: Scalars['String'];
};

export type MutationDeletePostArgs = {
    id: Scalars['String'];
};

export type MutationDeletePostCommentArgs = {
    id: Scalars['String'];
};

export type MutationDeleteUserImageArgs = {
    input: DeleteUserImageInput;
};

export type MutationDislikePostArgs = {
    post: DislikePostInput;
};

export type MutationEventPaymentArgs = {
    input: EventPaymentInput;
};

export type MutationFollowUserArgs = {
    input: FollowUserInput;
};

export type MutationJoinCommunityArgs = {
    input: JoinCommunityInput;
};

export type MutationLeaveCommunityArgs = {
    input: LeaveCommunityInput;
};

export type MutationLikePostArgs = {
    post: LikePostInput;
};

export type MutationLoginArgs = {
    credentials: CredentialsInput;
};

export type MutationRegisterManagerArgs = {
    manager: RegisterManagerInput;
};

export type MutationRegisterMatrixUserArgs = {
    input: RegisterMatrixUserInput;
};

export type MutationRegisterMemberArgs = {
    member: RegisterMemberInput;
};

export type MutationRejectFollowArgs = {
    input: RejectFollowInput;
};

export type MutationRejectMembershipRequestArgs = {
    input: RejectMembershipRequestInput;
};

export type MutationRemoveFollowerArgs = {
    input: RemoveFollowerInput;
};

export type MutationReorderCommunityImagesArgs = {
    community: ReorderCommunityImagesInput;
};

export type MutationReorderEventImagesArgs = {
    event: ReorderEventImagesInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationSendPasswordResetEmailArgs = {
    input: ForgotPasswordInput;
};

export type MutationSendVerificationEmailArgs = {
    input: SendVerifyEmailInput;
};

export type MutationSetDefaultPaymentMethodArgs = {
    paymentMethodId: Scalars['String'];
};

export type MutationSharePostArgs = {
    post: SharePostInput;
};

export type MutationSubscriptionArgs = {
    input: SubscriptionInput;
};

export type MutationTogglePinArgs = {
    event: TogglePinEventInput;
};

export type MutationUnfollowArgs = {
    input: UnfollowInput;
};

export type MutationUpdateCommunityArgs = {
    community: UpdateCommunityInput;
};

export type MutationUpdateCommunityPartnersArgs = {
    input: UpdateCommunityPartnersInput;
};

export type MutationUpdateEventArgs = {
    event: UpdateEventInput;
};

export type MutationUpdateManagerArgs = {
    manager: UpdateManagerInput;
};

export type MutationUpdateMemberArgs = {
    member: UpdateMemberInput;
};

export type MutationUpdateMemberProfileArgs = {
    memberProfile: UpdateMemberProfileInput;
};

export type MutationUpdatePostArgs = {
    post: UpdatePostInput;
};

export type MutationUploadCommunityImagesArgs = {
    community: UploadCommunityImagesInput;
};

export type MutationUploadCommunityLogoArgs = {
    community: UploadCommunityLogoInput;
};

export type MutationUploadEventImagesArgs = {
    event: UploadEventImagesInput;
};

export type MutationUploadUserAvatarArgs = {
    input: UploadUserAvatarInput;
};

export type MutationUploadUserCoverPhotoArgs = {
    input: UploadUserCoverPhotoInput;
};

export type MutationUploadUserImagesArgs = {
    input: UploadUserImagesInput;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    page: Scalars['Int'];
    totalPages: Scalars['Int'];
};

export enum PartnerCategory {
    Club = 'CLUB',
    Healthcare = 'HEALTHCARE',
    Hotel = 'HOTEL',
    Other = 'OTHER',
    Restaurant = 'RESTAURANT',
    Shop = 'SHOP',
}

export type PartnerWhereInput = {
    categories?: InputMaybe<Array<PartnerCategory>>;
    communityId?: InputMaybe<Scalars['String']>;
    keyword?: InputMaybe<Scalars['String']>;
    noPagination?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentMethod = {
    __typename?: 'PaymentMethod';
    brand: Scalars['String'];
    cartToken: Scalars['String'];
    expMonth: Scalars['Int'];
    expYear: Scalars['Int'];
    funding: Scalars['String'];
    id: Scalars['String'];
    isDefault: Scalars['Boolean'];
    lastFour: Scalars['String'];
};

export type PaymentMethodConnection = {
    __typename?: 'PaymentMethodConnection';
    edges: Array<PaymentMethodEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PaymentMethodEdge = {
    __typename?: 'PaymentMethodEdge';
    node: PaymentMethod;
};

export enum PendingMembershipOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type PendingMembershipWhereInput = {
    communityId: Scalars['String'];
};

export type Plan = {
    __typename?: 'Plan';
    benefits?: Maybe<Array<Scalars['String']>>;
    code: Scalars['String'];
    id: Scalars['String'];
    price: Scalars['Float'];
};

export type PlanConnection = {
    __typename?: 'PlanConnection';
    edges: Array<PlanEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PlanEdge = {
    __typename?: 'PlanEdge';
    node: Plan;
};

export type Point = {
    __typename?: 'Point';
    lat: Scalars['Float'];
    long: Scalars['Float'];
};

export type PointInput = {
    lat: Scalars['Float'];
    long: Scalars['Float'];
};

export type Post = RelayNode & {
    __typename?: 'Post';
    author: User;
    authorId: Scalars['String'];
    comments: PostCommentConnection;
    community: Community;
    communityId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    files: FileInfoConnection;
    id: Scalars['ID'];
    isBookmarked?: Maybe<Scalars['Boolean']>;
    isLiked: Scalars['Boolean'];
    likeCount: Scalars['Int'];
    meta: PostMetaUnion;
    repostCount: Scalars['Int'];
    topComments: PostCommentConnection;
    translations: PostTranslationConnection;
    type: PostType;
    updatedAt: Scalars['DateTime'];
    visibility: PostVisibility;
};

export type PostCommentsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type PostComment = RelayNode & {
    __typename?: 'PostComment';
    author: User;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    postId: Scalars['String'];
    text: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    userId: Scalars['String'];
};

export type PostCommentConnection = {
    __typename?: 'PostCommentConnection';
    edges: Array<PostCommentEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostCommentEdge = {
    __typename?: 'PostCommentEdge';
    node: PostComment;
};

export type PostConnection = {
    __typename?: 'PostConnection';
    edges: Array<PostEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostEdge = {
    __typename?: 'PostEdge';
    node: Post;
};

export type PostLike = RelayNode & {
    __typename?: 'PostLike';
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    postId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    user: User;
    userId: Scalars['String'];
    version: Scalars['Int'];
};

export type PostLikeConnection = {
    __typename?: 'PostLikeConnection';
    edges: Array<PostLikeEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostLikeEdge = {
    __typename?: 'PostLikeEdge';
    node: PostLike;
};

export type PostLikeWhereInput = {
    postId: Scalars['String'];
};

export type PostMetaUnion =
    | CoverPhotoChangeMeta
    | DefaultPostMeta
    | EventMeta
    | ExperienceMeta
    | ProfilePictureChangeMeta
    | RepostMeta;

export type PostTranslation = RelayNode & {
    __typename?: 'PostTranslation';
    body?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lang: LanguageCode;
    postId: Scalars['String'];
};

export type PostTranslationConnection = {
    __typename?: 'PostTranslationConnection';
    edges: Array<PostTranslationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PostTranslationEdge = {
    __typename?: 'PostTranslationEdge';
    node: PostTranslation;
};

export enum PostType {
    CoverPhotoChange = 'COVER_PHOTO_CHANGE',
    Default = 'DEFAULT',
    Event = 'EVENT',
    Experience = 'EXPERIENCE',
    ProfilePictureChange = 'PROFILE_PICTURE_CHANGE',
    Repost = 'REPOST',
}

export enum PostVisibility {
    Followers = 'FOLLOWERS',
    Members = 'MEMBERS',
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type PostWhereInput = {
    communityId: Scalars['String'];
    keyword?: InputMaybe<Scalars['String']>;
};

export type ProfilePictureChangeMeta = {
    __typename?: 'ProfilePictureChangeMeta';
    profilePictureUrl: Scalars['String'];
    type: PostType;
    user: User;
    userId: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    activities: ActivityConnection;
    activityCount: Scalars['Int'];
    approvedMembers?: Maybe<UserConnection>;
    bookmarkedEvents: EventConnection;
    bookmarkedExperiences: EventConnection;
    bookmarkedPosts: PostConnection;
    chatableUsers: UserConnection;
    cities: CityConnection;
    communities: CommunityConnection;
    community: Community;
    communityInvitation: CommunityInvitation;
    communityMembers: MemberConnection;
    communityPostsWithImages: PostConnection;
    countries: CountryConnection;
    dasboards: ReportConnection;
    event: Event;
    events: EventConnection;
    experience: Event;
    experiences: EventConnection;
    featuredEvents: EventConnection;
    fileInfo: FileInfo;
    followRequests: FollowConnection;
    followerCount: Scalars['Int'];
    followers: UserConnection;
    followingCount: Scalars['Int'];
    followings: UserConnection;
    guests?: Maybe<UserConnection>;
    me?: Maybe<User>;
    member?: Maybe<Member>;
    members?: Maybe<MemberConnection>;
    partners: CommunityConnection;
    pastEvents: EventConnection;
    paymentMethods: PaymentMethodConnection;
    pendingMemberships: CommunityMembershipConnection;
    peopleAvailableToChat: UserConnection;
    plans: PlanConnection;
    post: Post;
    postForEvent: Post;
    postLikes: PostLikeConnection;
    posts: PostConnection;
    reports: ReportConnection;
    repostedUsers: UserConnection;
    upcomingEvents: EventConnection;
    user: User;
    userPosts: PostConnection;
    userPostsWithImages: PostConnection;
    users: UserConnection;
};

export type QueryActivitiesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryApprovedMembersArgs = {
    orderBy?: InputMaybe<MemberOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MemberWhereInput>;
};

export type QueryBookmarkedEventsArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryBookmarkedExperiencesArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryBookmarkedPostsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: PostWhereInput;
};

export type QueryChatableUsersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ChatableUsersWhereInput>;
};

export type QueryCitiesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryCommunitiesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommunityWhereInput>;
};

export type QueryCommunityArgs = {
    id: Scalars['String'];
};

export type QueryCommunityInvitationArgs = {
    id: Scalars['String'];
};

export type QueryCommunityMembersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: CommunityMembersWhereInput;
};

export type QueryCommunityPostsWithImagesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: PostWhereInput;
};

export type QueryCountriesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryEventArgs = {
    id: Scalars['String'];
};

export type QueryEventsArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryExperienceArgs = {
    id: Scalars['String'];
};

export type QueryExperiencesArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryFileInfoArgs = {
    id: Scalars['String'];
};

export type QueryFollowRequestsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
};

export type QueryFollowerCountArgs = {
    where?: InputMaybe<FollowWhereInput>;
};

export type QueryFollowersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
};

export type QueryFollowingCountArgs = {
    where?: InputMaybe<FollowWhereInput>;
};

export type QueryFollowingsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
};

export type QueryGuestsArgs = {
    orderBy?: InputMaybe<MemberOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MemberWhereInput>;
};

export type QueryMemberArgs = {
    id: Scalars['String'];
};

export type QueryMembersArgs = {
    orderBy?: InputMaybe<MemberOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MemberWhereInput>;
};

export type QueryPartnersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PartnerWhereInput>;
};

export type QueryPastEventsArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryPendingMembershipsArgs = {
    orderBy?: InputMaybe<PendingMembershipOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: PendingMembershipWhereInput;
};

export type QueryPeopleAvailableToChatArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryPlansArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPostArgs = {
    id: Scalars['String'];
};

export type QueryPostForEventArgs = {
    eventId: Scalars['String'];
    eventType: Scalars['String'];
};

export type QueryPostLikesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: PostLikeWhereInput;
};

export type QueryPostsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: PostWhereInput;
};

export type QueryRepostedUsersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: RepostedUserWhereInput;
};

export type QueryUpcomingEventsArgs = {
    orderBy?: InputMaybe<EventOrderBy>;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryUserArgs = {
    id: Scalars['String'];
};

export type QueryUserPostsArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    userId: Scalars['String'];
    where: PostWhereInput;
};

export type QueryUserPostsWithImagesArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    userId: Scalars['String'];
    where: PostWhereInput;
};

export type QueryUsersArgs = {
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserWhereInput>;
};

export type RegisterManagerInput = {
    communityId: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type RegisterMatrixUserInput = {
    nickname: Scalars['String'];
    password: Scalars['String'];
};

export type RegisterMemberInput = {
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: InputMaybe<Gender>;
    incognito: Scalars['Boolean'];
    invitationId?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type RejectFollowInput = {
    followerId: Scalars['String'];
};

export type RejectFollowPayload = {
    __typename?: 'RejectFollowPayload';
    follow: Follow;
};

export type RejectMembershipRequestInput = {
    rejectReason?: InputMaybe<Scalars['String']>;
    requestId: Scalars['String'];
};

export type RejectMembershipRequestPayload = {
    __typename?: 'RejectMembershipRequestPayload';
    id: Scalars['String'];
};

export type RelayNode = {
    id: Scalars['ID'];
};

export type RemoveFollowerInput = {
    followerId: Scalars['String'];
};

export type ReorderCommunityImagesInput = {
    communityId: Scalars['String'];
    images: Array<CommunityImageOrderInput>;
};

export type ReorderCommunityImagesPayload = {
    __typename?: 'ReorderCommunityImagesPayload';
    community: Community;
};

export type ReorderEventImagesInput = {
    eventId: Scalars['String'];
    images: Array<EventImageOrderInput>;
};

export type ReorderEventImagesPayload = {
    __typename?: 'ReorderEventImagesPayload';
    event: Event;
};

export type Report = RelayNode & {
    __typename?: 'Report';
    code: Scalars['String'];
    embedUrl: Scalars['String'];
    id: Scalars['ID'];
};

export type ReportConnection = {
    __typename?: 'ReportConnection';
    edges: Array<ReportEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ReportEdge = {
    __typename?: 'ReportEdge';
    node: Report;
};

export type RepostMeta = {
    __typename?: 'RepostMeta';
    post: Post;
    postId: Scalars['String'];
    type: PostType;
};

export type RepostedUserWhereInput = {
    postId: Scalars['String'];
};

export type ResetPasswordInput = {
    password: Scalars['String'];
    token: Scalars['String'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    success: Scalars['Boolean'];
};

export type Role = RelayNode & {
    __typename?: 'Role';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type SendVerifyEmailInput = {
    email: Scalars['String'];
};

export type SendVerifyEmailPayload = {
    __typename?: 'SendVerifyEmailPayload';
    sent: Scalars['Boolean'];
};

export type SetDefaultPaymentMethodPayload = {
    __typename?: 'SetDefaultPaymentMethodPayload';
    paymentMethodId: Scalars['String'];
};

export type SharePostInput = {
    communityId: Scalars['String'];
    files?: InputMaybe<Array<Scalars['Upload']>>;
    postId: Scalars['String'];
    translations: Array<CreatePostTranslationInput>;
    visibility: PostVisibility;
};

export type SharePostPayload = {
    __typename?: 'SharePostPayload';
    post: Post;
};

export type SubscriptionEdge = {
    __typename?: 'SubscriptionEdge';
    node: SubscriptionNode;
};

export type SubscriptionInput = {
    paymentMethodId: Scalars['String'];
    planId: Scalars['String'];
};

export type SubscriptionNode = {
    __typename?: 'SubscriptionNode';
    expiresAt: Scalars['DateTime'];
    id: Scalars['String'];
    plan?: Maybe<Plan>;
    planId: Scalars['String'];
    status: SubscriptionStatus;
    userId: Scalars['String'];
};

export type SubscriptionPayload = {
    __typename?: 'SubscriptionPayload';
    result: Scalars['Boolean'];
};

export enum SubscriptionStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export type TogglePinEventInput = {
    eventId: Scalars['String'];
    pinned: Scalars['Boolean'];
};

export type TogglePinEventPayload = {
    __typename?: 'TogglePinEventPayload';
    event: Event;
};

export type UnfollowInput = {
    followingUserId: Scalars['String'];
};

export type UpdateCommunityInput = {
    benefits?: InputMaybe<Array<BenefitInput>>;
    category: CommunityCategory;
    cityId: Scalars['String'];
    id: Scalars['String'];
    isInternal: Scalars['Boolean'];
    joinRule?: InputMaybe<JoinRule>;
    translations: Array<UpdateCommunityTranslationInput>;
};

export type UpdateCommunityPartnersInput = {
    communityId: Scalars['String'];
    partnerIds: Array<Scalars['String']>;
};

export type UpdateCommunityPartnersPayload = {
    __typename?: 'UpdateCommunityPartnersPayload';
    community: Community;
};

export type UpdateCommunityPayload = {
    __typename?: 'UpdateCommunityPayload';
    community: Community;
};

export type UpdateCommunityTranslationInput = {
    description?: InputMaybe<Scalars['String']>;
    lang: LanguageCode;
    location: Scalars['String'];
    name: Scalars['String'];
};

export type UpdateEventInput = {
    communityId: Scalars['String'];
    externalLink?: InputMaybe<Scalars['String']>;
    id: Scalars['String'];
    isDraft: Scalars['Boolean'];
    isExternal: Scalars['Boolean'];
    location?: InputMaybe<PointInput>;
    price?: InputMaybe<Scalars['Float']>;
    startTime?: InputMaybe<Scalars['DateTime']>;
    tags?: InputMaybe<Array<Scalars['String']>>;
    translations: Array<UpdateEventTranslationInput>;
};

export type UpdateEventPayload = {
    __typename?: 'UpdateEventPayload';
    event: Event;
};

export type UpdateEventTranslationInput = {
    address?: InputMaybe<Scalars['String']>;
    description: Scalars['String'];
    lang: LanguageCode;
    title: Scalars['String'];
};

export type UpdateManagerInput = {
    bio?: InputMaybe<Scalars['String']>;
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    nickname: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type UpdateManagerPayload = {
    __typename?: 'UpdateManagerPayload';
    manager: Manager;
};

export type UpdateMemberInput = {
    bio?: InputMaybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Gender>;
    incognito: Scalars['Boolean'];
    lastName: Scalars['String'];
    maritalStatus?: InputMaybe<MaritalStatus>;
    nickname?: InputMaybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
};

export type UpdateMemberPayload = {
    __typename?: 'UpdateMemberPayload';
    member: Member;
};

export type UpdateMemberProfileInput = {
    bio?: InputMaybe<Scalars['String']>;
    cityOfResidence: Scalars['String'];
    countryOfResidenceId: Scalars['String'];
    dateOfBirth: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: InputMaybe<Gender>;
    id: Scalars['String'];
    lastName: Scalars['String'];
    maritalStatus?: InputMaybe<MaritalStatus>;
    phoneNumber: Scalars['String'];
};

export type UpdateMemberProfilePayload = {
    __typename?: 'UpdateMemberProfilePayload';
    memberProfile: MemberProfile;
};

export type UpdatePostInput = {
    communityId: Scalars['String'];
    files?: InputMaybe<Array<Scalars['Upload']>>;
    id: Scalars['String'];
    previousFileIds?: InputMaybe<Array<Scalars['String']>>;
    translations: Array<UpdatePostTranslationInput>;
    visibility: PostVisibility;
};

export type UpdatePostPayload = {
    __typename?: 'UpdatePostPayload';
    post: Post;
};

export type UpdatePostTranslationInput = {
    body?: InputMaybe<Scalars['String']>;
    lang: LanguageCode;
};

export type UploadCommunityImagesInput = {
    communityId: Scalars['String'];
    images: Array<Scalars['Upload']>;
};

export type UploadCommunityImagesPayload = {
    __typename?: 'UploadCommunityImagesPayload';
    community: Community;
};

export type UploadCommunityLogoInput = {
    communityId: Scalars['String'];
    logo: Scalars['Upload'];
};

export type UploadCommunityLogoPayload = {
    __typename?: 'UploadCommunityLogoPayload';
    community: Community;
};

export type UploadEventImagesInput = {
    eventId: Scalars['String'];
    images: Array<Scalars['Upload']>;
};

export type UploadEventImagesPayload = {
    __typename?: 'UploadEventImagesPayload';
    event: Event;
};

export type UploadUserAvatarInput = {
    avatar: Scalars['Upload'];
};

export type UploadUserAvatarPayload = {
    __typename?: 'UploadUserAvatarPayload';
    user: User;
};

export type UploadUserCoverPhotoInput = {
    coverPhoto: Scalars['Upload'];
};

export type UploadUserCoverPhotoPayload = {
    __typename?: 'UploadUserCoverPhotoPayload';
    user: User;
};

export type UploadUserImagesInput = {
    images: Array<Scalars['Upload']>;
};

export type UploadUserImagesPayload = {
    __typename?: 'UploadUserImagesPayload';
    user: User;
};

export type User = RelayNode & {
    __typename?: 'User';
    avatar?: Maybe<FileInfo>;
    bio?: Maybe<Scalars['String']>;
    communities: CommunityConnection;
    coverPhoto?: Maybe<FileInfo>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    followStatus: FollowStatus;
    fullName: Scalars['String'];
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    images: FileInfoConnection;
    incognito?: Maybe<Scalars['Boolean']>;
    isChatable: Scalars['Boolean'];
    lastName: Scalars['String'];
    manager?: Maybe<Manager>;
    matrixAuth?: Maybe<MatrixAuthData>;
    member?: Maybe<Member>;
    nickname?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    postCount?: Maybe<Scalars['Int']>;
    role: Role;
    statusText?: Maybe<Scalars['String']>;
    subscription?: Maybe<SubscriptionNode>;
};

export type UserConnection = {
    __typename?: 'UserConnection';
    edges: Array<UserEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UserEdge = {
    __typename?: 'UserEdge';
    node: User;
};

export type UserWhereInput = {
    communityId?: InputMaybe<Scalars['String']>;
    keyword?: InputMaybe<Scalars['String']>;
};

export type AvatarPartsFragment = {
    __typename?: 'FileInfo';
    id: string;
    url: string;
    blurhash?: { __typename?: 'BlurhashMeta'; hash: string } | null;
};

export type EventPartsFragment = {
    __typename?: 'Event';
    id: string;
    createdById: string;
    communityId: string;
    startTime?: any | null;
    tags?: Array<string> | null;
    type: EventType;
    pinned: boolean;
    price?: number | null;
    isExternal: boolean;
    externalLink?: string | null;
    isDraft?: boolean | null;
    isBookmarked?: boolean | null;
    purchased: boolean;
    translations: {
        __typename?: 'EventTranslationConnection';
        edges: Array<{
            __typename?: 'EventTranslationEdge';
            node: {
                __typename?: 'EventTranslation';
                id: string;
                lang: LanguageCode;
                title: string;
                description: string;
                address?: string | null;
            };
        }>;
    };
    community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
    location?: { __typename?: 'Point'; lat: number; long: number } | null;
    images: {
        __typename?: 'EventImageConnection';
        edges: Array<{
            __typename?: 'EventImageEdge';
            node: {
                __typename?: 'EventImage';
                id: string;
                order: number;
                image: {
                    __typename?: 'FileInfo';
                    id: string;
                    url: string;
                    name: string;
                    extension: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                };
            };
        }>;
    };
};

export type ExperiencePartsFragment = {
    __typename?: 'Event';
    id: string;
    createdById: string;
    communityId: string;
    startTime?: any | null;
    tags?: Array<string> | null;
    price?: number | null;
    isExternal: boolean;
    isDraft?: boolean | null;
    isBookmarked?: boolean | null;
    externalLink?: string | null;
    purchased: boolean;
    pinned: boolean;
    type: EventType;
    community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
    translations: {
        __typename?: 'EventTranslationConnection';
        edges: Array<{
            __typename?: 'EventTranslationEdge';
            node: {
                __typename?: 'EventTranslation';
                id: string;
                lang: LanguageCode;
                title: string;
                description: string;
                address?: string | null;
            };
        }>;
    };
    location?: { __typename?: 'Point'; lat: number; long: number } | null;
    images: {
        __typename?: 'EventImageConnection';
        edges: Array<{
            __typename?: 'EventImageEdge';
            node: {
                __typename?: 'EventImage';
                id: string;
                order: number;
                image: {
                    __typename?: 'FileInfo';
                    id: string;
                    url: string;
                    name: string;
                    extension: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                };
            };
        }>;
    };
};

export type MemberProfilePartsFragment = {
    __typename?: 'MemberProfile';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    bio?: string | null;
    phoneNumber: string;
    dateOfBirth: any;
    cityOfResidence: string;
    countryOfResidenceId: string;
    maritalStatus?: MaritalStatus | null;
    countryOfResidence: { __typename?: 'Country'; id: string; name: string };
};

export type PostPartsFragment = {
    __typename?: 'Post';
    id: string;
    communityId: string;
    repostCount: number;
    likeCount: number;
    isLiked: boolean;
    isBookmarked?: boolean | null;
    visibility: PostVisibility;
    type: PostType;
    createdAt: any;
    authorId: string;
    translations: {
        __typename?: 'PostTranslationConnection';
        edges: Array<{
            __typename?: 'PostTranslationEdge';
            node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
        }>;
    };
    files: {
        __typename?: 'FileInfoConnection';
        edges: Array<{
            __typename?: 'FileInfoEdge';
            node: {
                __typename?: 'FileInfo';
                id: string;
                name: string;
                url: string;
                extension: string;
                blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
            };
        }>;
    };
    comments: {
        __typename?: 'PostCommentConnection';
        totalCount: number;
        edges: Array<{
            __typename?: 'PostCommentEdge';
            node: {
                __typename?: 'PostComment';
                id: string;
                text: string;
                createdAt: any;
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    statusText?: string | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
    topComments: {
        __typename?: 'PostCommentConnection';
        edges: Array<{
            __typename?: 'PostCommentEdge';
            node: {
                __typename?: 'PostComment';
                id: string;
                text: string;
                userId: string;
                createdAt: any;
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    email: string;
                    statusText?: string | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
    };
    author: {
        __typename?: 'User';
        id: string;
        fullName: string;
        statusText?: string | null;
        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        role: { __typename?: 'Role'; id: string; name: string };
        member?: { __typename?: 'Member'; id: string } | null;
    };
    meta:
        | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
        | { __typename?: 'DefaultPostMeta'; type: PostType }
        | {
              __typename?: 'EventMeta';
              eventId: string;
              event: {
                  __typename?: 'Event';
                  id: string;
                  createdById: string;
                  communityId: string;
                  startTime?: any | null;
                  tags?: Array<string> | null;
                  type: EventType;
                  pinned: boolean;
                  price?: number | null;
                  isExternal: boolean;
                  externalLink?: string | null;
                  isDraft?: boolean | null;
                  isBookmarked?: boolean | null;
                  purchased: boolean;
                  translations: {
                      __typename?: 'EventTranslationConnection';
                      edges: Array<{
                          __typename?: 'EventTranslationEdge';
                          node: {
                              __typename?: 'EventTranslation';
                              id: string;
                              lang: LanguageCode;
                              title: string;
                              description: string;
                              address?: string | null;
                          };
                      }>;
                  };
                  community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                  location?: { __typename?: 'Point'; lat: number; long: number } | null;
                  images: {
                      __typename?: 'EventImageConnection';
                      edges: Array<{
                          __typename?: 'EventImageEdge';
                          node: {
                              __typename?: 'EventImage';
                              id: string;
                              order: number;
                              image: {
                                  __typename?: 'FileInfo';
                                  id: string;
                                  url: string;
                                  name: string;
                                  extension: string;
                                  blurhash?: {
                                      __typename?: 'BlurhashMeta';
                                      hash: string;
                                      width: number;
                                      height: number;
                                  } | null;
                              };
                          };
                      }>;
                  };
              };
          }
        | {
              __typename?: 'ExperienceMeta';
              experienceId: string;
              type: PostType;
              experience: {
                  __typename?: 'Event';
                  id: string;
                  createdById: string;
                  communityId: string;
                  startTime?: any | null;
                  tags?: Array<string> | null;
                  price?: number | null;
                  isExternal: boolean;
                  isDraft?: boolean | null;
                  isBookmarked?: boolean | null;
                  externalLink?: string | null;
                  purchased: boolean;
                  pinned: boolean;
                  type: EventType;
                  community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                  translations: {
                      __typename?: 'EventTranslationConnection';
                      edges: Array<{
                          __typename?: 'EventTranslationEdge';
                          node: {
                              __typename?: 'EventTranslation';
                              id: string;
                              lang: LanguageCode;
                              title: string;
                              description: string;
                              address?: string | null;
                          };
                      }>;
                  };
                  location?: { __typename?: 'Point'; lat: number; long: number } | null;
                  images: {
                      __typename?: 'EventImageConnection';
                      edges: Array<{
                          __typename?: 'EventImageEdge';
                          node: {
                              __typename?: 'EventImage';
                              id: string;
                              order: number;
                              image: {
                                  __typename?: 'FileInfo';
                                  id: string;
                                  url: string;
                                  name: string;
                                  extension: string;
                                  blurhash?: {
                                      __typename?: 'BlurhashMeta';
                                      hash: string;
                                      width: number;
                                      height: number;
                                  } | null;
                              };
                          };
                      }>;
                  };
              };
          }
        | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
        | { __typename?: 'RepostMeta' };
};

export type UserPartsFragment = {
    __typename?: 'User';
    id: string;
    fullName: string;
    postCount?: number | null;
    bio?: string | null;
    followStatus: FollowStatus;
    statusText?: string | null;
    email: string;
    isChatable: boolean;
    role: { __typename?: 'Role'; id: string; name: string };
    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
    coverPhoto?: { __typename?: 'FileInfo'; id: string; url: string } | null;
    member?: { __typename?: 'Member'; id: string } | null;
    matrixAuth?: { __typename?: 'MatrixAuthData'; user_id: string } | null;
};

export type ActivateAccountMutationVariables = Exact<{
    input: ActivateAccountInput;
}>;

export type ActivateAccountMutation = {
    __typename?: 'Mutation';
    activateAccount: { __typename?: 'ActivateAccountPayload'; success: boolean };
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
    __typename?: 'Mutation';
    changePassword: { __typename?: 'ChangePasswordPayload'; success: boolean };
};

export type LoginMutationVariables = Exact<{
    credentials: CredentialsInput;
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: {
        __typename?: 'LoginPayload';
        accessToken: string;
        matrixAuth?: {
            __typename?: 'MatrixAuthData';
            home_server: string;
            access_token: string;
            user_id: string;
            device_id: string;
        } | null;
    };
};

export type RegisterMatrixUserMutationVariables = Exact<{
    input: RegisterMatrixUserInput;
}>;

export type RegisterMatrixUserMutation = {
    __typename?: 'Mutation';
    registerMatrixUser: {
        __typename?: 'MatrixAuthData';
        access_token: string;
        device_id: string;
        home_server: string;
        user_id: string;
    };
};

export type RegisterMemberMutationVariables = Exact<{
    member: RegisterMemberInput;
}>;

export type RegisterMemberMutation = { __typename?: 'Mutation'; registerMember: string };

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
    __typename?: 'Mutation';
    resetPassword: { __typename?: 'ResetPasswordPayload'; success: boolean };
};

export type SendPasswordResetEmailMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;

export type SendPasswordResetEmailMutation = {
    __typename?: 'Mutation';
    sendPasswordResetEmail: { __typename?: 'ForgotPasswordPayload'; sent: boolean };
};

export type SendVerifyEmailMutationVariables = Exact<{
    input: SendVerifyEmailInput;
}>;

export type SendVerifyEmailMutation = {
    __typename?: 'Mutation';
    sendVerificationEmail: { __typename?: 'SendVerifyEmailPayload'; sent: boolean };
};

export type AcceptMembershipRequestMutationVariables = Exact<{
    input: AcceptMembershipRequestInput;
}>;

export type AcceptMembershipRequestMutation = {
    __typename?: 'Mutation';
    acceptMembershipRequest: {
        __typename?: 'AcceptMembershipRequestPayload';
        community: { __typename?: 'Community'; id: string };
    };
};

export type CancelMembershipRequestMutationVariables = Exact<{
    input: CancelMembershipRequestInput;
}>;

export type CancelMembershipRequestMutation = {
    __typename?: 'Mutation';
    cancelMembershipRequest: {
        __typename?: 'CancelMembershipRequestPayload';
        community: { __typename?: 'Community'; id: string; membershipStatus?: CommunityMembershipStatus | null };
    };
};

export type CreateCommunityInvitationMutationVariables = Exact<{
    input: CreateCommunityInvitationInput;
}>;

export type CreateCommunityInvitationMutation = {
    __typename?: 'Mutation';
    createCommunityInvitation: {
        __typename?: 'CreateCommunityInvitationPayload';
        invitation: {
            __typename?: 'CommunityInvitation';
            id: string;
            usageLimit: number;
            usedCount: number;
            expiresAt: any;
        };
    };
};

export type CreateCommunityMutationVariables = Exact<{
    community: CreateCommunityInput;
}>;

export type CreateCommunityMutation = {
    __typename?: 'Mutation';
    createCommunity: { __typename?: 'CreateCommunityPayload'; community: { __typename?: 'Community'; id: string } };
};

export type DeleteCommunityImageMutationVariables = Exact<{
    community: DeleteCommunityImageInput;
}>;

export type DeleteCommunityImageMutation = {
    __typename?: 'Mutation';
    deleteCommunityImage: {
        __typename?: 'DeleteCommunityImagePayload';
        community: { __typename?: 'Community'; id: string };
    };
};

export type DeleteCommunityInvitationMutationVariables = Exact<{
    input: DeleteCommunityInvitationyInput;
}>;

export type DeleteCommunityInvitationMutation = {
    __typename?: 'Mutation';
    deleteCommunityInvitation: {
        __typename?: 'DeleteCommunityInvitationPayload';
        invitation: { __typename?: 'CommunityInvitation'; id: string };
    };
};

export type DeleteCommunityMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteCommunityMutation = {
    __typename?: 'Mutation';
    deleteCommunity: { __typename?: 'DeleteCommunityPayload'; id: string };
};

export type JoinCommunityMutationVariables = Exact<{
    input: JoinCommunityInput;
}>;

export type JoinCommunityMutation = {
    __typename?: 'Mutation';
    joinCommunity: {
        __typename?: 'JoinCommunityPayload';
        community: { __typename?: 'Community'; id: string; membershipStatus?: CommunityMembershipStatus | null };
    };
};

export type LeaveCommunityMutationVariables = Exact<{
    input: LeaveCommunityInput;
}>;

export type LeaveCommunityMutation = {
    __typename?: 'Mutation';
    leaveCommunity: {
        __typename?: 'LeaveCommunityPayload';
        community: { __typename?: 'Community'; id: string; membershipStatus?: CommunityMembershipStatus | null };
    };
};

export type RejectMembershipRequestMutationVariables = Exact<{
    input: RejectMembershipRequestInput;
}>;

export type RejectMembershipRequestMutation = {
    __typename?: 'Mutation';
    rejectMembershipRequest: { __typename?: 'RejectMembershipRequestPayload'; id: string };
};

export type UpdateCommunityPartnersMutationVariables = Exact<{
    input: UpdateCommunityPartnersInput;
}>;

export type UpdateCommunityPartnersMutation = {
    __typename?: 'Mutation';
    updateCommunityPartners: {
        __typename?: 'UpdateCommunityPartnersPayload';
        community: { __typename?: 'Community'; id: string };
    };
};

export type UpdateCommunityMutationVariables = Exact<{
    community: UpdateCommunityInput;
}>;

export type UpdateCommunityMutation = {
    __typename?: 'Mutation';
    updateCommunity: { __typename?: 'UpdateCommunityPayload'; community: { __typename?: 'Community'; id: string } };
};

export type UploadCommunityImagesMutationVariables = Exact<{
    community: UploadCommunityImagesInput;
}>;

export type UploadCommunityImagesMutation = {
    __typename?: 'Mutation';
    uploadCommunityImages: {
        __typename?: 'UploadCommunityImagesPayload';
        community: {
            __typename?: 'Community';
            id: string;
            images: {
                __typename?: 'FileInfoConnection';
                edges: Array<{
                    __typename?: 'FileInfoEdge';
                    node: {
                        __typename?: 'FileInfo';
                        id: string;
                        url: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                }>;
            };
        };
    };
};

export type UploadCommunityLogoMutationVariables = Exact<{
    community: UploadCommunityLogoInput;
}>;

export type UploadCommunityLogoMutation = {
    __typename?: 'Mutation';
    uploadCommunityLogo: {
        __typename?: 'UploadCommunityLogoPayload';
        community: {
            __typename?: 'Community';
            id: string;
            logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        };
    };
};

export type BookmarkEventMutationVariables = Exact<{
    eventId: BookmarkEventInput;
}>;

export type BookmarkEventMutation = {
    __typename?: 'Mutation';
    bookmarkEvent: {
        __typename?: 'BookmarkEventPayload';
        event: { __typename?: 'Event'; id: string; isBookmarked?: boolean | null };
    };
};

export type CreateEventPostMutationVariables = Exact<{
    post: CreateEventPostInput;
}>;

export type CreateEventPostMutation = {
    __typename?: 'Mutation';
    createEventPost: { __typename?: 'CreateEventPostPayload'; success: boolean };
};

export type CreateEventMutationVariables = Exact<{
    event: CreateEventInput;
}>;

export type CreateEventMutation = {
    __typename?: 'Mutation';
    createEvent: { __typename?: 'CreateEventPayload'; event: { __typename?: 'Event'; id: string } };
};

export type CreateExperienceMutationVariables = Exact<{
    experience: CreateEventInput;
}>;

export type CreateExperienceMutation = {
    __typename?: 'Mutation';
    createExperience: { __typename?: 'CreateEventPayload'; event: { __typename?: 'Event'; id: string } };
};

export type DeleteEventImageMutationVariables = Exact<{
    event: DeleteEventImageInput;
}>;

export type DeleteEventImageMutation = {
    __typename?: 'Mutation';
    deleteEventImage: { __typename?: 'DeleteEventImagePayload'; event: { __typename?: 'Event'; id: string } };
};

export type DeleteEventMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteEventMutation = {
    __typename?: 'Mutation';
    deleteEvent: { __typename?: 'DeleteEventPayload'; id: string };
};

export type EventPaymentMutationVariables = Exact<{
    input: EventPaymentInput;
}>;

export type EventPaymentMutation = {
    __typename?: 'Mutation';
    eventPayment: { __typename?: 'EventPaymentPayload'; result: boolean };
};

export type ReorderEventImagesMutationVariables = Exact<{
    event: ReorderEventImagesInput;
}>;

export type ReorderEventImagesMutation = {
    __typename?: 'Mutation';
    reorderEventImages: {
        __typename?: 'ReorderEventImagesPayload';
        event: {
            __typename?: 'Event';
            id: string;
            createdById: string;
            communityId: string;
            startTime?: any | null;
            tags?: Array<string> | null;
            type: EventType;
            pinned: boolean;
            price?: number | null;
            isExternal: boolean;
            externalLink?: string | null;
            isDraft?: boolean | null;
            isBookmarked?: boolean | null;
            purchased: boolean;
            translations: {
                __typename?: 'EventTranslationConnection';
                edges: Array<{
                    __typename?: 'EventTranslationEdge';
                    node: {
                        __typename?: 'EventTranslation';
                        id: string;
                        lang: LanguageCode;
                        title: string;
                        description: string;
                        address?: string | null;
                    };
                }>;
            };
            community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
            location?: { __typename?: 'Point'; lat: number; long: number } | null;
            images: {
                __typename?: 'EventImageConnection';
                edges: Array<{
                    __typename?: 'EventImageEdge';
                    node: {
                        __typename?: 'EventImage';
                        id: string;
                        order: number;
                        image: {
                            __typename?: 'FileInfo';
                            id: string;
                            url: string;
                            name: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    };
                }>;
            };
        };
    };
};

export type TogglePinMutationVariables = Exact<{
    event: TogglePinEventInput;
}>;

export type TogglePinMutation = {
    __typename?: 'Mutation';
    togglePin: { __typename?: 'TogglePinEventPayload'; event: { __typename?: 'Event'; id: string; pinned: boolean } };
};

export type UpdateEventMutationVariables = Exact<{
    event: UpdateEventInput;
}>;

export type UpdateEventMutation = {
    __typename?: 'Mutation';
    updateEvent: { __typename?: 'UpdateEventPayload'; event: { __typename?: 'Event'; id: string } };
};

export type UploadEventImagesMutationVariables = Exact<{
    event: UploadEventImagesInput;
}>;

export type UploadEventImagesMutation = {
    __typename?: 'Mutation';
    uploadEventImages: {
        __typename?: 'UploadEventImagesPayload';
        event: {
            __typename?: 'Event';
            id: string;
            images: {
                __typename?: 'EventImageConnection';
                edges: Array<{
                    __typename?: 'EventImageEdge';
                    node: {
                        __typename?: 'EventImage';
                        id: string;
                        order: number;
                        image: {
                            __typename?: 'FileInfo';
                            id: string;
                            url: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    };
                }>;
            };
        };
    };
};

export type AcceptFollowMutationVariables = Exact<{
    input: AcceptFollowInput;
}>;

export type AcceptFollowMutation = {
    __typename?: 'Mutation';
    acceptFollow: {
        __typename?: 'AcceptFollowPayload';
        follow: { __typename?: 'Follow'; id: string; accepted: boolean };
    };
};

export type CancelFollowMutationVariables = Exact<{
    input: CancelFollowInput;
}>;

export type CancelFollowMutation = { __typename?: 'Mutation'; cancelFollow: boolean };

export type FollowMutationVariables = Exact<{
    input: FollowUserInput;
}>;

export type FollowMutation = {
    __typename?: 'Mutation';
    followUser: { __typename?: 'FollowUserPayload'; follow: { __typename?: 'Follow'; id: string } };
};

export type RejectFollowMutationVariables = Exact<{
    input: RejectFollowInput;
}>;

export type RejectFollowMutation = {
    __typename?: 'Mutation';
    rejectFollow: {
        __typename?: 'RejectFollowPayload';
        follow: { __typename?: 'Follow'; id: string; accepted: boolean };
    };
};

export type RemoveFollowerMutationVariables = Exact<{
    input: RemoveFollowerInput;
}>;

export type RemoveFollowerMutation = { __typename?: 'Mutation'; removeFollower: boolean };

export type UnfollowMutationVariables = Exact<{
    input: UnfollowInput;
}>;

export type UnfollowMutation = { __typename?: 'Mutation'; unfollow: boolean };

export type DeleteManagerMutationVariables = Exact<{
    input: DeleteManagerInput;
}>;

export type DeleteManagerMutation = {
    __typename?: 'Mutation';
    deleteManager: { __typename?: 'DeleteManagerPayload'; id: string };
};

export type RegisterManagerMutationVariables = Exact<{
    manager: RegisterManagerInput;
}>;

export type RegisterManagerMutation = {
    __typename?: 'Mutation';
    registerManager: {
        __typename?: 'Manager';
        id: string;
        user: {
            __typename?: 'User';
            id: string;
            fullName: string;
            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        };
    };
};

export type UpdateManagerMutationVariables = Exact<{
    manager: UpdateManagerInput;
}>;

export type UpdateManagerMutation = {
    __typename?: 'Mutation';
    updateManager: {
        __typename?: 'UpdateManagerPayload';
        manager: {
            __typename?: 'Manager';
            id: string;
            user: {
                __typename?: 'User';
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                phoneNumber?: string | null;
                bio?: string | null;
                dateOfBirth?: any | null;
                gender?: Gender | null;
            };
        };
    };
};

export type CreateMemberProfileMutationVariables = Exact<{
    memberProfile: CreateMemberProfileInput;
}>;

export type CreateMemberProfileMutation = {
    __typename?: 'Mutation';
    createMemberProfile: {
        __typename?: 'CreateMemberProfilePayload';
        memberProfile: { __typename?: 'MemberProfile'; id: string };
    };
};

export type DeleteMemberProfileMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteMemberProfileMutation = {
    __typename?: 'Mutation';
    deleteMemberProfile: { __typename?: 'MemberProfile'; firstName: string };
};

export type UpdateMemberProfileMutationVariables = Exact<{
    memberProfile: UpdateMemberProfileInput;
}>;

export type UpdateMemberProfileMutation = {
    __typename?: 'Mutation';
    updateMemberProfile: {
        __typename?: 'UpdateMemberProfilePayload';
        memberProfile: {
            __typename?: 'MemberProfile';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            bio?: string | null;
            phoneNumber: string;
            dateOfBirth: any;
            cityOfResidence: string;
            countryOfResidenceId: string;
            maritalStatus?: MaritalStatus | null;
            countryOfResidence: { __typename?: 'Country'; id: string; name: string };
        };
    };
};

export type UpdateMemberMutationVariables = Exact<{
    member: UpdateMemberInput;
}>;

export type UpdateMemberMutation = {
    __typename?: 'Mutation';
    updateMember: {
        __typename?: 'UpdateMemberPayload';
        member: {
            __typename?: 'Member';
            id: string;
            maritalStatus?: MaritalStatus | null;
            cityOfResidence: string;
            countryOfResidenceId: string;
            countryOfResidence: { __typename?: 'Country'; id: string; name: string };
            user: {
                __typename?: 'User';
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                phoneNumber?: string | null;
                dateOfBirth?: any | null;
                incognito?: boolean | null;
                nickname?: string | null;
            };
        };
    };
};

export type AddPaymentMethodMutationVariables = Exact<{
    input: AddPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = {
    __typename?: 'Mutation';
    addPaymentMethod: {
        __typename?: 'AddPaymentMethodPayload';
        paymentMethod: {
            __typename?: 'PaymentMethod';
            id: string;
            brand: string;
            cartToken: string;
            funding: string;
            lastFour: string;
            expYear: number;
            expMonth: number;
            isDefault: boolean;
        };
    };
};

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelSubscriptionMutation = { __typename?: 'Mutation'; cancelSubscription: boolean };

export type DeletePaymentMethodMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePaymentMethodMutation = { __typename?: 'Mutation'; deletePaymentMethod: string };

export type SetDefaultPaymentMethodMutationVariables = Exact<{
    paymentMethodId: Scalars['String'];
}>;

export type SetDefaultPaymentMethodMutation = {
    __typename?: 'Mutation';
    setDefaultPaymentMethod: { __typename?: 'SetDefaultPaymentMethodPayload'; paymentMethodId: string };
};

export type SubscribeMutationVariables = Exact<{
    input: SubscriptionInput;
}>;

export type SubscribeMutation = {
    __typename?: 'Mutation';
    subscription: { __typename?: 'SubscriptionPayload'; result: boolean };
};

export type BookmarkPostMutationVariables = Exact<{
    post: BookmarkPostInput;
}>;

export type BookmarkPostMutation = {
    __typename?: 'Mutation';
    bookmarkPost: {
        __typename?: 'BookmarkPostPayload';
        post: { __typename?: 'Post'; id: string; isBookmarked?: boolean | null };
    };
};

export type CreatePostCommentMutationVariables = Exact<{
    comment: CreatePostCommentInput;
}>;

export type CreatePostCommentMutation = {
    __typename?: 'Mutation';
    createPostComment: {
        __typename?: 'CreatePostCommentPayload';
        comment: {
            __typename?: 'PostComment';
            id: string;
            text: string;
            userId: string;
            createdAt: any;
            author: {
                __typename?: 'User';
                id: string;
                fullName: string;
                email: string;
                statusText?: string | null;
                role: { __typename?: 'Role'; id: string; name: string };
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                member?: { __typename?: 'Member'; id: string } | null;
            };
        };
    };
};

export type CreatePostMutationVariables = Exact<{
    post: CreatePostInput;
}>;

export type CreatePostMutation = {
    __typename?: 'Mutation';
    createPost: {
        __typename?: 'CreatePostPayload';
        post: {
            __typename?: 'Post';
            id: string;
            communityId: string;
            repostCount: number;
            likeCount: number;
            isLiked: boolean;
            isBookmarked?: boolean | null;
            visibility: PostVisibility;
            type: PostType;
            createdAt: any;
            authorId: string;
            meta:
                | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                | { __typename?: 'DefaultPostMeta'; type: PostType }
                | {
                      __typename?: 'EventMeta';
                      eventId: string;
                      event: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          type: EventType;
                          pinned: boolean;
                          price?: number | null;
                          isExternal: boolean;
                          externalLink?: string | null;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          purchased: boolean;
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | {
                      __typename?: 'ExperienceMeta';
                      experienceId: string;
                      type: PostType;
                      experience: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          price?: number | null;
                          isExternal: boolean;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          externalLink?: string | null;
                          purchased: boolean;
                          pinned: boolean;
                          type: EventType;
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                | {
                      __typename?: 'RepostMeta';
                      post: {
                          __typename?: 'Post';
                          id: string;
                          communityId: string;
                          repostCount: number;
                          likeCount: number;
                          isLiked: boolean;
                          isBookmarked?: boolean | null;
                          visibility: PostVisibility;
                          type: PostType;
                          createdAt: any;
                          authorId: string;
                          translations: {
                              __typename?: 'PostTranslationConnection';
                              edges: Array<{
                                  __typename?: 'PostTranslationEdge';
                                  node: {
                                      __typename?: 'PostTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      body?: string | null;
                                  };
                              }>;
                          };
                          files: {
                              __typename?: 'FileInfoConnection';
                              edges: Array<{
                                  __typename?: 'FileInfoEdge';
                                  node: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      name: string;
                                      url: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              }>;
                          };
                          comments: {
                              __typename?: 'PostCommentConnection';
                              totalCount: number;
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                              pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                          };
                          topComments: {
                              __typename?: 'PostCommentConnection';
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      userId: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          email: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                          };
                          author: {
                              __typename?: 'User';
                              id: string;
                              fullName: string;
                              statusText?: string | null;
                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                              role: { __typename?: 'Role'; id: string; name: string };
                              member?: { __typename?: 'Member'; id: string } | null;
                          };
                          meta:
                              | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                              | { __typename?: 'DefaultPostMeta'; type: PostType }
                              | {
                                    __typename?: 'EventMeta';
                                    eventId: string;
                                    event: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        type: EventType;
                                        pinned: boolean;
                                        price?: number | null;
                                        isExternal: boolean;
                                        externalLink?: string | null;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        purchased: boolean;
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | {
                                    __typename?: 'ExperienceMeta';
                                    experienceId: string;
                                    type: PostType;
                                    experience: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        price?: number | null;
                                        isExternal: boolean;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        externalLink?: string | null;
                                        purchased: boolean;
                                        pinned: boolean;
                                        type: EventType;
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                              | { __typename?: 'RepostMeta' };
                      };
                  };
            translations: {
                __typename?: 'PostTranslationConnection';
                edges: Array<{
                    __typename?: 'PostTranslationEdge';
                    node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                }>;
            };
            files: {
                __typename?: 'FileInfoConnection';
                edges: Array<{
                    __typename?: 'FileInfoEdge';
                    node: {
                        __typename?: 'FileInfo';
                        id: string;
                        name: string;
                        url: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                }>;
            };
            comments: {
                __typename?: 'PostCommentConnection';
                totalCount: number;
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
                pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
            };
            topComments: {
                __typename?: 'PostCommentConnection';
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        userId: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
            };
            author: {
                __typename?: 'User';
                id: string;
                fullName: string;
                statusText?: string | null;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                role: { __typename?: 'Role'; id: string; name: string };
                member?: { __typename?: 'Member'; id: string } | null;
            };
        };
    };
};

export type DeletePostCommentMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePostCommentMutation = {
    __typename?: 'Mutation';
    deletePostComment: { __typename?: 'DeletePostCommentPayload'; comment: { __typename?: 'PostComment'; id: string } };
};

export type DeletePostMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeletePostMutation = {
    __typename?: 'Mutation';
    deletePost: { __typename?: 'DeletePostPayload'; id: string };
};

export type DislikePostMutationVariables = Exact<{
    post: DislikePostInput;
}>;

export type DislikePostMutation = {
    __typename?: 'Mutation';
    dislikePost: { __typename?: 'DislikePostPayload'; likeCount: number };
};

export type LikePostMutationVariables = Exact<{
    post: LikePostInput;
}>;

export type LikePostMutation = {
    __typename?: 'Mutation';
    likePost: { __typename?: 'LikePostPayload'; likeCount: number };
};

export type SharePostMutationVariables = Exact<{
    post: SharePostInput;
}>;

export type SharePostMutation = {
    __typename?: 'Mutation';
    sharePost: {
        __typename?: 'SharePostPayload';
        post: {
            __typename?: 'Post';
            id: string;
            communityId: string;
            repostCount: number;
            likeCount: number;
            isLiked: boolean;
            isBookmarked?: boolean | null;
            visibility: PostVisibility;
            type: PostType;
            createdAt: any;
            authorId: string;
            meta:
                | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                | { __typename?: 'DefaultPostMeta'; type: PostType }
                | {
                      __typename?: 'EventMeta';
                      eventId: string;
                      event: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          type: EventType;
                          pinned: boolean;
                          price?: number | null;
                          isExternal: boolean;
                          externalLink?: string | null;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          purchased: boolean;
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | {
                      __typename?: 'ExperienceMeta';
                      experienceId: string;
                      type: PostType;
                      experience: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          price?: number | null;
                          isExternal: boolean;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          externalLink?: string | null;
                          purchased: boolean;
                          pinned: boolean;
                          type: EventType;
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                | {
                      __typename?: 'RepostMeta';
                      post: {
                          __typename?: 'Post';
                          id: string;
                          communityId: string;
                          repostCount: number;
                          likeCount: number;
                          isLiked: boolean;
                          isBookmarked?: boolean | null;
                          visibility: PostVisibility;
                          type: PostType;
                          createdAt: any;
                          authorId: string;
                          translations: {
                              __typename?: 'PostTranslationConnection';
                              edges: Array<{
                                  __typename?: 'PostTranslationEdge';
                                  node: {
                                      __typename?: 'PostTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      body?: string | null;
                                  };
                              }>;
                          };
                          files: {
                              __typename?: 'FileInfoConnection';
                              edges: Array<{
                                  __typename?: 'FileInfoEdge';
                                  node: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      name: string;
                                      url: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              }>;
                          };
                          comments: {
                              __typename?: 'PostCommentConnection';
                              totalCount: number;
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                              pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                          };
                          topComments: {
                              __typename?: 'PostCommentConnection';
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      userId: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          email: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                          };
                          author: {
                              __typename?: 'User';
                              id: string;
                              fullName: string;
                              statusText?: string | null;
                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                              role: { __typename?: 'Role'; id: string; name: string };
                              member?: { __typename?: 'Member'; id: string } | null;
                          };
                          meta:
                              | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                              | { __typename?: 'DefaultPostMeta'; type: PostType }
                              | {
                                    __typename?: 'EventMeta';
                                    eventId: string;
                                    event: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        type: EventType;
                                        pinned: boolean;
                                        price?: number | null;
                                        isExternal: boolean;
                                        externalLink?: string | null;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        purchased: boolean;
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | {
                                    __typename?: 'ExperienceMeta';
                                    experienceId: string;
                                    type: PostType;
                                    experience: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        price?: number | null;
                                        isExternal: boolean;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        externalLink?: string | null;
                                        purchased: boolean;
                                        pinned: boolean;
                                        type: EventType;
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                              | { __typename?: 'RepostMeta' };
                      };
                  };
            translations: {
                __typename?: 'PostTranslationConnection';
                edges: Array<{
                    __typename?: 'PostTranslationEdge';
                    node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                }>;
            };
            files: {
                __typename?: 'FileInfoConnection';
                edges: Array<{
                    __typename?: 'FileInfoEdge';
                    node: {
                        __typename?: 'FileInfo';
                        id: string;
                        name: string;
                        url: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                }>;
            };
            comments: {
                __typename?: 'PostCommentConnection';
                totalCount: number;
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
                pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
            };
            topComments: {
                __typename?: 'PostCommentConnection';
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        userId: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
            };
            author: {
                __typename?: 'User';
                id: string;
                fullName: string;
                statusText?: string | null;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                role: { __typename?: 'Role'; id: string; name: string };
                member?: { __typename?: 'Member'; id: string } | null;
            };
        };
    };
};

export type UpdatePostMutationVariables = Exact<{
    post: UpdatePostInput;
}>;

export type UpdatePostMutation = {
    __typename?: 'Mutation';
    updatePost: {
        __typename?: 'UpdatePostPayload';
        post: {
            __typename?: 'Post';
            id: string;
            communityId: string;
            repostCount: number;
            likeCount: number;
            isLiked: boolean;
            isBookmarked?: boolean | null;
            visibility: PostVisibility;
            type: PostType;
            createdAt: any;
            authorId: string;
            meta:
                | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                | { __typename?: 'DefaultPostMeta'; type: PostType }
                | {
                      __typename?: 'EventMeta';
                      eventId: string;
                      event: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          type: EventType;
                          pinned: boolean;
                          price?: number | null;
                          isExternal: boolean;
                          externalLink?: string | null;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          purchased: boolean;
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | {
                      __typename?: 'ExperienceMeta';
                      experienceId: string;
                      type: PostType;
                      experience: {
                          __typename?: 'Event';
                          id: string;
                          createdById: string;
                          communityId: string;
                          startTime?: any | null;
                          tags?: Array<string> | null;
                          price?: number | null;
                          isExternal: boolean;
                          isDraft?: boolean | null;
                          isBookmarked?: boolean | null;
                          externalLink?: string | null;
                          purchased: boolean;
                          pinned: boolean;
                          type: EventType;
                          community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                          translations: {
                              __typename?: 'EventTranslationConnection';
                              edges: Array<{
                                  __typename?: 'EventTranslationEdge';
                                  node: {
                                      __typename?: 'EventTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      title: string;
                                      description: string;
                                      address?: string | null;
                                  };
                              }>;
                          };
                          location?: { __typename?: 'Point'; lat: number; long: number } | null;
                          images: {
                              __typename?: 'EventImageConnection';
                              edges: Array<{
                                  __typename?: 'EventImageEdge';
                                  node: {
                                      __typename?: 'EventImage';
                                      id: string;
                                      order: number;
                                      image: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          url: string;
                                          name: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  };
                              }>;
                          };
                      };
                  }
                | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                | {
                      __typename?: 'RepostMeta';
                      post: {
                          __typename?: 'Post';
                          id: string;
                          communityId: string;
                          repostCount: number;
                          likeCount: number;
                          isLiked: boolean;
                          isBookmarked?: boolean | null;
                          visibility: PostVisibility;
                          type: PostType;
                          createdAt: any;
                          authorId: string;
                          translations: {
                              __typename?: 'PostTranslationConnection';
                              edges: Array<{
                                  __typename?: 'PostTranslationEdge';
                                  node: {
                                      __typename?: 'PostTranslation';
                                      id: string;
                                      lang: LanguageCode;
                                      body?: string | null;
                                  };
                              }>;
                          };
                          files: {
                              __typename?: 'FileInfoConnection';
                              edges: Array<{
                                  __typename?: 'FileInfoEdge';
                                  node: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      name: string;
                                      url: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              }>;
                          };
                          comments: {
                              __typename?: 'PostCommentConnection';
                              totalCount: number;
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                              pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                          };
                          topComments: {
                              __typename?: 'PostCommentConnection';
                              edges: Array<{
                                  __typename?: 'PostCommentEdge';
                                  node: {
                                      __typename?: 'PostComment';
                                      id: string;
                                      text: string;
                                      userId: string;
                                      createdAt: any;
                                      author: {
                                          __typename?: 'User';
                                          id: string;
                                          fullName: string;
                                          email: string;
                                          statusText?: string | null;
                                          role: { __typename?: 'Role'; id: string; name: string };
                                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                          member?: { __typename?: 'Member'; id: string } | null;
                                      };
                                  };
                              }>;
                          };
                          author: {
                              __typename?: 'User';
                              id: string;
                              fullName: string;
                              statusText?: string | null;
                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                              role: { __typename?: 'Role'; id: string; name: string };
                              member?: { __typename?: 'Member'; id: string } | null;
                          };
                          meta:
                              | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                              | { __typename?: 'DefaultPostMeta'; type: PostType }
                              | {
                                    __typename?: 'EventMeta';
                                    eventId: string;
                                    event: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        type: EventType;
                                        pinned: boolean;
                                        price?: number | null;
                                        isExternal: boolean;
                                        externalLink?: string | null;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        purchased: boolean;
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | {
                                    __typename?: 'ExperienceMeta';
                                    experienceId: string;
                                    type: PostType;
                                    experience: {
                                        __typename?: 'Event';
                                        id: string;
                                        createdById: string;
                                        communityId: string;
                                        startTime?: any | null;
                                        tags?: Array<string> | null;
                                        price?: number | null;
                                        isExternal: boolean;
                                        isDraft?: boolean | null;
                                        isBookmarked?: boolean | null;
                                        externalLink?: string | null;
                                        purchased: boolean;
                                        pinned: boolean;
                                        type: EventType;
                                        community: {
                                            __typename?: 'Community';
                                            city: { __typename?: 'City'; name: string };
                                        };
                                        translations: {
                                            __typename?: 'EventTranslationConnection';
                                            edges: Array<{
                                                __typename?: 'EventTranslationEdge';
                                                node: {
                                                    __typename?: 'EventTranslation';
                                                    id: string;
                                                    lang: LanguageCode;
                                                    title: string;
                                                    description: string;
                                                    address?: string | null;
                                                };
                                            }>;
                                        };
                                        location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                        images: {
                                            __typename?: 'EventImageConnection';
                                            edges: Array<{
                                                __typename?: 'EventImageEdge';
                                                node: {
                                                    __typename?: 'EventImage';
                                                    id: string;
                                                    order: number;
                                                    image: {
                                                        __typename?: 'FileInfo';
                                                        id: string;
                                                        url: string;
                                                        name: string;
                                                        extension: string;
                                                        blurhash?: {
                                                            __typename?: 'BlurhashMeta';
                                                            hash: string;
                                                            width: number;
                                                            height: number;
                                                        } | null;
                                                    };
                                                };
                                            }>;
                                        };
                                    };
                                }
                              | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                              | { __typename?: 'RepostMeta' };
                      };
                  };
            translations: {
                __typename?: 'PostTranslationConnection';
                edges: Array<{
                    __typename?: 'PostTranslationEdge';
                    node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                }>;
            };
            files: {
                __typename?: 'FileInfoConnection';
                edges: Array<{
                    __typename?: 'FileInfoEdge';
                    node: {
                        __typename?: 'FileInfo';
                        id: string;
                        name: string;
                        url: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                }>;
            };
            comments: {
                __typename?: 'PostCommentConnection';
                totalCount: number;
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
                pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
            };
            topComments: {
                __typename?: 'PostCommentConnection';
                edges: Array<{
                    __typename?: 'PostCommentEdge';
                    node: {
                        __typename?: 'PostComment';
                        id: string;
                        text: string;
                        userId: string;
                        createdAt: any;
                        author: {
                            __typename?: 'User';
                            id: string;
                            fullName: string;
                            email: string;
                            statusText?: string | null;
                            role: { __typename?: 'Role'; id: string; name: string };
                            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                            member?: { __typename?: 'Member'; id: string } | null;
                        };
                    };
                }>;
            };
            author: {
                __typename?: 'User';
                id: string;
                fullName: string;
                statusText?: string | null;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                role: { __typename?: 'Role'; id: string; name: string };
                member?: { __typename?: 'Member'; id: string } | null;
            };
        };
    };
};

export type UploadUserAvatarMutationVariables = Exact<{
    input: UploadUserAvatarInput;
}>;

export type UploadUserAvatarMutation = {
    __typename?: 'Mutation';
    uploadUserAvatar: {
        __typename?: 'UploadUserAvatarPayload';
        user: {
            __typename?: 'User';
            id: string;
            fullName: string;
            postCount?: number | null;
            bio?: string | null;
            followStatus: FollowStatus;
            statusText?: string | null;
            email: string;
            isChatable: boolean;
            role: { __typename?: 'Role'; id: string; name: string };
            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            coverPhoto?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            member?: { __typename?: 'Member'; id: string } | null;
            matrixAuth?: { __typename?: 'MatrixAuthData'; user_id: string } | null;
        };
    };
};

export type UploadUserCoverPhotoMutationVariables = Exact<{
    input: UploadUserCoverPhotoInput;
}>;

export type UploadUserCoverPhotoMutation = {
    __typename?: 'Mutation';
    uploadUserCoverPhoto: {
        __typename?: 'UploadUserCoverPhotoPayload';
        user: {
            __typename?: 'User';
            id: string;
            fullName: string;
            postCount?: number | null;
            bio?: string | null;
            followStatus: FollowStatus;
            statusText?: string | null;
            email: string;
            isChatable: boolean;
            role: { __typename?: 'Role'; id: string; name: string };
            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            coverPhoto?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            member?: { __typename?: 'Member'; id: string } | null;
            matrixAuth?: { __typename?: 'MatrixAuthData'; user_id: string } | null;
        };
    };
};

export type ActivityCountQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityCountQuery = { __typename?: 'Query'; activityCount: number };

export type ChatableUsersQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ChatableUsersWhereInput>;
}>;

export type ChatableUsersQuery = {
    __typename?: 'Query';
    chatableUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                matrixAuth?: {
                    __typename?: 'MatrixAuthData';
                    access_token: string;
                    device_id: string;
                    home_server: string;
                    user_id: string;
                } | null;
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type CitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesQuery = {
    __typename?: 'Query';
    cities: {
        __typename?: 'CityConnection';
        edges: Array<{ __typename?: 'CityEdge'; node: { __typename?: 'City'; id: string; name: string } }>;
    };
};

export type CommunitiesQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommunityWhereInput>;
}>;

export type CommunitiesQuery = {
    __typename?: 'Query';
    communities: {
        __typename?: 'CommunityConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'CommunityEdge';
            node: {
                __typename?: 'Community';
                id: string;
                category: CommunityCategory;
                joinRule?: JoinRule | null;
                translations: {
                    __typename?: 'CommunityTranslationConnection';
                    edges: Array<{
                        __typename?: 'CommunityTranslationEdge';
                        node: {
                            __typename?: 'CommunityTranslation';
                            id: string;
                            lang: LanguageCode;
                            name: string;
                            location: string;
                            description?: string | null;
                            communityId: string;
                        };
                    }>;
                };
                images: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: { __typename?: 'FileInfo'; id: string; url: string; extension: string };
                    }>;
                };
                logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            };
        }>;
    };
};

export type CommunityInvitationQueryVariables = Exact<{
    communityInvitationId: Scalars['String'];
}>;

export type CommunityInvitationQuery = {
    __typename?: 'Query';
    communityInvitation: {
        __typename?: 'CommunityInvitation';
        id: string;
        usageLimit: number;
        usedCount: number;
        expiresAt: any;
        community: {
            __typename?: 'Community';
            id: string;
            translations: {
                __typename?: 'CommunityTranslationConnection';
                edges: Array<{
                    __typename?: 'CommunityTranslationEdge';
                    node: { __typename?: 'CommunityTranslation'; id: string; lang: LanguageCode; name: string };
                }>;
            };
        };
    };
};

export type CommunityMembersQueryVariables = Exact<{
    where: CommunityMembersWhereInput;
    page?: InputMaybe<Scalars['Int']>;
}>;

export type CommunityMembersQuery = {
    __typename?: 'Query';
    communityMembers: {
        __typename?: 'MemberConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'MemberEdge';
            node: {
                __typename?: 'Member';
                id: string;
                status: MemberStatus;
                createdAt: any;
                user: {
                    __typename?: 'User';
                    id: string;
                    firstName: string;
                    lastName: string;
                    bio?: string | null;
                    email: string;
                    phoneNumber?: string | null;
                    dateOfBirth?: any | null;
                    fullName: string;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                };
            };
        }>;
    };
};

export type CommunityPostsWithImagesQueryVariables = Exact<{
    where: PostWhereInput;
    page?: InputMaybe<Scalars['Int']>;
}>;

export type CommunityPostsWithImagesQuery = {
    __typename?: 'Query';
    communityPostsWithImages: {
        __typename?: 'PostConnection';
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                files: {
                    __typename?: 'FileInfoConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            url: string;
                            extension: string;
                            blurhash?: { __typename?: 'BlurhashMeta'; hash: string } | null;
                        };
                    }>;
                };
            };
        }>;
    };
};

export type CommunityQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type CommunityQuery = {
    __typename?: 'Query';
    community: {
        __typename?: 'Community';
        id: string;
        membershipStatus?: CommunityMembershipStatus | null;
        joinRule?: JoinRule | null;
        category: CommunityCategory;
        isInternal: boolean;
        memberCount: number;
        translations: {
            __typename?: 'CommunityTranslationConnection';
            edges: Array<{
                __typename?: 'CommunityTranslationEdge';
                node: {
                    __typename?: 'CommunityTranslation';
                    id: string;
                    lang: LanguageCode;
                    name: string;
                    description?: string | null;
                    location: string;
                };
            }>;
        };
        city: { __typename?: 'City'; id: string; name: string };
        benefits?: Array<{ __typename?: 'Benefit'; text: string; icon: string }> | null;
        managers: {
            __typename?: 'ManagerConnection';
            edges: Array<{
                __typename?: 'ManagerEdge';
                node: {
                    __typename?: 'Manager';
                    id: string;
                    userId: string;
                    user: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    };
                };
            }>;
        };
        invitations: {
            __typename?: 'CommunityInvitationConnection';
            edges: Array<{
                __typename?: 'CommunityInvitationEdge';
                node: {
                    __typename?: 'CommunityInvitation';
                    id: string;
                    usageLimit: number;
                    usedCount: number;
                    expiresAt: any;
                };
            }>;
        };
        logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        images: {
            __typename?: 'FileInfoConnection';
            edges: Array<{
                __typename?: 'FileInfoEdge';
                node: {
                    __typename?: 'FileInfo';
                    id: string;
                    url: string;
                    extension: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                };
            }>;
        };
    };
};

export type PartnersQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PartnerWhereInput>;
}>;

export type PartnersQuery = {
    __typename?: 'Query';
    partners: {
        __typename?: 'CommunityConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'CommunityEdge';
            node: {
                __typename?: 'Community';
                id: string;
                category: CommunityCategory;
                joinRule?: JoinRule | null;
                translations: {
                    __typename?: 'CommunityTranslationConnection';
                    edges: Array<{
                        __typename?: 'CommunityTranslationEdge';
                        node: {
                            __typename?: 'CommunityTranslation';
                            id: string;
                            lang: LanguageCode;
                            name: string;
                            location: string;
                            description?: string | null;
                            communityId: string;
                        };
                    }>;
                };
                images: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: { __typename?: 'FileInfo'; id: string; url: string; extension: string };
                    }>;
                };
                logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            };
        }>;
    };
};

export type PendingMembershipsQueryVariables = Exact<{
    where: PendingMembershipWhereInput;
    page?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type PendingMembershipsQuery = {
    __typename?: 'Query';
    pendingMemberships: {
        __typename?: 'CommunityMembershipConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean };
        edges: Array<{
            __typename?: 'CommunityMembershipEdge';
            node: {
                __typename?: 'CommunityMembership';
                id: string;
                member: {
                    __typename?: 'Member';
                    id: string;
                    user: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    };
                };
            };
        }>;
    };
};

export type UserCommunitiesQueryVariables = Exact<{ [key: string]: never }>;

export type UserCommunitiesQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'User';
        id: string;
        communities: {
            __typename?: 'CommunityConnection';
            edges: Array<{
                __typename?: 'CommunityEdge';
                node: {
                    __typename?: 'Community';
                    id: string;
                    memberCount: number;
                    pendingMembershipsCount: number;
                    translations: {
                        __typename?: 'CommunityTranslationConnection';
                        edges: Array<{
                            __typename?: 'CommunityTranslationEdge';
                            node: { __typename?: 'CommunityTranslation'; id: string; name: string; location: string };
                        }>;
                    };
                    city: { __typename?: 'City'; id: string; name: string };
                };
            }>;
        };
    } | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
    __typename?: 'Query';
    countries: {
        __typename?: 'CountryConnection';
        edges: Array<{
            __typename?: 'CountryEdge';
            node: { __typename?: 'Country'; id: string; code: string; name: string };
        }>;
    };
};

export type BookmarkedEventsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type BookmarkedEventsQuery = {
    __typename?: 'Query';
    bookmarkedEvents: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                pinned: boolean;
                price?: number | null;
                isExternal: boolean;
                externalLink?: string | null;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type BookmarkedExperiencesQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type BookmarkedExperiencesQuery = {
    __typename?: 'Query';
    bookmarkedExperiences: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                price?: number | null;
                isExternal: boolean;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                externalLink?: string | null;
                purchased: boolean;
                pinned: boolean;
                type: EventType;
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type EventQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type EventQuery = {
    __typename?: 'Query';
    event: {
        __typename?: 'Event';
        id: string;
        createdById: string;
        communityId: string;
        startTime?: any | null;
        tags?: Array<string> | null;
        type: EventType;
        pinned: boolean;
        price?: number | null;
        isExternal: boolean;
        externalLink?: string | null;
        isDraft?: boolean | null;
        isBookmarked?: boolean | null;
        purchased: boolean;
        translations: {
            __typename?: 'EventTranslationConnection';
            edges: Array<{
                __typename?: 'EventTranslationEdge';
                node: {
                    __typename?: 'EventTranslation';
                    id: string;
                    lang: LanguageCode;
                    title: string;
                    description: string;
                    address?: string | null;
                };
            }>;
        };
        community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
        location?: { __typename?: 'Point'; lat: number; long: number } | null;
        images: {
            __typename?: 'EventImageConnection';
            edges: Array<{
                __typename?: 'EventImageEdge';
                node: {
                    __typename?: 'EventImage';
                    id: string;
                    order: number;
                    image: {
                        __typename?: 'FileInfo';
                        id: string;
                        url: string;
                        name: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                };
            }>;
        };
    };
};

export type EventsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type EventsQuery = {
    __typename?: 'Query';
    events: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                pinned: boolean;
                price?: number | null;
                isExternal: boolean;
                externalLink?: string | null;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type ExperienceQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ExperienceQuery = {
    __typename?: 'Query';
    experience: {
        __typename?: 'Event';
        id: string;
        createdById: string;
        communityId: string;
        startTime?: any | null;
        tags?: Array<string> | null;
        price?: number | null;
        isExternal: boolean;
        isDraft?: boolean | null;
        isBookmarked?: boolean | null;
        externalLink?: string | null;
        purchased: boolean;
        pinned: boolean;
        type: EventType;
        community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
        translations: {
            __typename?: 'EventTranslationConnection';
            edges: Array<{
                __typename?: 'EventTranslationEdge';
                node: {
                    __typename?: 'EventTranslation';
                    id: string;
                    lang: LanguageCode;
                    title: string;
                    description: string;
                    address?: string | null;
                };
            }>;
        };
        location?: { __typename?: 'Point'; lat: number; long: number } | null;
        images: {
            __typename?: 'EventImageConnection';
            edges: Array<{
                __typename?: 'EventImageEdge';
                node: {
                    __typename?: 'EventImage';
                    id: string;
                    order: number;
                    image: {
                        __typename?: 'FileInfo';
                        id: string;
                        url: string;
                        name: string;
                        extension: string;
                        blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                    };
                };
            }>;
        };
    };
};

export type ExperiencesQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type ExperiencesQuery = {
    __typename?: 'Query';
    experiences: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                price?: number | null;
                isExternal: boolean;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                externalLink?: string | null;
                purchased: boolean;
                pinned: boolean;
                type: EventType;
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type PastEventsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type PastEventsQuery = {
    __typename?: 'Query';
    pastEvents: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                pinned: boolean;
                price?: number | null;
                isExternal: boolean;
                externalLink?: string | null;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type PlansQueryVariables = Exact<{ [key: string]: never }>;

export type PlansQuery = {
    __typename?: 'Query';
    plans: {
        __typename?: 'PlanConnection';
        edges: Array<{
            __typename?: 'PlanEdge';
            node: { __typename?: 'Plan'; id: string; code: string; price: number; benefits?: Array<string> | null };
        }>;
    };
};

export type PostForEventQueryVariables = Exact<{
    eventId: Scalars['String'];
    eventType: Scalars['String'];
}>;

export type PostForEventQuery = {
    __typename?: 'Query';
    postForEvent: {
        __typename?: 'Post';
        id: string;
        communityId: string;
        repostCount: number;
        likeCount: number;
        isLiked: boolean;
        isBookmarked?: boolean | null;
        visibility: PostVisibility;
        type: PostType;
        createdAt: any;
        authorId: string;
        meta:
            | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
            | { __typename?: 'DefaultPostMeta'; type: PostType }
            | {
                  __typename?: 'EventMeta';
                  eventId: string;
                  event: {
                      __typename?: 'Event';
                      id: string;
                      createdById: string;
                      communityId: string;
                      startTime?: any | null;
                      tags?: Array<string> | null;
                      type: EventType;
                      pinned: boolean;
                      price?: number | null;
                      isExternal: boolean;
                      externalLink?: string | null;
                      isDraft?: boolean | null;
                      isBookmarked?: boolean | null;
                      purchased: boolean;
                      translations: {
                          __typename?: 'EventTranslationConnection';
                          edges: Array<{
                              __typename?: 'EventTranslationEdge';
                              node: {
                                  __typename?: 'EventTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  title: string;
                                  description: string;
                                  address?: string | null;
                              };
                          }>;
                      };
                      community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                      location?: { __typename?: 'Point'; lat: number; long: number } | null;
                      images: {
                          __typename?: 'EventImageConnection';
                          edges: Array<{
                              __typename?: 'EventImageEdge';
                              node: {
                                  __typename?: 'EventImage';
                                  id: string;
                                  order: number;
                                  image: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      url: string;
                                      name: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              };
                          }>;
                      };
                  };
              }
            | {
                  __typename?: 'ExperienceMeta';
                  experienceId: string;
                  type: PostType;
                  experience: {
                      __typename?: 'Event';
                      id: string;
                      createdById: string;
                      communityId: string;
                      startTime?: any | null;
                      tags?: Array<string> | null;
                      price?: number | null;
                      isExternal: boolean;
                      isDraft?: boolean | null;
                      isBookmarked?: boolean | null;
                      externalLink?: string | null;
                      purchased: boolean;
                      pinned: boolean;
                      type: EventType;
                      community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                      translations: {
                          __typename?: 'EventTranslationConnection';
                          edges: Array<{
                              __typename?: 'EventTranslationEdge';
                              node: {
                                  __typename?: 'EventTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  title: string;
                                  description: string;
                                  address?: string | null;
                              };
                          }>;
                      };
                      location?: { __typename?: 'Point'; lat: number; long: number } | null;
                      images: {
                          __typename?: 'EventImageConnection';
                          edges: Array<{
                              __typename?: 'EventImageEdge';
                              node: {
                                  __typename?: 'EventImage';
                                  id: string;
                                  order: number;
                                  image: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      url: string;
                                      name: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              };
                          }>;
                      };
                  };
              }
            | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
            | {
                  __typename?: 'RepostMeta';
                  post: {
                      __typename?: 'Post';
                      id: string;
                      communityId: string;
                      repostCount: number;
                      likeCount: number;
                      isLiked: boolean;
                      isBookmarked?: boolean | null;
                      visibility: PostVisibility;
                      type: PostType;
                      createdAt: any;
                      authorId: string;
                      translations: {
                          __typename?: 'PostTranslationConnection';
                          edges: Array<{
                              __typename?: 'PostTranslationEdge';
                              node: {
                                  __typename?: 'PostTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  body?: string | null;
                              };
                          }>;
                      };
                      files: {
                          __typename?: 'FileInfoConnection';
                          edges: Array<{
                              __typename?: 'FileInfoEdge';
                              node: {
                                  __typename?: 'FileInfo';
                                  id: string;
                                  name: string;
                                  url: string;
                                  extension: string;
                                  blurhash?: {
                                      __typename?: 'BlurhashMeta';
                                      hash: string;
                                      width: number;
                                      height: number;
                                  } | null;
                              };
                          }>;
                      };
                      comments: {
                          __typename?: 'PostCommentConnection';
                          totalCount: number;
                          edges: Array<{
                              __typename?: 'PostCommentEdge';
                              node: {
                                  __typename?: 'PostComment';
                                  id: string;
                                  text: string;
                                  createdAt: any;
                                  author: {
                                      __typename?: 'User';
                                      id: string;
                                      fullName: string;
                                      statusText?: string | null;
                                      role: { __typename?: 'Role'; id: string; name: string };
                                      avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                      member?: { __typename?: 'Member'; id: string } | null;
                                  };
                              };
                          }>;
                          pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                      };
                      topComments: {
                          __typename?: 'PostCommentConnection';
                          edges: Array<{
                              __typename?: 'PostCommentEdge';
                              node: {
                                  __typename?: 'PostComment';
                                  id: string;
                                  text: string;
                                  userId: string;
                                  createdAt: any;
                                  author: {
                                      __typename?: 'User';
                                      id: string;
                                      fullName: string;
                                      email: string;
                                      statusText?: string | null;
                                      role: { __typename?: 'Role'; id: string; name: string };
                                      avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                      member?: { __typename?: 'Member'; id: string } | null;
                                  };
                              };
                          }>;
                      };
                      author: {
                          __typename?: 'User';
                          id: string;
                          fullName: string;
                          statusText?: string | null;
                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                          role: { __typename?: 'Role'; id: string; name: string };
                          member?: { __typename?: 'Member'; id: string } | null;
                      };
                      meta:
                          | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                          | { __typename?: 'DefaultPostMeta'; type: PostType }
                          | {
                                __typename?: 'EventMeta';
                                eventId: string;
                                event: {
                                    __typename?: 'Event';
                                    id: string;
                                    createdById: string;
                                    communityId: string;
                                    startTime?: any | null;
                                    tags?: Array<string> | null;
                                    type: EventType;
                                    pinned: boolean;
                                    price?: number | null;
                                    isExternal: boolean;
                                    externalLink?: string | null;
                                    isDraft?: boolean | null;
                                    isBookmarked?: boolean | null;
                                    purchased: boolean;
                                    translations: {
                                        __typename?: 'EventTranslationConnection';
                                        edges: Array<{
                                            __typename?: 'EventTranslationEdge';
                                            node: {
                                                __typename?: 'EventTranslation';
                                                id: string;
                                                lang: LanguageCode;
                                                title: string;
                                                description: string;
                                                address?: string | null;
                                            };
                                        }>;
                                    };
                                    community: {
                                        __typename?: 'Community';
                                        city: { __typename?: 'City'; name: string };
                                    };
                                    location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                    images: {
                                        __typename?: 'EventImageConnection';
                                        edges: Array<{
                                            __typename?: 'EventImageEdge';
                                            node: {
                                                __typename?: 'EventImage';
                                                id: string;
                                                order: number;
                                                image: {
                                                    __typename?: 'FileInfo';
                                                    id: string;
                                                    url: string;
                                                    name: string;
                                                    extension: string;
                                                    blurhash?: {
                                                        __typename?: 'BlurhashMeta';
                                                        hash: string;
                                                        width: number;
                                                        height: number;
                                                    } | null;
                                                };
                                            };
                                        }>;
                                    };
                                };
                            }
                          | {
                                __typename?: 'ExperienceMeta';
                                experienceId: string;
                                type: PostType;
                                experience: {
                                    __typename?: 'Event';
                                    id: string;
                                    createdById: string;
                                    communityId: string;
                                    startTime?: any | null;
                                    tags?: Array<string> | null;
                                    price?: number | null;
                                    isExternal: boolean;
                                    isDraft?: boolean | null;
                                    isBookmarked?: boolean | null;
                                    externalLink?: string | null;
                                    purchased: boolean;
                                    pinned: boolean;
                                    type: EventType;
                                    community: {
                                        __typename?: 'Community';
                                        city: { __typename?: 'City'; name: string };
                                    };
                                    translations: {
                                        __typename?: 'EventTranslationConnection';
                                        edges: Array<{
                                            __typename?: 'EventTranslationEdge';
                                            node: {
                                                __typename?: 'EventTranslation';
                                                id: string;
                                                lang: LanguageCode;
                                                title: string;
                                                description: string;
                                                address?: string | null;
                                            };
                                        }>;
                                    };
                                    location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                    images: {
                                        __typename?: 'EventImageConnection';
                                        edges: Array<{
                                            __typename?: 'EventImageEdge';
                                            node: {
                                                __typename?: 'EventImage';
                                                id: string;
                                                order: number;
                                                image: {
                                                    __typename?: 'FileInfo';
                                                    id: string;
                                                    url: string;
                                                    name: string;
                                                    extension: string;
                                                    blurhash?: {
                                                        __typename?: 'BlurhashMeta';
                                                        hash: string;
                                                        width: number;
                                                        height: number;
                                                    } | null;
                                                };
                                            };
                                        }>;
                                    };
                                };
                            }
                          | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                          | { __typename?: 'RepostMeta' };
                  };
              };
        translations: {
            __typename?: 'PostTranslationConnection';
            edges: Array<{
                __typename?: 'PostTranslationEdge';
                node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
            }>;
        };
        files: {
            __typename?: 'FileInfoConnection';
            edges: Array<{
                __typename?: 'FileInfoEdge';
                node: {
                    __typename?: 'FileInfo';
                    id: string;
                    name: string;
                    url: string;
                    extension: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                };
            }>;
        };
        comments: {
            __typename?: 'PostCommentConnection';
            totalCount: number;
            edges: Array<{
                __typename?: 'PostCommentEdge';
                node: {
                    __typename?: 'PostComment';
                    id: string;
                    text: string;
                    createdAt: any;
                    author: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        statusText?: string | null;
                        role: { __typename?: 'Role'; id: string; name: string };
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                        member?: { __typename?: 'Member'; id: string } | null;
                    };
                };
            }>;
            pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        };
        topComments: {
            __typename?: 'PostCommentConnection';
            edges: Array<{
                __typename?: 'PostCommentEdge';
                node: {
                    __typename?: 'PostComment';
                    id: string;
                    text: string;
                    userId: string;
                    createdAt: any;
                    author: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        email: string;
                        statusText?: string | null;
                        role: { __typename?: 'Role'; id: string; name: string };
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                        member?: { __typename?: 'Member'; id: string } | null;
                    };
                };
            }>;
        };
        author: {
            __typename?: 'User';
            id: string;
            fullName: string;
            statusText?: string | null;
            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            role: { __typename?: 'Role'; id: string; name: string };
            member?: { __typename?: 'Member'; id: string } | null;
        };
    };
};

export type UpcomingEventsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<EventWhereInput>;
}>;

export type UpcomingEventsQuery = {
    __typename?: 'Query';
    upcomingEvents: {
        __typename?: 'EventConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                createdById: string;
                communityId: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                pinned: boolean;
                price?: number | null;
                isExternal: boolean;
                externalLink?: string | null;
                isDraft?: boolean | null;
                isBookmarked?: boolean | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                location?: { __typename?: 'Point'; lat: number; long: number } | null;
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
};

export type MeManagerQueryVariables = Exact<{ [key: string]: never }>;

export type MeManagerQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        bio?: string | null;
        phoneNumber?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        incognito?: boolean | null;
        nickname?: string | null;
        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
    } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        fullName: string;
        statusText?: string | null;
        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        role: { __typename?: 'Role'; id: string; name: string };
        matrixAuth?: {
            __typename?: 'MatrixAuthData';
            access_token: string;
            device_id: string;
            home_server: string;
            user_id: string;
        } | null;
        member?: { __typename?: 'Member'; id: string; status: MemberStatus } | null;
    } | null;
};

export type MeMemberQueryVariables = Exact<{ [key: string]: never }>;

export type MeMemberQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'User';
        id: string;
        incognito?: boolean | null;
        nickname?: string | null;
        subscription?: {
            __typename?: 'SubscriptionNode';
            id: string;
            expiresAt: any;
            plan?: { __typename?: 'Plan'; id: string; code: string; benefits?: Array<string> | null } | null;
        } | null;
        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        member?: {
            __typename?: 'Member';
            id: string;
            maritalStatus?: MaritalStatus | null;
            cityOfResidence: string;
            countryOfResidenceId: string;
            countryOfResidence: { __typename?: 'Country'; id: string; name: string };
            user: {
                __typename?: 'User';
                id: string;
                firstName: string;
                lastName: string;
                bio?: string | null;
                gender?: Gender | null;
                email: string;
                phoneNumber?: string | null;
                dateOfBirth?: any | null;
                incognito?: boolean | null;
                nickname?: string | null;
            };
            profiles: {
                __typename?: 'MemberProfileConnection';
                edges: Array<{
                    __typename?: 'MemberProfileEdge';
                    node: {
                        __typename?: 'MemberProfile';
                        id: string;
                        firstName: string;
                        lastName: string;
                        email: string;
                        bio?: string | null;
                        phoneNumber: string;
                        dateOfBirth: any;
                        cityOfResidence: string;
                        countryOfResidenceId: string;
                        maritalStatus?: MaritalStatus | null;
                        countryOfResidence: { __typename?: 'Country'; id: string; name: string };
                    };
                }>;
            };
        } | null;
    } | null;
};

export type MemberQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type MemberQuery = {
    __typename?: 'Query';
    member?: {
        __typename?: 'Member';
        id: string;
        status: MemberStatus;
        maritalStatus?: MaritalStatus | null;
        cityOfResidence: string;
        countryOfResidence: { __typename?: 'Country'; id: string; name: string };
        user: {
            __typename?: 'User';
            id: string;
            firstName: string;
            lastName: string;
            bio?: string | null;
            email: string;
            phoneNumber?: string | null;
            dateOfBirth?: any | null;
        };
        profiles: {
            __typename?: 'MemberProfileConnection';
            edges: Array<{
                __typename?: 'MemberProfileEdge';
                node: {
                    __typename?: 'MemberProfile';
                    id: string;
                    firstName: string;
                    lastName: string;
                    email: string;
                    bio?: string | null;
                    phoneNumber: string;
                    dateOfBirth: any;
                    cityOfResidence: string;
                    countryOfResidenceId: string;
                    maritalStatus?: MaritalStatus | null;
                    countryOfResidence: { __typename?: 'Country'; id: string; name: string };
                };
            }>;
        };
    } | null;
};

export type MembersQueryVariables = Exact<{
    where: MemberWhereInput;
}>;

export type MembersQuery = {
    __typename?: 'Query';
    members?: {
        __typename?: 'MemberConnection';
        edges: Array<{
            __typename?: 'MemberEdge';
            node: {
                __typename?: 'Member';
                id: string;
                status: MemberStatus;
                createdAt: any;
                user: {
                    __typename?: 'User';
                    id: string;
                    firstName: string;
                    lastName: string;
                    bio?: string | null;
                    email: string;
                    phoneNumber?: string | null;
                    dateOfBirth?: any | null;
                };
            };
        }>;
    } | null;
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = {
    __typename?: 'Query';
    paymentMethods: {
        __typename?: 'PaymentMethodConnection';
        edges: Array<{
            __typename?: 'PaymentMethodEdge';
            node: {
                __typename?: 'PaymentMethod';
                id: string;
                isDefault: boolean;
                lastFour: string;
                funding: string;
                expYear: number;
                expMonth: number;
                brand: string;
                cartToken: string;
            };
        }>;
    };
};

export type PostDetailQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type PostDetailQuery = {
    __typename?: 'Query';
    post: {
        __typename?: 'Post';
        id: string;
        communityId: string;
        repostCount: number;
        likeCount: number;
        isLiked: boolean;
        isBookmarked?: boolean | null;
        visibility: PostVisibility;
        type: PostType;
        createdAt: any;
        authorId: string;
        meta:
            | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
            | { __typename?: 'DefaultPostMeta'; type: PostType }
            | {
                  __typename?: 'EventMeta';
                  eventId: string;
                  event: {
                      __typename?: 'Event';
                      id: string;
                      createdById: string;
                      communityId: string;
                      startTime?: any | null;
                      tags?: Array<string> | null;
                      type: EventType;
                      pinned: boolean;
                      price?: number | null;
                      isExternal: boolean;
                      externalLink?: string | null;
                      isDraft?: boolean | null;
                      isBookmarked?: boolean | null;
                      purchased: boolean;
                      translations: {
                          __typename?: 'EventTranslationConnection';
                          edges: Array<{
                              __typename?: 'EventTranslationEdge';
                              node: {
                                  __typename?: 'EventTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  title: string;
                                  description: string;
                                  address?: string | null;
                              };
                          }>;
                      };
                      community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                      location?: { __typename?: 'Point'; lat: number; long: number } | null;
                      images: {
                          __typename?: 'EventImageConnection';
                          edges: Array<{
                              __typename?: 'EventImageEdge';
                              node: {
                                  __typename?: 'EventImage';
                                  id: string;
                                  order: number;
                                  image: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      url: string;
                                      name: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              };
                          }>;
                      };
                  };
              }
            | {
                  __typename?: 'ExperienceMeta';
                  experienceId: string;
                  type: PostType;
                  experience: {
                      __typename?: 'Event';
                      id: string;
                      createdById: string;
                      communityId: string;
                      startTime?: any | null;
                      tags?: Array<string> | null;
                      price?: number | null;
                      isExternal: boolean;
                      isDraft?: boolean | null;
                      isBookmarked?: boolean | null;
                      externalLink?: string | null;
                      purchased: boolean;
                      pinned: boolean;
                      type: EventType;
                      community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                      translations: {
                          __typename?: 'EventTranslationConnection';
                          edges: Array<{
                              __typename?: 'EventTranslationEdge';
                              node: {
                                  __typename?: 'EventTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  title: string;
                                  description: string;
                                  address?: string | null;
                              };
                          }>;
                      };
                      location?: { __typename?: 'Point'; lat: number; long: number } | null;
                      images: {
                          __typename?: 'EventImageConnection';
                          edges: Array<{
                              __typename?: 'EventImageEdge';
                              node: {
                                  __typename?: 'EventImage';
                                  id: string;
                                  order: number;
                                  image: {
                                      __typename?: 'FileInfo';
                                      id: string;
                                      url: string;
                                      name: string;
                                      extension: string;
                                      blurhash?: {
                                          __typename?: 'BlurhashMeta';
                                          hash: string;
                                          width: number;
                                          height: number;
                                      } | null;
                                  };
                              };
                          }>;
                      };
                  };
              }
            | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
            | {
                  __typename?: 'RepostMeta';
                  post: {
                      __typename?: 'Post';
                      id: string;
                      communityId: string;
                      repostCount: number;
                      likeCount: number;
                      isLiked: boolean;
                      isBookmarked?: boolean | null;
                      visibility: PostVisibility;
                      type: PostType;
                      createdAt: any;
                      authorId: string;
                      translations: {
                          __typename?: 'PostTranslationConnection';
                          edges: Array<{
                              __typename?: 'PostTranslationEdge';
                              node: {
                                  __typename?: 'PostTranslation';
                                  id: string;
                                  lang: LanguageCode;
                                  body?: string | null;
                              };
                          }>;
                      };
                      files: {
                          __typename?: 'FileInfoConnection';
                          edges: Array<{
                              __typename?: 'FileInfoEdge';
                              node: {
                                  __typename?: 'FileInfo';
                                  id: string;
                                  name: string;
                                  url: string;
                                  extension: string;
                                  blurhash?: {
                                      __typename?: 'BlurhashMeta';
                                      hash: string;
                                      width: number;
                                      height: number;
                                  } | null;
                              };
                          }>;
                      };
                      comments: {
                          __typename?: 'PostCommentConnection';
                          totalCount: number;
                          edges: Array<{
                              __typename?: 'PostCommentEdge';
                              node: {
                                  __typename?: 'PostComment';
                                  id: string;
                                  text: string;
                                  createdAt: any;
                                  author: {
                                      __typename?: 'User';
                                      id: string;
                                      fullName: string;
                                      statusText?: string | null;
                                      role: { __typename?: 'Role'; id: string; name: string };
                                      avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                      member?: { __typename?: 'Member'; id: string } | null;
                                  };
                              };
                          }>;
                          pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                      };
                      topComments: {
                          __typename?: 'PostCommentConnection';
                          edges: Array<{
                              __typename?: 'PostCommentEdge';
                              node: {
                                  __typename?: 'PostComment';
                                  id: string;
                                  text: string;
                                  userId: string;
                                  createdAt: any;
                                  author: {
                                      __typename?: 'User';
                                      id: string;
                                      fullName: string;
                                      email: string;
                                      statusText?: string | null;
                                      role: { __typename?: 'Role'; id: string; name: string };
                                      avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                      member?: { __typename?: 'Member'; id: string } | null;
                                  };
                              };
                          }>;
                      };
                      author: {
                          __typename?: 'User';
                          id: string;
                          fullName: string;
                          statusText?: string | null;
                          avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                          role: { __typename?: 'Role'; id: string; name: string };
                          member?: { __typename?: 'Member'; id: string } | null;
                      };
                      meta:
                          | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                          | { __typename?: 'DefaultPostMeta'; type: PostType }
                          | {
                                __typename?: 'EventMeta';
                                eventId: string;
                                event: {
                                    __typename?: 'Event';
                                    id: string;
                                    createdById: string;
                                    communityId: string;
                                    startTime?: any | null;
                                    tags?: Array<string> | null;
                                    type: EventType;
                                    pinned: boolean;
                                    price?: number | null;
                                    isExternal: boolean;
                                    externalLink?: string | null;
                                    isDraft?: boolean | null;
                                    isBookmarked?: boolean | null;
                                    purchased: boolean;
                                    translations: {
                                        __typename?: 'EventTranslationConnection';
                                        edges: Array<{
                                            __typename?: 'EventTranslationEdge';
                                            node: {
                                                __typename?: 'EventTranslation';
                                                id: string;
                                                lang: LanguageCode;
                                                title: string;
                                                description: string;
                                                address?: string | null;
                                            };
                                        }>;
                                    };
                                    community: {
                                        __typename?: 'Community';
                                        city: { __typename?: 'City'; name: string };
                                    };
                                    location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                    images: {
                                        __typename?: 'EventImageConnection';
                                        edges: Array<{
                                            __typename?: 'EventImageEdge';
                                            node: {
                                                __typename?: 'EventImage';
                                                id: string;
                                                order: number;
                                                image: {
                                                    __typename?: 'FileInfo';
                                                    id: string;
                                                    url: string;
                                                    name: string;
                                                    extension: string;
                                                    blurhash?: {
                                                        __typename?: 'BlurhashMeta';
                                                        hash: string;
                                                        width: number;
                                                        height: number;
                                                    } | null;
                                                };
                                            };
                                        }>;
                                    };
                                };
                            }
                          | {
                                __typename?: 'ExperienceMeta';
                                experienceId: string;
                                type: PostType;
                                experience: {
                                    __typename?: 'Event';
                                    id: string;
                                    createdById: string;
                                    communityId: string;
                                    startTime?: any | null;
                                    tags?: Array<string> | null;
                                    price?: number | null;
                                    isExternal: boolean;
                                    isDraft?: boolean | null;
                                    isBookmarked?: boolean | null;
                                    externalLink?: string | null;
                                    purchased: boolean;
                                    pinned: boolean;
                                    type: EventType;
                                    community: {
                                        __typename?: 'Community';
                                        city: { __typename?: 'City'; name: string };
                                    };
                                    translations: {
                                        __typename?: 'EventTranslationConnection';
                                        edges: Array<{
                                            __typename?: 'EventTranslationEdge';
                                            node: {
                                                __typename?: 'EventTranslation';
                                                id: string;
                                                lang: LanguageCode;
                                                title: string;
                                                description: string;
                                                address?: string | null;
                                            };
                                        }>;
                                    };
                                    location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                    images: {
                                        __typename?: 'EventImageConnection';
                                        edges: Array<{
                                            __typename?: 'EventImageEdge';
                                            node: {
                                                __typename?: 'EventImage';
                                                id: string;
                                                order: number;
                                                image: {
                                                    __typename?: 'FileInfo';
                                                    id: string;
                                                    url: string;
                                                    name: string;
                                                    extension: string;
                                                    blurhash?: {
                                                        __typename?: 'BlurhashMeta';
                                                        hash: string;
                                                        width: number;
                                                        height: number;
                                                    } | null;
                                                };
                                            };
                                        }>;
                                    };
                                };
                            }
                          | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                          | { __typename?: 'RepostMeta' };
                  };
              };
        translations: {
            __typename?: 'PostTranslationConnection';
            edges: Array<{
                __typename?: 'PostTranslationEdge';
                node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
            }>;
        };
        files: {
            __typename?: 'FileInfoConnection';
            edges: Array<{
                __typename?: 'FileInfoEdge';
                node: {
                    __typename?: 'FileInfo';
                    id: string;
                    name: string;
                    url: string;
                    extension: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string; width: number; height: number } | null;
                };
            }>;
        };
        comments: {
            __typename?: 'PostCommentConnection';
            totalCount: number;
            edges: Array<{
                __typename?: 'PostCommentEdge';
                node: {
                    __typename?: 'PostComment';
                    id: string;
                    text: string;
                    createdAt: any;
                    author: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        statusText?: string | null;
                        role: { __typename?: 'Role'; id: string; name: string };
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                        member?: { __typename?: 'Member'; id: string } | null;
                    };
                };
            }>;
            pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        };
        topComments: {
            __typename?: 'PostCommentConnection';
            edges: Array<{
                __typename?: 'PostCommentEdge';
                node: {
                    __typename?: 'PostComment';
                    id: string;
                    text: string;
                    userId: string;
                    createdAt: any;
                    author: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        email: string;
                        statusText?: string | null;
                        role: { __typename?: 'Role'; id: string; name: string };
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                        member?: { __typename?: 'Member'; id: string } | null;
                    };
                };
            }>;
        };
        author: {
            __typename?: 'User';
            id: string;
            fullName: string;
            statusText?: string | null;
            avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            role: { __typename?: 'Role'; id: string; name: string };
            member?: { __typename?: 'Member'; id: string } | null;
        };
    };
};

export type PostLikesQueryVariables = Exact<{
    where: PostLikeWhereInput;
    page?: InputMaybe<Scalars['Int']>;
}>;

export type PostLikesQuery = {
    __typename?: 'Query';
    postLikes: {
        __typename?: 'PostLikeConnection';
        edges: Array<{
            __typename?: 'PostLikeEdge';
            node: {
                __typename?: 'PostLike';
                id: string;
                createdAt: any;
                user: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                };
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type PostQueryVariables = Exact<{
    id: Scalars['String'];
    page?: InputMaybe<Scalars['Int']>;
}>;

export type PostQuery = {
    __typename?: 'Query';
    post: {
        __typename?: 'Post';
        id: string;
        visibility: PostVisibility;
        comments: {
            __typename?: 'PostCommentConnection';
            edges: Array<{
                __typename?: 'PostCommentEdge';
                node: {
                    __typename?: 'PostComment';
                    id: string;
                    text: string;
                    userId: string;
                    createdAt: any;
                    author: {
                        __typename?: 'User';
                        id: string;
                        fullName: string;
                        statusText?: string | null;
                        role: { __typename?: 'Role'; id: string; name: string };
                        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                        member?: { __typename?: 'Member'; id: string } | null;
                    };
                };
            }>;
            pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        };
    };
};

export type PostsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where: PostWhereInput;
}>;

export type PostsQuery = {
    __typename?: 'Query';
    posts: {
        __typename?: 'PostConnection';
        pageInfo: { __typename?: 'PageInfo'; totalPages: number };
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                communityId: string;
                repostCount: number;
                likeCount: number;
                isLiked: boolean;
                isBookmarked?: boolean | null;
                visibility: PostVisibility;
                type: PostType;
                createdAt: any;
                authorId: string;
                meta:
                    | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                    | { __typename?: 'DefaultPostMeta'; type: PostType }
                    | {
                          __typename?: 'EventMeta';
                          eventId: string;
                          event: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              type: EventType;
                              pinned: boolean;
                              price?: number | null;
                              isExternal: boolean;
                              externalLink?: string | null;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              purchased: boolean;
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | {
                          __typename?: 'ExperienceMeta';
                          experienceId: string;
                          type: PostType;
                          experience: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              price?: number | null;
                              isExternal: boolean;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              externalLink?: string | null;
                              purchased: boolean;
                              pinned: boolean;
                              type: EventType;
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                    | {
                          __typename?: 'RepostMeta';
                          post: {
                              __typename?: 'Post';
                              id: string;
                              communityId: string;
                              repostCount: number;
                              likeCount: number;
                              isLiked: boolean;
                              isBookmarked?: boolean | null;
                              visibility: PostVisibility;
                              type: PostType;
                              createdAt: any;
                              authorId: string;
                              translations: {
                                  __typename?: 'PostTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'PostTranslationEdge';
                                      node: {
                                          __typename?: 'PostTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          body?: string | null;
                                      };
                                  }>;
                              };
                              files: {
                                  __typename?: 'FileInfoConnection';
                                  edges: Array<{
                                      __typename?: 'FileInfoEdge';
                                      node: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          name: string;
                                          url: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  }>;
                              };
                              comments: {
                                  __typename?: 'PostCommentConnection';
                                  totalCount: number;
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                              };
                              topComments: {
                                  __typename?: 'PostCommentConnection';
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          userId: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              email: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                              };
                              author: {
                                  __typename?: 'User';
                                  id: string;
                                  fullName: string;
                                  statusText?: string | null;
                                  avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                  role: { __typename?: 'Role'; id: string; name: string };
                                  member?: { __typename?: 'Member'; id: string } | null;
                              };
                              meta:
                                  | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                                  | { __typename?: 'DefaultPostMeta'; type: PostType }
                                  | {
                                        __typename?: 'EventMeta';
                                        eventId: string;
                                        event: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            type: EventType;
                                            pinned: boolean;
                                            price?: number | null;
                                            isExternal: boolean;
                                            externalLink?: string | null;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            purchased: boolean;
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | {
                                        __typename?: 'ExperienceMeta';
                                        experienceId: string;
                                        type: PostType;
                                        experience: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            price?: number | null;
                                            isExternal: boolean;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            externalLink?: string | null;
                                            purchased: boolean;
                                            pinned: boolean;
                                            type: EventType;
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                                  | { __typename?: 'RepostMeta' };
                          };
                      };
                translations: {
                    __typename?: 'PostTranslationConnection';
                    edges: Array<{
                        __typename?: 'PostTranslationEdge';
                        node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                    }>;
                };
                files: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            name: string;
                            url: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    }>;
                };
                comments: {
                    __typename?: 'PostCommentConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                };
                topComments: {
                    __typename?: 'PostCommentConnection';
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            userId: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                email: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                };
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    statusText?: string | null;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
    };
};

export type RepostedUsersQueryVariables = Exact<{
    where: RepostedUserWhereInput;
    page?: InputMaybe<Scalars['Int']>;
}>;

export type RepostedUsersQuery = {
    __typename?: 'Query';
    repostedUsers: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type SavedPostsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where: PostWhereInput;
}>;

export type SavedPostsQuery = {
    __typename?: 'Query';
    bookmarkedPosts: {
        __typename?: 'PostConnection';
        pageInfo: { __typename?: 'PageInfo'; totalPages: number };
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                communityId: string;
                repostCount: number;
                likeCount: number;
                isLiked: boolean;
                isBookmarked?: boolean | null;
                visibility: PostVisibility;
                type: PostType;
                createdAt: any;
                authorId: string;
                meta:
                    | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                    | { __typename?: 'DefaultPostMeta'; type: PostType }
                    | {
                          __typename?: 'EventMeta';
                          eventId: string;
                          event: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              type: EventType;
                              pinned: boolean;
                              price?: number | null;
                              isExternal: boolean;
                              externalLink?: string | null;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              purchased: boolean;
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | {
                          __typename?: 'ExperienceMeta';
                          experienceId: string;
                          type: PostType;
                          experience: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              price?: number | null;
                              isExternal: boolean;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              externalLink?: string | null;
                              purchased: boolean;
                              pinned: boolean;
                              type: EventType;
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                    | {
                          __typename?: 'RepostMeta';
                          post: {
                              __typename?: 'Post';
                              id: string;
                              communityId: string;
                              repostCount: number;
                              likeCount: number;
                              isLiked: boolean;
                              isBookmarked?: boolean | null;
                              visibility: PostVisibility;
                              type: PostType;
                              createdAt: any;
                              authorId: string;
                              translations: {
                                  __typename?: 'PostTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'PostTranslationEdge';
                                      node: {
                                          __typename?: 'PostTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          body?: string | null;
                                      };
                                  }>;
                              };
                              files: {
                                  __typename?: 'FileInfoConnection';
                                  edges: Array<{
                                      __typename?: 'FileInfoEdge';
                                      node: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          name: string;
                                          url: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  }>;
                              };
                              comments: {
                                  __typename?: 'PostCommentConnection';
                                  totalCount: number;
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                              };
                              topComments: {
                                  __typename?: 'PostCommentConnection';
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          userId: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              email: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                              };
                              author: {
                                  __typename?: 'User';
                                  id: string;
                                  fullName: string;
                                  statusText?: string | null;
                                  avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                  role: { __typename?: 'Role'; id: string; name: string };
                                  member?: { __typename?: 'Member'; id: string } | null;
                              };
                              meta:
                                  | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                                  | { __typename?: 'DefaultPostMeta'; type: PostType }
                                  | {
                                        __typename?: 'EventMeta';
                                        eventId: string;
                                        event: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            type: EventType;
                                            pinned: boolean;
                                            price?: number | null;
                                            isExternal: boolean;
                                            externalLink?: string | null;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            purchased: boolean;
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | {
                                        __typename?: 'ExperienceMeta';
                                        experienceId: string;
                                        type: PostType;
                                        experience: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            price?: number | null;
                                            isExternal: boolean;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            externalLink?: string | null;
                                            purchased: boolean;
                                            pinned: boolean;
                                            type: EventType;
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                                  | { __typename?: 'RepostMeta' };
                          };
                      };
                translations: {
                    __typename?: 'PostTranslationConnection';
                    edges: Array<{
                        __typename?: 'PostTranslationEdge';
                        node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                    }>;
                };
                files: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            name: string;
                            url: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    }>;
                };
                comments: {
                    __typename?: 'PostCommentConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                };
                topComments: {
                    __typename?: 'PostCommentConnection';
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            userId: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                email: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                };
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    statusText?: string | null;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
    };
};

export type UserPostsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    userId: Scalars['String'];
    where: PostWhereInput;
}>;

export type UserPostsQuery = {
    __typename?: 'Query';
    userPosts: {
        __typename?: 'PostConnection';
        pageInfo: { __typename?: 'PageInfo'; totalPages: number };
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                communityId: string;
                repostCount: number;
                likeCount: number;
                isLiked: boolean;
                isBookmarked?: boolean | null;
                visibility: PostVisibility;
                type: PostType;
                createdAt: any;
                authorId: string;
                meta:
                    | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                    | { __typename?: 'DefaultPostMeta'; type: PostType }
                    | {
                          __typename?: 'EventMeta';
                          eventId: string;
                          event: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              type: EventType;
                              pinned: boolean;
                              price?: number | null;
                              isExternal: boolean;
                              externalLink?: string | null;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              purchased: boolean;
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | {
                          __typename?: 'ExperienceMeta';
                          experienceId: string;
                          type: PostType;
                          experience: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              price?: number | null;
                              isExternal: boolean;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              externalLink?: string | null;
                              purchased: boolean;
                              pinned: boolean;
                              type: EventType;
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                    | {
                          __typename?: 'RepostMeta';
                          post: {
                              __typename?: 'Post';
                              id: string;
                              communityId: string;
                              repostCount: number;
                              likeCount: number;
                              isLiked: boolean;
                              isBookmarked?: boolean | null;
                              visibility: PostVisibility;
                              type: PostType;
                              createdAt: any;
                              authorId: string;
                              translations: {
                                  __typename?: 'PostTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'PostTranslationEdge';
                                      node: {
                                          __typename?: 'PostTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          body?: string | null;
                                      };
                                  }>;
                              };
                              files: {
                                  __typename?: 'FileInfoConnection';
                                  edges: Array<{
                                      __typename?: 'FileInfoEdge';
                                      node: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          name: string;
                                          url: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  }>;
                              };
                              comments: {
                                  __typename?: 'PostCommentConnection';
                                  totalCount: number;
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                              };
                              topComments: {
                                  __typename?: 'PostCommentConnection';
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          userId: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              email: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                              };
                              author: {
                                  __typename?: 'User';
                                  id: string;
                                  fullName: string;
                                  statusText?: string | null;
                                  avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                  role: { __typename?: 'Role'; id: string; name: string };
                                  member?: { __typename?: 'Member'; id: string } | null;
                              };
                              meta:
                                  | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                                  | { __typename?: 'DefaultPostMeta'; type: PostType }
                                  | {
                                        __typename?: 'EventMeta';
                                        eventId: string;
                                        event: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            type: EventType;
                                            pinned: boolean;
                                            price?: number | null;
                                            isExternal: boolean;
                                            externalLink?: string | null;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            purchased: boolean;
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | {
                                        __typename?: 'ExperienceMeta';
                                        experienceId: string;
                                        type: PostType;
                                        experience: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            price?: number | null;
                                            isExternal: boolean;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            externalLink?: string | null;
                                            purchased: boolean;
                                            pinned: boolean;
                                            type: EventType;
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                                  | { __typename?: 'RepostMeta' };
                          };
                      };
                translations: {
                    __typename?: 'PostTranslationConnection';
                    edges: Array<{
                        __typename?: 'PostTranslationEdge';
                        node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                    }>;
                };
                files: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            name: string;
                            url: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    }>;
                };
                comments: {
                    __typename?: 'PostCommentConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                };
                topComments: {
                    __typename?: 'PostCommentConnection';
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            userId: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                email: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                };
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    statusText?: string | null;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
    };
};

export type ReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsQuery = {
    __typename?: 'Query';
    reports: {
        __typename?: 'ReportConnection';
        edges: Array<{
            __typename?: 'ReportEdge';
            node: { __typename?: 'Report'; code: string; embedUrl: string; id: string };
        }>;
    };
};

export type SearchQueryVariables = Exact<{
    keyword: Scalars['String'];
    communityId: Scalars['String'];
}>;

export type SearchQuery = {
    __typename?: 'Query';
    users: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
            };
        }>;
    };
    events: {
        __typename?: 'EventConnection';
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                price?: number | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
    experiences: {
        __typename?: 'EventConnection';
        edges: Array<{
            __typename?: 'EventEdge';
            node: {
                __typename?: 'Event';
                id: string;
                startTime?: any | null;
                tags?: Array<string> | null;
                type: EventType;
                price?: number | null;
                purchased: boolean;
                translations: {
                    __typename?: 'EventTranslationConnection';
                    edges: Array<{
                        __typename?: 'EventTranslationEdge';
                        node: {
                            __typename?: 'EventTranslation';
                            id: string;
                            lang: LanguageCode;
                            title: string;
                            description: string;
                            address?: string | null;
                        };
                    }>;
                };
                images: {
                    __typename?: 'EventImageConnection';
                    edges: Array<{
                        __typename?: 'EventImageEdge';
                        node: {
                            __typename?: 'EventImage';
                            id: string;
                            order: number;
                            image: {
                                __typename?: 'FileInfo';
                                id: string;
                                url: string;
                                name: string;
                                extension: string;
                                blurhash?: {
                                    __typename?: 'BlurhashMeta';
                                    hash: string;
                                    width: number;
                                    height: number;
                                } | null;
                            };
                        };
                    }>;
                };
            };
        }>;
    };
    communities: {
        __typename?: 'CommunityConnection';
        edges: Array<{
            __typename?: 'CommunityEdge';
            node: {
                __typename?: 'Community';
                id: string;
                logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                translations: {
                    __typename?: 'CommunityTranslationConnection';
                    edges: Array<{
                        __typename?: 'CommunityTranslationEdge';
                        node: {
                            __typename?: 'CommunityTranslation';
                            id: string;
                            lang: LanguageCode;
                            name: string;
                            location: string;
                            description?: string | null;
                        };
                    }>;
                };
                images: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: { __typename?: 'FileInfo'; id: string; url: string; extension: string };
                    }>;
                };
            };
        }>;
    };
    partners: {
        __typename?: 'CommunityConnection';
        edges: Array<{
            __typename?: 'CommunityEdge';
            node: {
                __typename?: 'Community';
                id: string;
                logo?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                translations: {
                    __typename?: 'CommunityTranslationConnection';
                    edges: Array<{
                        __typename?: 'CommunityTranslationEdge';
                        node: {
                            __typename?: 'CommunityTranslation';
                            id: string;
                            lang: LanguageCode;
                            name: string;
                            location: string;
                            description?: string | null;
                        };
                    }>;
                };
                images: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: { __typename?: 'FileInfo'; id: string; url: string; extension: string };
                    }>;
                };
            };
        }>;
    };
    posts: {
        __typename?: 'PostConnection';
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                communityId: string;
                repostCount: number;
                likeCount: number;
                isLiked: boolean;
                isBookmarked?: boolean | null;
                visibility: PostVisibility;
                type: PostType;
                createdAt: any;
                authorId: string;
                meta:
                    | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                    | { __typename?: 'DefaultPostMeta'; type: PostType }
                    | {
                          __typename?: 'EventMeta';
                          eventId: string;
                          event: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              type: EventType;
                              pinned: boolean;
                              price?: number | null;
                              isExternal: boolean;
                              externalLink?: string | null;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              purchased: boolean;
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | {
                          __typename?: 'ExperienceMeta';
                          experienceId: string;
                          type: PostType;
                          experience: {
                              __typename?: 'Event';
                              id: string;
                              createdById: string;
                              communityId: string;
                              startTime?: any | null;
                              tags?: Array<string> | null;
                              price?: number | null;
                              isExternal: boolean;
                              isDraft?: boolean | null;
                              isBookmarked?: boolean | null;
                              externalLink?: string | null;
                              purchased: boolean;
                              pinned: boolean;
                              type: EventType;
                              community: { __typename?: 'Community'; city: { __typename?: 'City'; name: string } };
                              translations: {
                                  __typename?: 'EventTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'EventTranslationEdge';
                                      node: {
                                          __typename?: 'EventTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          title: string;
                                          description: string;
                                          address?: string | null;
                                      };
                                  }>;
                              };
                              location?: { __typename?: 'Point'; lat: number; long: number } | null;
                              images: {
                                  __typename?: 'EventImageConnection';
                                  edges: Array<{
                                      __typename?: 'EventImageEdge';
                                      node: {
                                          __typename?: 'EventImage';
                                          id: string;
                                          order: number;
                                          image: {
                                              __typename?: 'FileInfo';
                                              id: string;
                                              url: string;
                                              name: string;
                                              extension: string;
                                              blurhash?: {
                                                  __typename?: 'BlurhashMeta';
                                                  hash: string;
                                                  width: number;
                                                  height: number;
                                              } | null;
                                          };
                                      };
                                  }>;
                              };
                          };
                      }
                    | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                    | {
                          __typename?: 'RepostMeta';
                          post: {
                              __typename?: 'Post';
                              id: string;
                              communityId: string;
                              repostCount: number;
                              likeCount: number;
                              isLiked: boolean;
                              isBookmarked?: boolean | null;
                              visibility: PostVisibility;
                              type: PostType;
                              createdAt: any;
                              authorId: string;
                              translations: {
                                  __typename?: 'PostTranslationConnection';
                                  edges: Array<{
                                      __typename?: 'PostTranslationEdge';
                                      node: {
                                          __typename?: 'PostTranslation';
                                          id: string;
                                          lang: LanguageCode;
                                          body?: string | null;
                                      };
                                  }>;
                              };
                              files: {
                                  __typename?: 'FileInfoConnection';
                                  edges: Array<{
                                      __typename?: 'FileInfoEdge';
                                      node: {
                                          __typename?: 'FileInfo';
                                          id: string;
                                          name: string;
                                          url: string;
                                          extension: string;
                                          blurhash?: {
                                              __typename?: 'BlurhashMeta';
                                              hash: string;
                                              width: number;
                                              height: number;
                                          } | null;
                                      };
                                  }>;
                              };
                              comments: {
                                  __typename?: 'PostCommentConnection';
                                  totalCount: number;
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                              };
                              topComments: {
                                  __typename?: 'PostCommentConnection';
                                  edges: Array<{
                                      __typename?: 'PostCommentEdge';
                                      node: {
                                          __typename?: 'PostComment';
                                          id: string;
                                          text: string;
                                          userId: string;
                                          createdAt: any;
                                          author: {
                                              __typename?: 'User';
                                              id: string;
                                              fullName: string;
                                              email: string;
                                              statusText?: string | null;
                                              role: { __typename?: 'Role'; id: string; name: string };
                                              avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                              member?: { __typename?: 'Member'; id: string } | null;
                                          };
                                      };
                                  }>;
                              };
                              author: {
                                  __typename?: 'User';
                                  id: string;
                                  fullName: string;
                                  statusText?: string | null;
                                  avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                  role: { __typename?: 'Role'; id: string; name: string };
                                  member?: { __typename?: 'Member'; id: string } | null;
                              };
                              meta:
                                  | { __typename?: 'CoverPhotoChangeMeta'; coverPhotoUrl: string }
                                  | { __typename?: 'DefaultPostMeta'; type: PostType }
                                  | {
                                        __typename?: 'EventMeta';
                                        eventId: string;
                                        event: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            type: EventType;
                                            pinned: boolean;
                                            price?: number | null;
                                            isExternal: boolean;
                                            externalLink?: string | null;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            purchased: boolean;
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | {
                                        __typename?: 'ExperienceMeta';
                                        experienceId: string;
                                        type: PostType;
                                        experience: {
                                            __typename?: 'Event';
                                            id: string;
                                            createdById: string;
                                            communityId: string;
                                            startTime?: any | null;
                                            tags?: Array<string> | null;
                                            price?: number | null;
                                            isExternal: boolean;
                                            isDraft?: boolean | null;
                                            isBookmarked?: boolean | null;
                                            externalLink?: string | null;
                                            purchased: boolean;
                                            pinned: boolean;
                                            type: EventType;
                                            community: {
                                                __typename?: 'Community';
                                                city: { __typename?: 'City'; name: string };
                                            };
                                            translations: {
                                                __typename?: 'EventTranslationConnection';
                                                edges: Array<{
                                                    __typename?: 'EventTranslationEdge';
                                                    node: {
                                                        __typename?: 'EventTranslation';
                                                        id: string;
                                                        lang: LanguageCode;
                                                        title: string;
                                                        description: string;
                                                        address?: string | null;
                                                    };
                                                }>;
                                            };
                                            location?: { __typename?: 'Point'; lat: number; long: number } | null;
                                            images: {
                                                __typename?: 'EventImageConnection';
                                                edges: Array<{
                                                    __typename?: 'EventImageEdge';
                                                    node: {
                                                        __typename?: 'EventImage';
                                                        id: string;
                                                        order: number;
                                                        image: {
                                                            __typename?: 'FileInfo';
                                                            id: string;
                                                            url: string;
                                                            name: string;
                                                            extension: string;
                                                            blurhash?: {
                                                                __typename?: 'BlurhashMeta';
                                                                hash: string;
                                                                width: number;
                                                                height: number;
                                                            } | null;
                                                        };
                                                    };
                                                }>;
                                            };
                                        };
                                    }
                                  | { __typename?: 'ProfilePictureChangeMeta'; profilePictureUrl: string }
                                  | { __typename?: 'RepostMeta' };
                          };
                      };
                translations: {
                    __typename?: 'PostTranslationConnection';
                    edges: Array<{
                        __typename?: 'PostTranslationEdge';
                        node: { __typename?: 'PostTranslation'; id: string; lang: LanguageCode; body?: string | null };
                    }>;
                };
                files: {
                    __typename?: 'FileInfoConnection';
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            name: string;
                            url: string;
                            extension: string;
                            blurhash?: {
                                __typename?: 'BlurhashMeta';
                                hash: string;
                                width: number;
                                height: number;
                            } | null;
                        };
                    }>;
                };
                comments: {
                    __typename?: 'PostCommentConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
                };
                topComments: {
                    __typename?: 'PostCommentConnection';
                    edges: Array<{
                        __typename?: 'PostCommentEdge';
                        node: {
                            __typename?: 'PostComment';
                            id: string;
                            text: string;
                            userId: string;
                            createdAt: any;
                            author: {
                                __typename?: 'User';
                                id: string;
                                fullName: string;
                                email: string;
                                statusText?: string | null;
                                role: { __typename?: 'Role'; id: string; name: string };
                                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                                member?: { __typename?: 'Member'; id: string } | null;
                            };
                        };
                    }>;
                };
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    statusText?: string | null;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                    role: { __typename?: 'Role'; id: string; name: string };
                    member?: { __typename?: 'Member'; id: string } | null;
                };
            };
        }>;
    };
};

export type ActivitiesQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
}>;

export type ActivitiesQuery = {
    __typename?: 'Query';
    activities: {
        __typename?: 'ActivityConnection';
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'ActivityEdge';
            node: {
                __typename?: 'Activity';
                id: string;
                type: ActivityType;
                createdAt: any;
                author: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                };
                meta: {
                    __typename?: 'ActivityMeta';
                    post: {
                        __typename?: 'Post';
                        id: string;
                        files: {
                            __typename?: 'FileInfoConnection';
                            edges: Array<{
                                __typename?: 'FileInfoEdge';
                                node: { __typename?: 'FileInfo'; id: string; extension: string; url: string };
                            }>;
                        };
                    };
                };
            };
        }>;
    };
};

export type ApprovedMembersQueryVariables = Exact<{ [key: string]: never }>;

export type ApprovedMembersQuery = {
    __typename?: 'Query';
    approvedMembers?: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                statusText?: string | null;
                role: { __typename?: 'Role'; id: string; name: string };
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                member?: { __typename?: 'Member'; id: string } | null;
            };
        }>;
    } | null;
};

export type FollowRequestsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
}>;

export type FollowRequestsQuery = {
    __typename?: 'Query';
    followRequests: {
        __typename?: 'FollowConnection';
        totalCount: number;
        edges: Array<{
            __typename?: 'FollowEdge';
            node: {
                __typename?: 'Follow';
                id: string;
                follower: {
                    __typename?: 'User';
                    id: string;
                    fullName: string;
                    avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                };
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type FollowersQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
}>;

export type FollowersQuery = {
    __typename?: 'Query';
    followers: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                incognito?: boolean | null;
                nickname?: string | null;
                avatar?: {
                    __typename?: 'FileInfo';
                    id: string;
                    url: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string } | null;
                } | null;
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type FollowingsQueryVariables = Exact<{
    page?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<FollowWhereInput>;
}>;

export type FollowingsQuery = {
    __typename?: 'Query';
    followings: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                incognito?: boolean | null;
                nickname?: string | null;
                avatar?: {
                    __typename?: 'FileInfo';
                    id: string;
                    url: string;
                    blurhash?: { __typename?: 'BlurhashMeta'; hash: string } | null;
                } | null;
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
    };
};

export type GuestsQueryVariables = Exact<{ [key: string]: never }>;

export type GuestsQuery = {
    __typename?: 'Query';
    guests?: {
        __typename?: 'UserConnection';
        edges: Array<{
            __typename?: 'UserEdge';
            node: {
                __typename?: 'User';
                id: string;
                fullName: string;
                statusText?: string | null;
                role: { __typename?: 'Role'; id: string; name: string };
                avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
                member?: { __typename?: 'Member'; id: string } | null;
            };
        }>;
    } | null;
};

export type UserPostsWithImagesQueryVariables = Exact<{
    userId: Scalars['String'];
    where: PostWhereInput;
    page?: InputMaybe<Scalars['Int']>;
}>;

export type UserPostsWithImagesQuery = {
    __typename?: 'Query';
    userPostsWithImages: {
        __typename?: 'PostConnection';
        edges: Array<{
            __typename?: 'PostEdge';
            node: {
                __typename?: 'Post';
                id: string;
                files: {
                    __typename?: 'FileInfoConnection';
                    totalCount: number;
                    edges: Array<{
                        __typename?: 'FileInfoEdge';
                        node: {
                            __typename?: 'FileInfo';
                            id: string;
                            url: string;
                            extension: string;
                            blurhash?: { __typename?: 'BlurhashMeta'; hash: string } | null;
                        };
                    }>;
                };
            };
        }>;
    };
};

export type UserQueryVariables = Exact<{
    userId: Scalars['String'];
}>;

export type UserQuery = {
    __typename?: 'Query';
    followerCount: number;
    followingCount: number;
    user: {
        __typename?: 'User';
        id: string;
        fullName: string;
        postCount?: number | null;
        bio?: string | null;
        followStatus: FollowStatus;
        statusText?: string | null;
        email: string;
        isChatable: boolean;
        role: { __typename?: 'Role'; id: string; name: string };
        avatar?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        coverPhoto?: { __typename?: 'FileInfo'; id: string; url: string } | null;
        member?: { __typename?: 'Member'; id: string } | null;
        matrixAuth?: { __typename?: 'MatrixAuthData'; user_id: string } | null;
    };
};

export const AvatarPartsFragmentDoc = gql`
    fragment AvatarParts on FileInfo {
        id
        url
        blurhash {
            hash
        }
    }
`;
export const MemberProfilePartsFragmentDoc = gql`
    fragment MemberProfileParts on MemberProfile {
        id
        firstName
        lastName
        email
        bio
        phoneNumber
        dateOfBirth
        cityOfResidence
        countryOfResidence {
            id
            name
        }
        countryOfResidenceId
        maritalStatus
    }
`;
export const EventPartsFragmentDoc = gql`
    fragment EventParts on Event {
        id
        createdById
        translations {
            edges {
                node {
                    id
                    lang
                    title
                    description
                    address
                }
            }
        }
        communityId
        community {
            city {
                name
            }
        }
        startTime
        tags
        type
        pinned
        price
        isExternal
        externalLink
        isDraft
        isBookmarked
        location {
            lat
            long
        }
        purchased
        images {
            edges {
                node {
                    id
                    order
                    image {
                        id
                        url
                        name
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
export const ExperiencePartsFragmentDoc = gql`
    fragment ExperienceParts on Event {
        id
        createdById
        community {
            city {
                name
            }
        }
        translations {
            edges {
                node {
                    id
                    lang
                    title
                    description
                    address
                }
            }
        }
        communityId
        startTime
        tags
        price
        isExternal
        isDraft
        isBookmarked
        externalLink
        location {
            lat
            long
        }
        purchased
        pinned
        type
        images {
            edges {
                node {
                    id
                    order
                    image {
                        id
                        url
                        name
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
export const PostPartsFragmentDoc = gql`
    fragment PostParts on Post {
        id
        translations {
            edges {
                node {
                    id
                    lang
                    body
                }
            }
        }
        communityId
        repostCount
        likeCount
        isLiked
        isBookmarked
        visibility
        type
        createdAt
        files {
            edges {
                node {
                    id
                    name
                    url
                    extension
                    blurhash {
                        hash
                        width
                        height
                    }
                }
            }
        }
        comments {
            totalCount
            edges {
                node {
                    id
                    text
                    createdAt
                    author {
                        id
                        fullName
                        role {
                            id
                            name
                        }
                        statusText
                        avatar {
                            id
                            url
                        }
                        member {
                            id
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
        topComments {
            edges {
                node {
                    id
                    text
                    userId
                    createdAt
                    author {
                        id
                        fullName
                        email
                        role {
                            id
                            name
                        }
                        statusText
                        avatar {
                            id
                            url
                        }
                        member {
                            id
                        }
                    }
                }
            }
        }
        authorId
        author {
            id
            fullName
            avatar {
                id
                url
            }
            role {
                id
                name
            }
            statusText
            member {
                id
            }
        }
        meta {
            ... on EventMeta {
                eventId
                event {
                    ...EventParts
                }
            }
            ... on ExperienceMeta {
                experienceId
                type
                experience {
                    ...ExperienceParts
                }
            }
            ... on ProfilePictureChangeMeta {
                profilePictureUrl
            }
            ... on CoverPhotoChangeMeta {
                coverPhotoUrl
            }
            ... on DefaultPostMeta {
                type
            }
        }
    }
    ${EventPartsFragmentDoc}
    ${ExperiencePartsFragmentDoc}
`;
export const UserPartsFragmentDoc = gql`
    fragment UserParts on User {
        id
        fullName
        postCount
        bio
        followStatus
        role {
            id
            name
        }
        statusText
        avatar {
            id
            url
        }
        coverPhoto {
            id
            url
        }
        email
        member {
            id
        }
        isChatable
        matrixAuth {
            user_id
        }
    }
`;
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($input: ActivateAccountInput!) {
        activateAccount(input: $input) {
            success
        }
    }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
        ActivateAccountDocument,
        options,
    );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            success
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const LoginDocument = gql`
    mutation Login($credentials: CredentialsInput!) {
        login(credentials: $credentials) {
            accessToken
            matrixAuth {
                home_server
                access_token
                user_id
                device_id
            }
        }
    }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterMatrixUserDocument = gql`
    mutation RegisterMatrixUser($input: RegisterMatrixUserInput!) {
        registerMatrixUser(input: $input) {
            access_token
            device_id
            home_server
            user_id
        }
    }
`;
export type RegisterMatrixUserMutationFn = Apollo.MutationFunction<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;

/**
 * __useRegisterMatrixUserMutation__
 *
 * To run a mutation, you first call `useRegisterMatrixUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMatrixUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMatrixUserMutation, { data, loading, error }] = useRegisterMatrixUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMatrixUserMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>(
        RegisterMatrixUserDocument,
        options,
    );
}
export type RegisterMatrixUserMutationHookResult = ReturnType<typeof useRegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationResult = Apollo.MutationResult<RegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationOptions = Apollo.BaseMutationOptions<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;
export const RegisterMemberDocument = gql`
    mutation RegisterMember($member: RegisterMemberInput!) {
        registerMember(member: $member)
    }
`;
export type RegisterMemberMutationFn = Apollo.MutationFunction<RegisterMemberMutation, RegisterMemberMutationVariables>;

/**
 * __useRegisterMemberMutation__
 *
 * To run a mutation, you first call `useRegisterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMemberMutation, { data, loading, error }] = useRegisterMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useRegisterMemberMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMemberMutation, RegisterMemberMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterMemberMutation, RegisterMemberMutationVariables>(RegisterMemberDocument, options);
}
export type RegisterMemberMutationHookResult = ReturnType<typeof useRegisterMemberMutation>;
export type RegisterMemberMutationResult = Apollo.MutationResult<RegisterMemberMutation>;
export type RegisterMemberMutationOptions = Apollo.BaseMutationOptions<
    RegisterMemberMutation,
    RegisterMemberMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($input: ForgotPasswordInput!) {
        sendPasswordResetEmail(input: $input) {
            sent
        }
    }
`;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
        SendPasswordResetEmailDocument,
        options,
    );
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
>;
export const SendVerifyEmailDocument = gql`
    mutation SendVerifyEmail($input: SendVerifyEmailInput!) {
        sendVerificationEmail(input: $input) {
            sent
        }
    }
`;
export type SendVerifyEmailMutationFn = Apollo.MutationFunction<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;

/**
 * __useSendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useSendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerifyEmailMutation, { data, loading, error }] = useSendVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerifyEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>(
        SendVerifyEmailDocument,
        options,
    );
}
export type SendVerifyEmailMutationHookResult = ReturnType<typeof useSendVerifyEmailMutation>;
export type SendVerifyEmailMutationResult = Apollo.MutationResult<SendVerifyEmailMutation>;
export type SendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;
export const AcceptMembershipRequestDocument = gql`
    mutation AcceptMembershipRequest($input: AcceptMembershipRequestInput!) {
        acceptMembershipRequest(input: $input) {
            community {
                id
            }
        }
    }
`;
export type AcceptMembershipRequestMutationFn = Apollo.MutationFunction<
    AcceptMembershipRequestMutation,
    AcceptMembershipRequestMutationVariables
>;

/**
 * __useAcceptMembershipRequestMutation__
 *
 * To run a mutation, you first call `useAcceptMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMembershipRequestMutation, { data, loading, error }] = useAcceptMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>(
        AcceptMembershipRequestDocument,
        options,
    );
}
export type AcceptMembershipRequestMutationHookResult = ReturnType<typeof useAcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationResult = Apollo.MutationResult<AcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    AcceptMembershipRequestMutation,
    AcceptMembershipRequestMutationVariables
>;
export const CancelMembershipRequestDocument = gql`
    mutation CancelMembershipRequest($input: CancelMembershipRequestInput!) {
        cancelMembershipRequest(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type CancelMembershipRequestMutationFn = Apollo.MutationFunction<
    CancelMembershipRequestMutation,
    CancelMembershipRequestMutationVariables
>;

/**
 * __useCancelMembershipRequestMutation__
 *
 * To run a mutation, you first call `useCancelMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMembershipRequestMutation, { data, loading, error }] = useCancelMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelMembershipRequestMutation, CancelMembershipRequestMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelMembershipRequestMutation, CancelMembershipRequestMutationVariables>(
        CancelMembershipRequestDocument,
        options,
    );
}
export type CancelMembershipRequestMutationHookResult = ReturnType<typeof useCancelMembershipRequestMutation>;
export type CancelMembershipRequestMutationResult = Apollo.MutationResult<CancelMembershipRequestMutation>;
export type CancelMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    CancelMembershipRequestMutation,
    CancelMembershipRequestMutationVariables
>;
export const CreateCommunityInvitationDocument = gql`
    mutation CreateCommunityInvitation($input: CreateCommunityInvitationInput!) {
        createCommunityInvitation(input: $input) {
            invitation {
                id
                usageLimit
                usedCount
                expiresAt
            }
        }
    }
`;
export type CreateCommunityInvitationMutationFn = Apollo.MutationFunction<
    CreateCommunityInvitationMutation,
    CreateCommunityInvitationMutationVariables
>;

/**
 * __useCreateCommunityInvitationMutation__
 *
 * To run a mutation, you first call `useCreateCommunityInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityInvitationMutation, { data, loading, error }] = useCreateCommunityInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityInvitationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCommunityInvitationMutation,
        CreateCommunityInvitationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCommunityInvitationMutation, CreateCommunityInvitationMutationVariables>(
        CreateCommunityInvitationDocument,
        options,
    );
}
export type CreateCommunityInvitationMutationHookResult = ReturnType<typeof useCreateCommunityInvitationMutation>;
export type CreateCommunityInvitationMutationResult = Apollo.MutationResult<CreateCommunityInvitationMutation>;
export type CreateCommunityInvitationMutationOptions = Apollo.BaseMutationOptions<
    CreateCommunityInvitationMutation,
    CreateCommunityInvitationMutationVariables
>;
export const CreateCommunityDocument = gql`
    mutation CreateCommunity($community: CreateCommunityInput!) {
        createCommunity(community: $community) {
            community {
                id
            }
        }
    }
`;
export type CreateCommunityMutationFn = Apollo.MutationFunction<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useCreateCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCommunityMutation, CreateCommunityMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(
        CreateCommunityDocument,
        options,
    );
}
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
>;
export const DeleteCommunityImageDocument = gql`
    mutation DeleteCommunityImage($community: DeleteCommunityImageInput!) {
        deleteCommunityImage(community: $community) {
            community {
                id
            }
        }
    }
`;
export type DeleteCommunityImageMutationFn = Apollo.MutationFunction<
    DeleteCommunityImageMutation,
    DeleteCommunityImageMutationVariables
>;

/**
 * __useDeleteCommunityImageMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityImageMutation, { data, loading, error }] = useDeleteCommunityImageMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useDeleteCommunityImageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommunityImageMutation, DeleteCommunityImageMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCommunityImageMutation, DeleteCommunityImageMutationVariables>(
        DeleteCommunityImageDocument,
        options,
    );
}
export type DeleteCommunityImageMutationHookResult = ReturnType<typeof useDeleteCommunityImageMutation>;
export type DeleteCommunityImageMutationResult = Apollo.MutationResult<DeleteCommunityImageMutation>;
export type DeleteCommunityImageMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityImageMutation,
    DeleteCommunityImageMutationVariables
>;
export const DeleteCommunityInvitationDocument = gql`
    mutation DeleteCommunityInvitation($input: DeleteCommunityInvitationyInput!) {
        deleteCommunityInvitation(input: $input) {
            invitation {
                id
            }
        }
    }
`;
export type DeleteCommunityInvitationMutationFn = Apollo.MutationFunction<
    DeleteCommunityInvitationMutation,
    DeleteCommunityInvitationMutationVariables
>;

/**
 * __useDeleteCommunityInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityInvitationMutation, { data, loading, error }] = useDeleteCommunityInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommunityInvitationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCommunityInvitationMutation,
        DeleteCommunityInvitationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCommunityInvitationMutation, DeleteCommunityInvitationMutationVariables>(
        DeleteCommunityInvitationDocument,
        options,
    );
}
export type DeleteCommunityInvitationMutationHookResult = ReturnType<typeof useDeleteCommunityInvitationMutation>;
export type DeleteCommunityInvitationMutationResult = Apollo.MutationResult<DeleteCommunityInvitationMutation>;
export type DeleteCommunityInvitationMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityInvitationMutation,
    DeleteCommunityInvitationMutationVariables
>;
export const DeleteCommunityDocument = gql`
    mutation DeleteCommunity($id: String!) {
        deleteCommunity(id: $id) {
            id
        }
    }
`;
export type DeleteCommunityMutationFn = Apollo.MutationFunction<
    DeleteCommunityMutation,
    DeleteCommunityMutationVariables
>;

/**
 * __useDeleteCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMutation, { data, loading, error }] = useDeleteCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(
        DeleteCommunityDocument,
        options,
    );
}
export type DeleteCommunityMutationHookResult = ReturnType<typeof useDeleteCommunityMutation>;
export type DeleteCommunityMutationResult = Apollo.MutationResult<DeleteCommunityMutation>;
export type DeleteCommunityMutationOptions = Apollo.BaseMutationOptions<
    DeleteCommunityMutation,
    DeleteCommunityMutationVariables
>;
export const JoinCommunityDocument = gql`
    mutation JoinCommunity($input: JoinCommunityInput!) {
        joinCommunity(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type JoinCommunityMutationFn = Apollo.MutationFunction<JoinCommunityMutation, JoinCommunityMutationVariables>;

/**
 * __useJoinCommunityMutation__
 *
 * To run a mutation, you first call `useJoinCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityMutation, { data, loading, error }] = useJoinCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<JoinCommunityMutation, JoinCommunityMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<JoinCommunityMutation, JoinCommunityMutationVariables>(JoinCommunityDocument, options);
}
export type JoinCommunityMutationHookResult = ReturnType<typeof useJoinCommunityMutation>;
export type JoinCommunityMutationResult = Apollo.MutationResult<JoinCommunityMutation>;
export type JoinCommunityMutationOptions = Apollo.BaseMutationOptions<
    JoinCommunityMutation,
    JoinCommunityMutationVariables
>;
export const LeaveCommunityDocument = gql`
    mutation LeaveCommunity($input: LeaveCommunityInput!) {
        leaveCommunity(input: $input) {
            community {
                id
                membershipStatus
            }
        }
    }
`;
export type LeaveCommunityMutationFn = Apollo.MutationFunction<LeaveCommunityMutation, LeaveCommunityMutationVariables>;

/**
 * __useLeaveCommunityMutation__
 *
 * To run a mutation, you first call `useLeaveCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCommunityMutation, { data, loading, error }] = useLeaveCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<LeaveCommunityMutation, LeaveCommunityMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LeaveCommunityMutation, LeaveCommunityMutationVariables>(LeaveCommunityDocument, options);
}
export type LeaveCommunityMutationHookResult = ReturnType<typeof useLeaveCommunityMutation>;
export type LeaveCommunityMutationResult = Apollo.MutationResult<LeaveCommunityMutation>;
export type LeaveCommunityMutationOptions = Apollo.BaseMutationOptions<
    LeaveCommunityMutation,
    LeaveCommunityMutationVariables
>;
export const RejectMembershipRequestDocument = gql`
    mutation RejectMembershipRequest($input: RejectMembershipRequestInput!) {
        rejectMembershipRequest(input: $input) {
            id
        }
    }
`;
export type RejectMembershipRequestMutationFn = Apollo.MutationFunction<
    RejectMembershipRequestMutation,
    RejectMembershipRequestMutationVariables
>;

/**
 * __useRejectMembershipRequestMutation__
 *
 * To run a mutation, you first call `useRejectMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMembershipRequestMutation, { data, loading, error }] = useRejectMembershipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMembershipRequestMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>(
        RejectMembershipRequestDocument,
        options,
    );
}
export type RejectMembershipRequestMutationHookResult = ReturnType<typeof useRejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationResult = Apollo.MutationResult<RejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationOptions = Apollo.BaseMutationOptions<
    RejectMembershipRequestMutation,
    RejectMembershipRequestMutationVariables
>;
export const UpdateCommunityPartnersDocument = gql`
    mutation UpdateCommunityPartners($input: UpdateCommunityPartnersInput!) {
        updateCommunityPartners(input: $input) {
            community {
                id
            }
        }
    }
`;
export type UpdateCommunityPartnersMutationFn = Apollo.MutationFunction<
    UpdateCommunityPartnersMutation,
    UpdateCommunityPartnersMutationVariables
>;

/**
 * __useUpdateCommunityPartnersMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityPartnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityPartnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityPartnersMutation, { data, loading, error }] = useUpdateCommunityPartnersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityPartnersMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCommunityPartnersMutation, UpdateCommunityPartnersMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateCommunityPartnersMutation, UpdateCommunityPartnersMutationVariables>(
        UpdateCommunityPartnersDocument,
        options,
    );
}
export type UpdateCommunityPartnersMutationHookResult = ReturnType<typeof useUpdateCommunityPartnersMutation>;
export type UpdateCommunityPartnersMutationResult = Apollo.MutationResult<UpdateCommunityPartnersMutation>;
export type UpdateCommunityPartnersMutationOptions = Apollo.BaseMutationOptions<
    UpdateCommunityPartnersMutation,
    UpdateCommunityPartnersMutationVariables
>;
export const UpdateCommunityDocument = gql`
    mutation UpdateCommunity($community: UpdateCommunityInput!) {
        updateCommunity(community: $community) {
            community {
                id
            }
        }
    }
`;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<
    UpdateCommunityMutation,
    UpdateCommunityMutationVariables
>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUpdateCommunityMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(
        UpdateCommunityDocument,
        options,
    );
}
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<
    UpdateCommunityMutation,
    UpdateCommunityMutationVariables
>;
export const UploadCommunityImagesDocument = gql`
    mutation UploadCommunityImages($community: UploadCommunityImagesInput!) {
        uploadCommunityImages(community: $community) {
            community {
                id
                images {
                    edges {
                        node {
                            id
                            url
                            extension
                            blurhash {
                                hash
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    }
`;
export type UploadCommunityImagesMutationFn = Apollo.MutationFunction<
    UploadCommunityImagesMutation,
    UploadCommunityImagesMutationVariables
>;

/**
 * __useUploadCommunityImagesMutation__
 *
 * To run a mutation, you first call `useUploadCommunityImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityImagesMutation, { data, loading, error }] = useUploadCommunityImagesMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUploadCommunityImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadCommunityImagesMutation, UploadCommunityImagesMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadCommunityImagesMutation, UploadCommunityImagesMutationVariables>(
        UploadCommunityImagesDocument,
        options,
    );
}
export type UploadCommunityImagesMutationHookResult = ReturnType<typeof useUploadCommunityImagesMutation>;
export type UploadCommunityImagesMutationResult = Apollo.MutationResult<UploadCommunityImagesMutation>;
export type UploadCommunityImagesMutationOptions = Apollo.BaseMutationOptions<
    UploadCommunityImagesMutation,
    UploadCommunityImagesMutationVariables
>;
export const UploadCommunityLogoDocument = gql`
    mutation UploadCommunityLogo($community: UploadCommunityLogoInput!) {
        uploadCommunityLogo(community: $community) {
            community {
                id
                logo {
                    id
                    url
                }
            }
        }
    }
`;
export type UploadCommunityLogoMutationFn = Apollo.MutationFunction<
    UploadCommunityLogoMutation,
    UploadCommunityLogoMutationVariables
>;

/**
 * __useUploadCommunityLogoMutation__
 *
 * To run a mutation, you first call `useUploadCommunityLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityLogoMutation, { data, loading, error }] = useUploadCommunityLogoMutation({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useUploadCommunityLogoMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadCommunityLogoMutation, UploadCommunityLogoMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadCommunityLogoMutation, UploadCommunityLogoMutationVariables>(
        UploadCommunityLogoDocument,
        options,
    );
}
export type UploadCommunityLogoMutationHookResult = ReturnType<typeof useUploadCommunityLogoMutation>;
export type UploadCommunityLogoMutationResult = Apollo.MutationResult<UploadCommunityLogoMutation>;
export type UploadCommunityLogoMutationOptions = Apollo.BaseMutationOptions<
    UploadCommunityLogoMutation,
    UploadCommunityLogoMutationVariables
>;
export const BookmarkEventDocument = gql`
    mutation BookmarkEvent($eventId: BookmarkEventInput!) {
        bookmarkEvent(eventId: $eventId) {
            event {
                id
                isBookmarked
            }
        }
    }
`;
export type BookmarkEventMutationFn = Apollo.MutationFunction<BookmarkEventMutation, BookmarkEventMutationVariables>;

/**
 * __useBookmarkEventMutation__
 *
 * To run a mutation, you first call `useBookmarkEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkEventMutation, { data, loading, error }] = useBookmarkEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useBookmarkEventMutation(
    baseOptions?: Apollo.MutationHookOptions<BookmarkEventMutation, BookmarkEventMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookmarkEventMutation, BookmarkEventMutationVariables>(BookmarkEventDocument, options);
}
export type BookmarkEventMutationHookResult = ReturnType<typeof useBookmarkEventMutation>;
export type BookmarkEventMutationResult = Apollo.MutationResult<BookmarkEventMutation>;
export type BookmarkEventMutationOptions = Apollo.BaseMutationOptions<
    BookmarkEventMutation,
    BookmarkEventMutationVariables
>;
export const CreateEventPostDocument = gql`
    mutation createEventPost($post: CreateEventPostInput!) {
        createEventPost(post: $post) {
            success
        }
    }
`;
export type CreateEventPostMutationFn = Apollo.MutationFunction<
    CreateEventPostMutation,
    CreateEventPostMutationVariables
>;

/**
 * __useCreateEventPostMutation__
 *
 * To run a mutation, you first call `useCreateEventPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventPostMutation, { data, loading, error }] = useCreateEventPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useCreateEventPostMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateEventPostMutation, CreateEventPostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateEventPostMutation, CreateEventPostMutationVariables>(
        CreateEventPostDocument,
        options,
    );
}
export type CreateEventPostMutationHookResult = ReturnType<typeof useCreateEventPostMutation>;
export type CreateEventPostMutationResult = Apollo.MutationResult<CreateEventPostMutation>;
export type CreateEventPostMutationOptions = Apollo.BaseMutationOptions<
    CreateEventPostMutation,
    CreateEventPostMutationVariables
>;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: CreateEventInput!) {
        createEvent(event: $event) {
            event {
                id
            }
        }
    }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateExperienceDocument = gql`
    mutation CreateExperience($experience: CreateEventInput!) {
        createExperience(experience: $experience) {
            event {
                id
            }
        }
    }
`;
export type CreateExperienceMutationFn = Apollo.MutationFunction<
    CreateExperienceMutation,
    CreateExperienceMutationVariables
>;

/**
 * __useCreateExperienceMutation__
 *
 * To run a mutation, you first call `useCreateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperienceMutation, { data, loading, error }] = useCreateExperienceMutation({
 *   variables: {
 *      experience: // value for 'experience'
 *   },
 * });
 */
export function useCreateExperienceMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateExperienceMutation, CreateExperienceMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateExperienceMutation, CreateExperienceMutationVariables>(
        CreateExperienceDocument,
        options,
    );
}
export type CreateExperienceMutationHookResult = ReturnType<typeof useCreateExperienceMutation>;
export type CreateExperienceMutationResult = Apollo.MutationResult<CreateExperienceMutation>;
export type CreateExperienceMutationOptions = Apollo.BaseMutationOptions<
    CreateExperienceMutation,
    CreateExperienceMutationVariables
>;
export const DeleteEventImageDocument = gql`
    mutation DeleteEventImage($event: DeleteEventImageInput!) {
        deleteEventImage(event: $event) {
            event {
                id
            }
        }
    }
`;
export type DeleteEventImageMutationFn = Apollo.MutationFunction<
    DeleteEventImageMutation,
    DeleteEventImageMutationVariables
>;

/**
 * __useDeleteEventImageMutation__
 *
 * To run a mutation, you first call `useDeleteEventImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventImageMutation, { data, loading, error }] = useDeleteEventImageMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useDeleteEventImageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteEventImageMutation, DeleteEventImageMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteEventImageMutation, DeleteEventImageMutationVariables>(
        DeleteEventImageDocument,
        options,
    );
}
export type DeleteEventImageMutationHookResult = ReturnType<typeof useDeleteEventImageMutation>;
export type DeleteEventImageMutationResult = Apollo.MutationResult<DeleteEventImageMutation>;
export type DeleteEventImageMutationOptions = Apollo.BaseMutationOptions<
    DeleteEventImageMutation,
    DeleteEventImageMutationVariables
>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: String!) {
        deleteEvent(id: $id) {
            id
        }
    }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const EventPaymentDocument = gql`
    mutation eventPayment($input: EventPaymentInput!) {
        eventPayment(input: $input) {
            result
        }
    }
`;
export type EventPaymentMutationFn = Apollo.MutationFunction<EventPaymentMutation, EventPaymentMutationVariables>;

/**
 * __useEventPaymentMutation__
 *
 * To run a mutation, you first call `useEventPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventPaymentMutation, { data, loading, error }] = useEventPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventPaymentMutation(
    baseOptions?: Apollo.MutationHookOptions<EventPaymentMutation, EventPaymentMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EventPaymentMutation, EventPaymentMutationVariables>(EventPaymentDocument, options);
}
export type EventPaymentMutationHookResult = ReturnType<typeof useEventPaymentMutation>;
export type EventPaymentMutationResult = Apollo.MutationResult<EventPaymentMutation>;
export type EventPaymentMutationOptions = Apollo.BaseMutationOptions<
    EventPaymentMutation,
    EventPaymentMutationVariables
>;
export const ReorderEventImagesDocument = gql`
    mutation ReorderEventImages($event: ReorderEventImagesInput!) {
        reorderEventImages(event: $event) {
            event {
                ...EventParts
            }
        }
    }
    ${EventPartsFragmentDoc}
`;
export type ReorderEventImagesMutationFn = Apollo.MutationFunction<
    ReorderEventImagesMutation,
    ReorderEventImagesMutationVariables
>;

/**
 * __useReorderEventImagesMutation__
 *
 * To run a mutation, you first call `useReorderEventImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderEventImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderEventImagesMutation, { data, loading, error }] = useReorderEventImagesMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useReorderEventImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<ReorderEventImagesMutation, ReorderEventImagesMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReorderEventImagesMutation, ReorderEventImagesMutationVariables>(
        ReorderEventImagesDocument,
        options,
    );
}
export type ReorderEventImagesMutationHookResult = ReturnType<typeof useReorderEventImagesMutation>;
export type ReorderEventImagesMutationResult = Apollo.MutationResult<ReorderEventImagesMutation>;
export type ReorderEventImagesMutationOptions = Apollo.BaseMutationOptions<
    ReorderEventImagesMutation,
    ReorderEventImagesMutationVariables
>;
export const TogglePinDocument = gql`
    mutation TogglePin($event: TogglePinEventInput!) {
        togglePin(event: $event) {
            event {
                id
                pinned
            }
        }
    }
`;
export type TogglePinMutationFn = Apollo.MutationFunction<TogglePinMutation, TogglePinMutationVariables>;

/**
 * __useTogglePinMutation__
 *
 * To run a mutation, you first call `useTogglePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePinMutation, { data, loading, error }] = useTogglePinMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTogglePinMutation(
    baseOptions?: Apollo.MutationHookOptions<TogglePinMutation, TogglePinMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<TogglePinMutation, TogglePinMutationVariables>(TogglePinDocument, options);
}
export type TogglePinMutationHookResult = ReturnType<typeof useTogglePinMutation>;
export type TogglePinMutationResult = Apollo.MutationResult<TogglePinMutation>;
export type TogglePinMutationOptions = Apollo.BaseMutationOptions<TogglePinMutation, TogglePinMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: UpdateEventInput!) {
        updateEvent(event: $event) {
            event {
                id
            }
        }
    }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UploadEventImagesDocument = gql`
    mutation UploadEventImages($event: UploadEventImagesInput!) {
        uploadEventImages(event: $event) {
            event {
                id
                images {
                    edges {
                        node {
                            id
                            order
                            image {
                                id
                                url
                                extension
                                blurhash {
                                    hash
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export type UploadEventImagesMutationFn = Apollo.MutationFunction<
    UploadEventImagesMutation,
    UploadEventImagesMutationVariables
>;

/**
 * __useUploadEventImagesMutation__
 *
 * To run a mutation, you first call `useUploadEventImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEventImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEventImagesMutation, { data, loading, error }] = useUploadEventImagesMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUploadEventImagesMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadEventImagesMutation, UploadEventImagesMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadEventImagesMutation, UploadEventImagesMutationVariables>(
        UploadEventImagesDocument,
        options,
    );
}
export type UploadEventImagesMutationHookResult = ReturnType<typeof useUploadEventImagesMutation>;
export type UploadEventImagesMutationResult = Apollo.MutationResult<UploadEventImagesMutation>;
export type UploadEventImagesMutationOptions = Apollo.BaseMutationOptions<
    UploadEventImagesMutation,
    UploadEventImagesMutationVariables
>;
export const AcceptFollowDocument = gql`
    mutation AcceptFollow($input: AcceptFollowInput!) {
        acceptFollow(input: $input) {
            follow {
                id
                accepted
            }
        }
    }
`;
export type AcceptFollowMutationFn = Apollo.MutationFunction<AcceptFollowMutation, AcceptFollowMutationVariables>;

/**
 * __useAcceptFollowMutation__
 *
 * To run a mutation, you first call `useAcceptFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFollowMutation, { data, loading, error }] = useAcceptFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptFollowMutation, AcceptFollowMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AcceptFollowMutation, AcceptFollowMutationVariables>(AcceptFollowDocument, options);
}
export type AcceptFollowMutationHookResult = ReturnType<typeof useAcceptFollowMutation>;
export type AcceptFollowMutationResult = Apollo.MutationResult<AcceptFollowMutation>;
export type AcceptFollowMutationOptions = Apollo.BaseMutationOptions<
    AcceptFollowMutation,
    AcceptFollowMutationVariables
>;
export const CancelFollowDocument = gql`
    mutation CancelFollow($input: CancelFollowInput!) {
        cancelFollow(input: $input)
    }
`;
export type CancelFollowMutationFn = Apollo.MutationFunction<CancelFollowMutation, CancelFollowMutationVariables>;

/**
 * __useCancelFollowMutation__
 *
 * To run a mutation, you first call `useCancelFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFollowMutation, { data, loading, error }] = useCancelFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelFollowMutation, CancelFollowMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelFollowMutation, CancelFollowMutationVariables>(CancelFollowDocument, options);
}
export type CancelFollowMutationHookResult = ReturnType<typeof useCancelFollowMutation>;
export type CancelFollowMutationResult = Apollo.MutationResult<CancelFollowMutation>;
export type CancelFollowMutationOptions = Apollo.BaseMutationOptions<
    CancelFollowMutation,
    CancelFollowMutationVariables
>;
export const FollowDocument = gql`
    mutation Follow($input: FollowUserInput!) {
        followUser(input: $input) {
            follow {
                id
            }
        }
    }
`;
export type FollowMutationFn = Apollo.MutationFunction<FollowMutation, FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowMutation(baseOptions?: Apollo.MutationHookOptions<FollowMutation, FollowMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<FollowMutation, FollowMutationVariables>(FollowDocument, options);
}
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<FollowMutation, FollowMutationVariables>;
export const RejectFollowDocument = gql`
    mutation RejectFollow($input: RejectFollowInput!) {
        rejectFollow(input: $input) {
            follow {
                id
                accepted
            }
        }
    }
`;
export type RejectFollowMutationFn = Apollo.MutationFunction<RejectFollowMutation, RejectFollowMutationVariables>;

/**
 * __useRejectFollowMutation__
 *
 * To run a mutation, you first call `useRejectFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectFollowMutation, { data, loading, error }] = useRejectFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectFollowMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectFollowMutation, RejectFollowMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RejectFollowMutation, RejectFollowMutationVariables>(RejectFollowDocument, options);
}
export type RejectFollowMutationHookResult = ReturnType<typeof useRejectFollowMutation>;
export type RejectFollowMutationResult = Apollo.MutationResult<RejectFollowMutation>;
export type RejectFollowMutationOptions = Apollo.BaseMutationOptions<
    RejectFollowMutation,
    RejectFollowMutationVariables
>;
export const RemoveFollowerDocument = gql`
    mutation RemoveFollower($input: RemoveFollowerInput!) {
        removeFollower(input: $input)
    }
`;
export type RemoveFollowerMutationFn = Apollo.MutationFunction<RemoveFollowerMutation, RemoveFollowerMutationVariables>;

/**
 * __useRemoveFollowerMutation__
 *
 * To run a mutation, you first call `useRemoveFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFollowerMutation, { data, loading, error }] = useRemoveFollowerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFollowerMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveFollowerMutation, RemoveFollowerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveFollowerMutation, RemoveFollowerMutationVariables>(RemoveFollowerDocument, options);
}
export type RemoveFollowerMutationHookResult = ReturnType<typeof useRemoveFollowerMutation>;
export type RemoveFollowerMutationResult = Apollo.MutationResult<RemoveFollowerMutation>;
export type RemoveFollowerMutationOptions = Apollo.BaseMutationOptions<
    RemoveFollowerMutation,
    RemoveFollowerMutationVariables
>;
export const UnfollowDocument = gql`
    mutation Unfollow($input: UnfollowInput!) {
        unfollow(input: $input)
    }
`;
export type UnfollowMutationFn = Apollo.MutationFunction<UnfollowMutation, UnfollowMutationVariables>;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowMutation(
    baseOptions?: Apollo.MutationHookOptions<UnfollowMutation, UnfollowMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UnfollowMutation, UnfollowMutationVariables>(UnfollowDocument, options);
}
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<UnfollowMutation, UnfollowMutationVariables>;
export const DeleteManagerDocument = gql`
    mutation DeleteManager($input: DeleteManagerInput!) {
        deleteManager(input: $input) {
            id
        }
    }
`;
export type DeleteManagerMutationFn = Apollo.MutationFunction<DeleteManagerMutation, DeleteManagerMutationVariables>;

/**
 * __useDeleteManagerMutation__
 *
 * To run a mutation, you first call `useDeleteManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagerMutation, { data, loading, error }] = useDeleteManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteManagerMutation, DeleteManagerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteManagerMutation, DeleteManagerMutationVariables>(DeleteManagerDocument, options);
}
export type DeleteManagerMutationHookResult = ReturnType<typeof useDeleteManagerMutation>;
export type DeleteManagerMutationResult = Apollo.MutationResult<DeleteManagerMutation>;
export type DeleteManagerMutationOptions = Apollo.BaseMutationOptions<
    DeleteManagerMutation,
    DeleteManagerMutationVariables
>;
export const RegisterManagerDocument = gql`
    mutation RegisterManager($manager: RegisterManagerInput!) {
        registerManager(manager: $manager) {
            id
            user {
                id
                fullName
                avatar {
                    id
                    url
                }
            }
        }
    }
`;
export type RegisterManagerMutationFn = Apollo.MutationFunction<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;

/**
 * __useRegisterManagerMutation__
 *
 * To run a mutation, you first call `useRegisterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerManagerMutation, { data, loading, error }] = useRegisterManagerMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useRegisterManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterManagerMutation, RegisterManagerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterManagerMutation, RegisterManagerMutationVariables>(
        RegisterManagerDocument,
        options,
    );
}
export type RegisterManagerMutationHookResult = ReturnType<typeof useRegisterManagerMutation>;
export type RegisterManagerMutationResult = Apollo.MutationResult<RegisterManagerMutation>;
export type RegisterManagerMutationOptions = Apollo.BaseMutationOptions<
    RegisterManagerMutation,
    RegisterManagerMutationVariables
>;
export const UpdateManagerDocument = gql`
    mutation UpdateManager($manager: UpdateManagerInput!) {
        updateManager(manager: $manager) {
            manager {
                id
                user {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    bio
                    dateOfBirth
                    gender
                }
            }
        }
    }
`;
export type UpdateManagerMutationFn = Apollo.MutationFunction<UpdateManagerMutation, UpdateManagerMutationVariables>;

/**
 * __useUpdateManagerMutation__
 *
 * To run a mutation, you first call `useUpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerMutation, { data, loading, error }] = useUpdateManagerMutation({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useUpdateManagerMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateManagerMutation, UpdateManagerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateManagerMutation, UpdateManagerMutationVariables>(UpdateManagerDocument, options);
}
export type UpdateManagerMutationHookResult = ReturnType<typeof useUpdateManagerMutation>;
export type UpdateManagerMutationResult = Apollo.MutationResult<UpdateManagerMutation>;
export type UpdateManagerMutationOptions = Apollo.BaseMutationOptions<
    UpdateManagerMutation,
    UpdateManagerMutationVariables
>;
export const CreateMemberProfileDocument = gql`
    mutation CreateMemberProfile($memberProfile: CreateMemberProfileInput!) {
        createMemberProfile(memberProfile: $memberProfile) {
            memberProfile {
                id
            }
        }
    }
`;
export type CreateMemberProfileMutationFn = Apollo.MutationFunction<
    CreateMemberProfileMutation,
    CreateMemberProfileMutationVariables
>;

/**
 * __useCreateMemberProfileMutation__
 *
 * To run a mutation, you first call `useCreateMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberProfileMutation, { data, loading, error }] = useCreateMemberProfileMutation({
 *   variables: {
 *      memberProfile: // value for 'memberProfile'
 *   },
 * });
 */
export function useCreateMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateMemberProfileMutation, CreateMemberProfileMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMemberProfileMutation, CreateMemberProfileMutationVariables>(
        CreateMemberProfileDocument,
        options,
    );
}
export type CreateMemberProfileMutationHookResult = ReturnType<typeof useCreateMemberProfileMutation>;
export type CreateMemberProfileMutationResult = Apollo.MutationResult<CreateMemberProfileMutation>;
export type CreateMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    CreateMemberProfileMutation,
    CreateMemberProfileMutationVariables
>;
export const DeleteMemberProfileDocument = gql`
    mutation DeleteMemberProfile($id: String!) {
        deleteMemberProfile(id: $id) {
            firstName
        }
    }
`;
export type DeleteMemberProfileMutationFn = Apollo.MutationFunction<
    DeleteMemberProfileMutation,
    DeleteMemberProfileMutationVariables
>;

/**
 * __useDeleteMemberProfileMutation__
 *
 * To run a mutation, you first call `useDeleteMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberProfileMutation, { data, loading, error }] = useDeleteMemberProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteMemberProfileMutation, DeleteMemberProfileMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteMemberProfileMutation, DeleteMemberProfileMutationVariables>(
        DeleteMemberProfileDocument,
        options,
    );
}
export type DeleteMemberProfileMutationHookResult = ReturnType<typeof useDeleteMemberProfileMutation>;
export type DeleteMemberProfileMutationResult = Apollo.MutationResult<DeleteMemberProfileMutation>;
export type DeleteMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    DeleteMemberProfileMutation,
    DeleteMemberProfileMutationVariables
>;
export const UpdateMemberProfileDocument = gql`
    mutation UpdateMemberProfile($memberProfile: UpdateMemberProfileInput!) {
        updateMemberProfile(memberProfile: $memberProfile) {
            memberProfile {
                ...MemberProfileParts
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;
export type UpdateMemberProfileMutationFn = Apollo.MutationFunction<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
>;

/**
 * __useUpdateMemberProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberProfileMutation, { data, loading, error }] = useUpdateMemberProfileMutation({
 *   variables: {
 *      memberProfile: // value for 'memberProfile'
 *   },
 * });
 */
export function useUpdateMemberProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateMemberProfileMutation, UpdateMemberProfileMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateMemberProfileMutation, UpdateMemberProfileMutationVariables>(
        UpdateMemberProfileDocument,
        options,
    );
}
export type UpdateMemberProfileMutationHookResult = ReturnType<typeof useUpdateMemberProfileMutation>;
export type UpdateMemberProfileMutationResult = Apollo.MutationResult<UpdateMemberProfileMutation>;
export type UpdateMemberProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateMemberProfileMutation,
    UpdateMemberProfileMutationVariables
>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($member: UpdateMemberInput!) {
        updateMember(member: $member) {
            member {
                id
                maritalStatus
                cityOfResidence
                countryOfResidence {
                    id
                    name
                }
                countryOfResidenceId
                user {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    dateOfBirth
                    incognito
                    nickname
                }
            }
        }
    }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useUpdateMemberMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
}
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
    UpdateMemberMutation,
    UpdateMemberMutationVariables
>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($input: AddPaymentMethodInput!) {
        addPaymentMethod(input: $input) {
            paymentMethod {
                id
                brand
                cartToken
                funding
                lastFour
                expYear
                expMonth
                isDefault
            }
        }
    }
`;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(
        AddPaymentMethodDocument,
        options,
    );
}
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription {
        cancelSubscription
    }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(
        CancelSubscriptionDocument,
        options,
    );
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($id: String!) {
        deletePaymentMethod(id: $id)
    }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(
        DeletePaymentMethodDocument,
        options,
    );
}
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
>;
export const SetDefaultPaymentMethodDocument = gql`
    mutation setDefaultPaymentMethod($paymentMethodId: String!) {
        setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
            paymentMethodId
        }
    }
`;
export type SetDefaultPaymentMethodMutationFn = Apollo.MutationFunction<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
>;

/**
 * __useSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentMethodMutation, { data, loading, error }] = useSetDefaultPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetDefaultPaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>(
        SetDefaultPaymentMethodDocument,
        options,
    );
}
export type SetDefaultPaymentMethodMutationHookResult = ReturnType<typeof useSetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationResult = Apollo.MutationResult<SetDefaultPaymentMethodMutation>;
export type SetDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
>;
export const SubscribeDocument = gql`
    mutation subscribe($input: SubscriptionInput!) {
        subscription(input: $input) {
            result
        }
    }
`;
export type SubscribeMutationFn = Apollo.MutationFunction<SubscribeMutation, SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
    baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, options);
}
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const BookmarkPostDocument = gql`
    mutation BookmarkPost($post: BookmarkPostInput!) {
        bookmarkPost(post: $post) {
            post {
                id
                isBookmarked
            }
        }
    }
`;
export type BookmarkPostMutationFn = Apollo.MutationFunction<BookmarkPostMutation, BookmarkPostMutationVariables>;

/**
 * __useBookmarkPostMutation__
 *
 * To run a mutation, you first call `useBookmarkPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkPostMutation, { data, loading, error }] = useBookmarkPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useBookmarkPostMutation(
    baseOptions?: Apollo.MutationHookOptions<BookmarkPostMutation, BookmarkPostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<BookmarkPostMutation, BookmarkPostMutationVariables>(BookmarkPostDocument, options);
}
export type BookmarkPostMutationHookResult = ReturnType<typeof useBookmarkPostMutation>;
export type BookmarkPostMutationResult = Apollo.MutationResult<BookmarkPostMutation>;
export type BookmarkPostMutationOptions = Apollo.BaseMutationOptions<
    BookmarkPostMutation,
    BookmarkPostMutationVariables
>;
export const CreatePostCommentDocument = gql`
    mutation CreatePostComment($comment: CreatePostCommentInput!) {
        createPostComment(comment: $comment) {
            comment {
                id
                text
                userId
                createdAt
                author {
                    id
                    fullName
                    email
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;
export type CreatePostCommentMutationFn = Apollo.MutationFunction<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
>;

/**
 * __useCreatePostCommentMutation__
 *
 * To run a mutation, you first call `useCreatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCommentMutation, { data, loading, error }] = useCreatePostCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreatePostCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostCommentMutation, CreatePostCommentMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePostCommentMutation, CreatePostCommentMutationVariables>(
        CreatePostCommentDocument,
        options,
    );
}
export type CreatePostCommentMutationHookResult = ReturnType<typeof useCreatePostCommentMutation>;
export type CreatePostCommentMutationResult = Apollo.MutationResult<CreatePostCommentMutation>;
export type CreatePostCommentMutationOptions = Apollo.BaseMutationOptions<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
>;
export const CreatePostDocument = gql`
    mutation CreatePost($post: CreatePostInput!) {
        createPost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useCreatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
}
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const DeletePostCommentDocument = gql`
    mutation DeletePostComment($id: String!) {
        deletePostComment(id: $id) {
            comment {
                id
            }
        }
    }
`;
export type DeletePostCommentMutationFn = Apollo.MutationFunction<
    DeletePostCommentMutation,
    DeletePostCommentMutationVariables
>;

/**
 * __useDeletePostCommentMutation__
 *
 * To run a mutation, you first call `useDeletePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostCommentMutation, { data, loading, error }] = useDeletePostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePostCommentMutation, DeletePostCommentMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePostCommentMutation, DeletePostCommentMutationVariables>(
        DeletePostCommentDocument,
        options,
    );
}
export type DeletePostCommentMutationHookResult = ReturnType<typeof useDeletePostCommentMutation>;
export type DeletePostCommentMutationResult = Apollo.MutationResult<DeletePostCommentMutation>;
export type DeletePostCommentMutationOptions = Apollo.BaseMutationOptions<
    DeletePostCommentMutation,
    DeletePostCommentMutationVariables
>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: String!) {
        deletePost(id: $id) {
            id
        }
    }
`;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(
    baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
}
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DislikePostDocument = gql`
    mutation DislikePost($post: DislikePostInput!) {
        dislikePost(post: $post) {
            likeCount
        }
    }
`;
export type DislikePostMutationFn = Apollo.MutationFunction<DislikePostMutation, DislikePostMutationVariables>;

/**
 * __useDislikePostMutation__
 *
 * To run a mutation, you first call `useDislikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDislikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dislikePostMutation, { data, loading, error }] = useDislikePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useDislikePostMutation(
    baseOptions?: Apollo.MutationHookOptions<DislikePostMutation, DislikePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DislikePostMutation, DislikePostMutationVariables>(DislikePostDocument, options);
}
export type DislikePostMutationHookResult = ReturnType<typeof useDislikePostMutation>;
export type DislikePostMutationResult = Apollo.MutationResult<DislikePostMutation>;
export type DislikePostMutationOptions = Apollo.BaseMutationOptions<DislikePostMutation, DislikePostMutationVariables>;
export const LikePostDocument = gql`
    mutation LikePost($post: LikePostInput!) {
        likePost(post: $post) {
            likeCount
        }
    }
`;
export type LikePostMutationFn = Apollo.MutationFunction<LikePostMutation, LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useLikePostMutation(
    baseOptions?: Apollo.MutationHookOptions<LikePostMutation, LikePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LikePostMutation, LikePostMutationVariables>(LikePostDocument, options);
}
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = Apollo.MutationResult<LikePostMutation>;
export type LikePostMutationOptions = Apollo.BaseMutationOptions<LikePostMutation, LikePostMutationVariables>;
export const SharePostDocument = gql`
    mutation SharePost($post: SharePostInput!) {
        sharePost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type SharePostMutationFn = Apollo.MutationFunction<SharePostMutation, SharePostMutationVariables>;

/**
 * __useSharePostMutation__
 *
 * To run a mutation, you first call `useSharePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePostMutation, { data, loading, error }] = useSharePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useSharePostMutation(
    baseOptions?: Apollo.MutationHookOptions<SharePostMutation, SharePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SharePostMutation, SharePostMutationVariables>(SharePostDocument, options);
}
export type SharePostMutationHookResult = ReturnType<typeof useSharePostMutation>;
export type SharePostMutationResult = Apollo.MutationResult<SharePostMutation>;
export type SharePostMutationOptions = Apollo.BaseMutationOptions<SharePostMutation, SharePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($post: UpdatePostInput!) {
        updatePost(post: $post) {
            post {
                ...PostParts
                meta {
                    ... on RepostMeta {
                        post {
                            ...PostParts
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useUpdatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
}
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($input: UploadUserAvatarInput!) {
        uploadUserAvatar(input: $input) {
            user {
                ...UserParts
            }
        }
    }
    ${UserPartsFragmentDoc}
`;
export type UploadUserAvatarMutationFn = Apollo.MutationFunction<
    UploadUserAvatarMutation,
    UploadUserAvatarMutationVariables
>;

/**
 * __useUploadUserAvatarMutation__
 *
 * To run a mutation, you first call `useUploadUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserAvatarMutation, { data, loading, error }] = useUploadUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>(
        UploadUserAvatarDocument,
        options,
    );
}
export type UploadUserAvatarMutationHookResult = ReturnType<typeof useUploadUserAvatarMutation>;
export type UploadUserAvatarMutationResult = Apollo.MutationResult<UploadUserAvatarMutation>;
export type UploadUserAvatarMutationOptions = Apollo.BaseMutationOptions<
    UploadUserAvatarMutation,
    UploadUserAvatarMutationVariables
>;
export const UploadUserCoverPhotoDocument = gql`
    mutation UploadUserCoverPhoto($input: UploadUserCoverPhotoInput!) {
        uploadUserCoverPhoto(input: $input) {
            user {
                ...UserParts
            }
        }
    }
    ${UserPartsFragmentDoc}
`;
export type UploadUserCoverPhotoMutationFn = Apollo.MutationFunction<
    UploadUserCoverPhotoMutation,
    UploadUserCoverPhotoMutationVariables
>;

/**
 * __useUploadUserCoverPhotoMutation__
 *
 * To run a mutation, you first call `useUploadUserCoverPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserCoverPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserCoverPhotoMutation, { data, loading, error }] = useUploadUserCoverPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserCoverPhotoMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadUserCoverPhotoMutation, UploadUserCoverPhotoMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadUserCoverPhotoMutation, UploadUserCoverPhotoMutationVariables>(
        UploadUserCoverPhotoDocument,
        options,
    );
}
export type UploadUserCoverPhotoMutationHookResult = ReturnType<typeof useUploadUserCoverPhotoMutation>;
export type UploadUserCoverPhotoMutationResult = Apollo.MutationResult<UploadUserCoverPhotoMutation>;
export type UploadUserCoverPhotoMutationOptions = Apollo.BaseMutationOptions<
    UploadUserCoverPhotoMutation,
    UploadUserCoverPhotoMutationVariables
>;
export const ActivityCountDocument = gql`
    query ActivityCount {
        activityCount
    }
`;

/**
 * __useActivityCountQuery__
 *
 * To run a query within a React component, call `useActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityCountQuery(
    baseOptions?: Apollo.QueryHookOptions<ActivityCountQuery, ActivityCountQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ActivityCountQuery, ActivityCountQueryVariables>(ActivityCountDocument, options);
}
export function useActivityCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivityCountQuery, ActivityCountQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ActivityCountQuery, ActivityCountQueryVariables>(ActivityCountDocument, options);
}
export type ActivityCountQueryHookResult = ReturnType<typeof useActivityCountQuery>;
export type ActivityCountLazyQueryHookResult = ReturnType<typeof useActivityCountLazyQuery>;
export type ActivityCountQueryResult = Apollo.QueryResult<ActivityCountQuery, ActivityCountQueryVariables>;
export const ChatableUsersDocument = gql`
    query ChatableUsers($page: Int, $where: ChatableUsersWhereInput) {
        chatableUsers(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                    matrixAuth {
                        access_token
                        device_id
                        home_server
                        user_id
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useChatableUsersQuery__
 *
 * To run a query within a React component, call `useChatableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatableUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChatableUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, options);
}
export function useChatableUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, options);
}
export type ChatableUsersQueryHookResult = ReturnType<typeof useChatableUsersQuery>;
export type ChatableUsersLazyQueryHookResult = ReturnType<typeof useChatableUsersLazyQuery>;
export type ChatableUsersQueryResult = Apollo.QueryResult<ChatableUsersQuery, ChatableUsersQueryVariables>;
export const CitiesDocument = gql`
    query Cities {
        cities {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
}
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CommunitiesDocument = gql`
    query Communities($page: Int, $where: CommunityWhereInput) {
        communities(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    category
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                                communityId
                            }
                        }
                    }
                    joinRule
                    images {
                        edges {
                            node {
                                id
                                url
                                extension
                            }
                        }
                    }
                    logo {
                        id
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunitiesQuery__
 *
 * To run a query within a React component, call `useCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCommunitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, options);
}
export function useCommunitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunitiesQuery, CommunitiesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommunitiesQuery, CommunitiesQueryVariables>(CommunitiesDocument, options);
}
export type CommunitiesQueryHookResult = ReturnType<typeof useCommunitiesQuery>;
export type CommunitiesLazyQueryHookResult = ReturnType<typeof useCommunitiesLazyQuery>;
export type CommunitiesQueryResult = Apollo.QueryResult<CommunitiesQuery, CommunitiesQueryVariables>;
export const CommunityInvitationDocument = gql`
    query CommunityInvitation($communityInvitationId: String!) {
        communityInvitation(id: $communityInvitationId) {
            id
            usageLimit
            usedCount
            expiresAt
            community {
                id
                translations {
                    edges {
                        node {
                            id
                            lang
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityInvitationQuery__
 *
 * To run a query within a React component, call `useCommunityInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityInvitationQuery({
 *   variables: {
 *      communityInvitationId: // value for 'communityInvitationId'
 *   },
 * });
 */
export function useCommunityInvitationQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityInvitationQuery, CommunityInvitationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommunityInvitationQuery, CommunityInvitationQueryVariables>(
        CommunityInvitationDocument,
        options,
    );
}
export function useCommunityInvitationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityInvitationQuery, CommunityInvitationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommunityInvitationQuery, CommunityInvitationQueryVariables>(
        CommunityInvitationDocument,
        options,
    );
}
export type CommunityInvitationQueryHookResult = ReturnType<typeof useCommunityInvitationQuery>;
export type CommunityInvitationLazyQueryHookResult = ReturnType<typeof useCommunityInvitationLazyQuery>;
export type CommunityInvitationQueryResult = Apollo.QueryResult<
    CommunityInvitationQuery,
    CommunityInvitationQueryVariables
>;
export const CommunityMembersDocument = gql`
    query CommunityMembers($where: CommunityMembersWhereInput!, $page: Int) {
        communityMembers(where: $where, page: $page) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    status
                    createdAt
                    user {
                        id
                        firstName
                        lastName
                        bio
                        email
                        phoneNumber
                        dateOfBirth
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityMembersQuery__
 *
 * To run a query within a React component, call `useCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMembersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCommunityMembersQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityMembersQuery, CommunityMembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommunityMembersQuery, CommunityMembersQueryVariables>(CommunityMembersDocument, options);
}
export function useCommunityMembersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityMembersQuery, CommunityMembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommunityMembersQuery, CommunityMembersQueryVariables>(
        CommunityMembersDocument,
        options,
    );
}
export type CommunityMembersQueryHookResult = ReturnType<typeof useCommunityMembersQuery>;
export type CommunityMembersLazyQueryHookResult = ReturnType<typeof useCommunityMembersLazyQuery>;
export type CommunityMembersQueryResult = Apollo.QueryResult<CommunityMembersQuery, CommunityMembersQueryVariables>;
export const CommunityPostsWithImagesDocument = gql`
    query CommunityPostsWithImages($where: PostWhereInput!, $page: Int) {
        communityPostsWithImages(where: $where, page: $page) {
            edges {
                node {
                    id
                    files {
                        totalCount
                        edges {
                            node {
                                id
                                blurhash {
                                    hash
                                }
                                url
                                extension
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityPostsWithImagesQuery__
 *
 * To run a query within a React component, call `useCommunityPostsWithImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPostsWithImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPostsWithImagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCommunityPostsWithImagesQuery(
    baseOptions: Apollo.QueryHookOptions<CommunityPostsWithImagesQuery, CommunityPostsWithImagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommunityPostsWithImagesQuery, CommunityPostsWithImagesQueryVariables>(
        CommunityPostsWithImagesDocument,
        options,
    );
}
export function useCommunityPostsWithImagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityPostsWithImagesQuery, CommunityPostsWithImagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommunityPostsWithImagesQuery, CommunityPostsWithImagesQueryVariables>(
        CommunityPostsWithImagesDocument,
        options,
    );
}
export type CommunityPostsWithImagesQueryHookResult = ReturnType<typeof useCommunityPostsWithImagesQuery>;
export type CommunityPostsWithImagesLazyQueryHookResult = ReturnType<typeof useCommunityPostsWithImagesLazyQuery>;
export type CommunityPostsWithImagesQueryResult = Apollo.QueryResult<
    CommunityPostsWithImagesQuery,
    CommunityPostsWithImagesQueryVariables
>;
export const CommunityDocument = gql`
    query Community($id: String!) {
        community(id: $id) {
            id
            translations {
                edges {
                    node {
                        id
                        lang
                        name
                        description
                        location
                    }
                }
            }
            membershipStatus
            joinRule
            category
            isInternal
            memberCount
            city {
                id
                name
            }
            benefits {
                text
                icon
            }
            managers {
                edges {
                    node {
                        id
                        user {
                            id
                            fullName
                            avatar {
                                id
                                url
                            }
                        }
                    }
                }
            }
            invitations {
                edges {
                    node {
                        id
                        usageLimit
                        usedCount
                        expiresAt
                    }
                }
            }
            logo {
                id
                url
            }
            images {
                edges {
                    node {
                        id
                        url
                        extension
                        blurhash {
                            hash
                            width
                            height
                        }
                    }
                }
            }
            managers {
                edges {
                    node {
                        userId
                    }
                }
            }
        }
    }
`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityQuery(baseOptions: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
}
export function useCommunityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
}
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const PartnersDocument = gql`
    query Partners($page: Int, $where: PartnerWhereInput) {
        partners(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    category
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                                communityId
                            }
                        }
                    }
                    joinRule
                    images {
                        edges {
                            node {
                                id
                                url
                                extension
                            }
                        }
                    }
                    logo {
                        id
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __usePartnersQuery__
 *
 * To run a query within a React component, call `usePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePartnersQuery(baseOptions?: Apollo.QueryHookOptions<PartnersQuery, PartnersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export function usePartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnersQuery, PartnersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export type PartnersQueryHookResult = ReturnType<typeof usePartnersQuery>;
export type PartnersLazyQueryHookResult = ReturnType<typeof usePartnersLazyQuery>;
export type PartnersQueryResult = Apollo.QueryResult<PartnersQuery, PartnersQueryVariables>;
export const PendingMembershipsDocument = gql`
    query PendingMemberships($where: PendingMembershipWhereInput!, $page: Int, $pageSize: Int) {
        pendingMemberships(where: $where, page: $page, pageSize: $pageSize) {
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    id
                    member {
                        id
                        user {
                            id
                            fullName
                            avatar {
                                id
                                url
                            }
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

/**
 * __usePendingMembershipsQuery__
 *
 * To run a query within a React component, call `usePendingMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingMembershipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePendingMembershipsQuery(
    baseOptions: Apollo.QueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(
        PendingMembershipsDocument,
        options,
    );
}
export function usePendingMembershipsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(
        PendingMembershipsDocument,
        options,
    );
}
export type PendingMembershipsQueryHookResult = ReturnType<typeof usePendingMembershipsQuery>;
export type PendingMembershipsLazyQueryHookResult = ReturnType<typeof usePendingMembershipsLazyQuery>;
export type PendingMembershipsQueryResult = Apollo.QueryResult<
    PendingMembershipsQuery,
    PendingMembershipsQueryVariables
>;
export const UserCommunitiesDocument = gql`
    query UserCommunities {
        me {
            id
            communities {
                edges {
                    node {
                        id
                        memberCount
                        pendingMembershipsCount
                        translations {
                            edges {
                                node {
                                    id
                                    name
                                    location
                                }
                            }
                        }
                        city {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useUserCommunitiesQuery__
 *
 * To run a query within a React component, call `useUserCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCommunitiesQuery(
    baseOptions?: Apollo.QueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(UserCommunitiesDocument, options);
}
export function useUserCommunitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserCommunitiesQuery, UserCommunitiesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserCommunitiesQuery, UserCommunitiesQueryVariables>(UserCommunitiesDocument, options);
}
export type UserCommunitiesQueryHookResult = ReturnType<typeof useUserCommunitiesQuery>;
export type UserCommunitiesLazyQueryHookResult = ReturnType<typeof useUserCommunitiesLazyQuery>;
export type UserCommunitiesQueryResult = Apollo.QueryResult<UserCommunitiesQuery, UserCommunitiesQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
        countries {
            edges {
                node {
                    id
                    code
                    name
                }
            }
        }
    }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export function useCountriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const BookmarkedEventsDocument = gql`
    query BookmarkedEvents($page: Int, $where: EventWhereInput) {
        bookmarkedEvents(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useBookmarkedEventsQuery__
 *
 * To run a query within a React component, call `useBookmarkedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBookmarkedEventsQuery(
    baseOptions?: Apollo.QueryHookOptions<BookmarkedEventsQuery, BookmarkedEventsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookmarkedEventsQuery, BookmarkedEventsQueryVariables>(BookmarkedEventsDocument, options);
}
export function useBookmarkedEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookmarkedEventsQuery, BookmarkedEventsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookmarkedEventsQuery, BookmarkedEventsQueryVariables>(
        BookmarkedEventsDocument,
        options,
    );
}
export type BookmarkedEventsQueryHookResult = ReturnType<typeof useBookmarkedEventsQuery>;
export type BookmarkedEventsLazyQueryHookResult = ReturnType<typeof useBookmarkedEventsLazyQuery>;
export type BookmarkedEventsQueryResult = Apollo.QueryResult<BookmarkedEventsQuery, BookmarkedEventsQueryVariables>;
export const BookmarkedExperiencesDocument = gql`
    query BookmarkedExperiences($page: Int, $where: EventWhereInput) {
        bookmarkedExperiences(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...ExperienceParts
                }
            }
        }
    }
    ${ExperiencePartsFragmentDoc}
`;

/**
 * __useBookmarkedExperiencesQuery__
 *
 * To run a query within a React component, call `useBookmarkedExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedExperiencesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBookmarkedExperiencesQuery(
    baseOptions?: Apollo.QueryHookOptions<BookmarkedExperiencesQuery, BookmarkedExperiencesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookmarkedExperiencesQuery, BookmarkedExperiencesQueryVariables>(
        BookmarkedExperiencesDocument,
        options,
    );
}
export function useBookmarkedExperiencesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookmarkedExperiencesQuery, BookmarkedExperiencesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookmarkedExperiencesQuery, BookmarkedExperiencesQueryVariables>(
        BookmarkedExperiencesDocument,
        options,
    );
}
export type BookmarkedExperiencesQueryHookResult = ReturnType<typeof useBookmarkedExperiencesQuery>;
export type BookmarkedExperiencesLazyQueryHookResult = ReturnType<typeof useBookmarkedExperiencesLazyQuery>;
export type BookmarkedExperiencesQueryResult = Apollo.QueryResult<
    BookmarkedExperiencesQuery,
    BookmarkedExperiencesQueryVariables
>;
export const EventDocument = gql`
    query Event($id: String!) {
        event(id: $id) {
            ...EventParts
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query Events($page: Int, $where: EventWhereInput) {
        events(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ExperienceDocument = gql`
    query Experience($id: String!) {
        experience(id: $id) {
            ...ExperienceParts
        }
    }
    ${ExperiencePartsFragmentDoc}
`;

/**
 * __useExperienceQuery__
 *
 * To run a query within a React component, call `useExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceQuery(baseOptions: Apollo.QueryHookOptions<ExperienceQuery, ExperienceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, options);
}
export function useExperienceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExperienceQuery, ExperienceQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, options);
}
export type ExperienceQueryHookResult = ReturnType<typeof useExperienceQuery>;
export type ExperienceLazyQueryHookResult = ReturnType<typeof useExperienceLazyQuery>;
export type ExperienceQueryResult = Apollo.QueryResult<ExperienceQuery, ExperienceQueryVariables>;
export const ExperiencesDocument = gql`
    query Experiences($page: Int, $where: EventWhereInput) {
        experiences(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...ExperienceParts
                }
            }
        }
    }
    ${ExperiencePartsFragmentDoc}
`;

/**
 * __useExperiencesQuery__
 *
 * To run a query within a React component, call `useExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExperiencesQuery(
    baseOptions?: Apollo.QueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExperiencesQuery, ExperiencesQueryVariables>(ExperiencesDocument, options);
}
export function useExperiencesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExperiencesQuery, ExperiencesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExperiencesQuery, ExperiencesQueryVariables>(ExperiencesDocument, options);
}
export type ExperiencesQueryHookResult = ReturnType<typeof useExperiencesQuery>;
export type ExperiencesLazyQueryHookResult = ReturnType<typeof useExperiencesLazyQuery>;
export type ExperiencesQueryResult = Apollo.QueryResult<ExperiencesQuery, ExperiencesQueryVariables>;
export const PastEventsDocument = gql`
    query PastEvents($page: Int, $where: EventWhereInput) {
        pastEvents(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __usePastEventsQuery__
 *
 * To run a query within a React component, call `usePastEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePastEventsQuery(baseOptions?: Apollo.QueryHookOptions<PastEventsQuery, PastEventsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PastEventsQuery, PastEventsQueryVariables>(PastEventsDocument, options);
}
export function usePastEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PastEventsQuery, PastEventsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PastEventsQuery, PastEventsQueryVariables>(PastEventsDocument, options);
}
export type PastEventsQueryHookResult = ReturnType<typeof usePastEventsQuery>;
export type PastEventsLazyQueryHookResult = ReturnType<typeof usePastEventsLazyQuery>;
export type PastEventsQueryResult = Apollo.QueryResult<PastEventsQuery, PastEventsQueryVariables>;
export const PlansDocument = gql`
    query plans {
        plans {
            edges {
                node {
                    id
                    code
                    price
                    benefits
                }
            }
        }
    }
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
}
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const PostForEventDocument = gql`
    query PostForEvent($eventId: String!, $eventType: String!) {
        postForEvent(eventId: $eventId, eventType: $eventType) {
            ...PostParts
            meta {
                ... on RepostMeta {
                    post {
                        ...PostParts
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __usePostForEventQuery__
 *
 * To run a query within a React component, call `usePostForEventQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostForEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostForEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function usePostForEventQuery(
    baseOptions: Apollo.QueryHookOptions<PostForEventQuery, PostForEventQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PostForEventQuery, PostForEventQueryVariables>(PostForEventDocument, options);
}
export function usePostForEventLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PostForEventQuery, PostForEventQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PostForEventQuery, PostForEventQueryVariables>(PostForEventDocument, options);
}
export type PostForEventQueryHookResult = ReturnType<typeof usePostForEventQuery>;
export type PostForEventLazyQueryHookResult = ReturnType<typeof usePostForEventLazyQuery>;
export type PostForEventQueryResult = Apollo.QueryResult<PostForEventQuery, PostForEventQueryVariables>;
export const UpcomingEventsDocument = gql`
    query UpcomingEvents($page: Int, $where: EventWhereInput) {
        upcomingEvents(page: $page, where: $where) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...EventParts
                }
            }
        }
    }
    ${EventPartsFragmentDoc}
`;

/**
 * __useUpcomingEventsQuery__
 *
 * To run a query within a React component, call `useUpcomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpcomingEventsQuery(
    baseOptions?: Apollo.QueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
}
export function useUpcomingEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
}
export type UpcomingEventsQueryHookResult = ReturnType<typeof useUpcomingEventsQuery>;
export type UpcomingEventsLazyQueryHookResult = ReturnType<typeof useUpcomingEventsLazyQuery>;
export type UpcomingEventsQueryResult = Apollo.QueryResult<UpcomingEventsQuery, UpcomingEventsQueryVariables>;
export const MeManagerDocument = gql`
    query MeManager {
        me {
            id
            firstName
            lastName
            email
            bio
            phoneNumber
            dateOfBirth
            gender
            incognito
            nickname
            avatar {
                id
                url
            }
        }
    }
`;

/**
 * __useMeManagerQuery__
 *
 * To run a query within a React component, call `useMeManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeManagerQuery(baseOptions?: Apollo.QueryHookOptions<MeManagerQuery, MeManagerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeManagerQuery, MeManagerQueryVariables>(MeManagerDocument, options);
}
export function useMeManagerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MeManagerQuery, MeManagerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeManagerQuery, MeManagerQueryVariables>(MeManagerDocument, options);
}
export type MeManagerQueryHookResult = ReturnType<typeof useMeManagerQuery>;
export type MeManagerLazyQueryHookResult = ReturnType<typeof useMeManagerLazyQuery>;
export type MeManagerQueryResult = Apollo.QueryResult<MeManagerQuery, MeManagerQueryVariables>;
export const MeDocument = gql`
    query Me {
        me {
            id
            email
            firstName
            lastName
            fullName
            avatar {
                id
                url
            }
            statusText
            role {
                id
                name
            }
            matrixAuth {
                access_token
                device_id
                home_server
                user_id
            }
            member {
                id
                status
            }
        }
    }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeMemberDocument = gql`
    query MeMember {
        me {
            subscription {
                id
                expiresAt
                plan {
                    id
                    code
                    benefits
                }
            }
            id
            incognito
            nickname
            avatar {
                id
                url
            }
            member {
                id
                maritalStatus
                cityOfResidence
                countryOfResidence {
                    id
                    name
                }
                countryOfResidenceId
                user {
                    id
                    firstName
                    lastName
                    bio
                    gender
                    email
                    phoneNumber
                    dateOfBirth
                    incognito
                    nickname
                }
                profiles {
                    edges {
                        node {
                            ...MemberProfileParts
                        }
                    }
                }
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;

/**
 * __useMeMemberQuery__
 *
 * To run a query within a React component, call `useMeMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeMemberQuery(baseOptions?: Apollo.QueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
}
export function useMeMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeMemberQuery, MeMemberQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeMemberQuery, MeMemberQueryVariables>(MeMemberDocument, options);
}
export type MeMemberQueryHookResult = ReturnType<typeof useMeMemberQuery>;
export type MeMemberLazyQueryHookResult = ReturnType<typeof useMeMemberLazyQuery>;
export type MeMemberQueryResult = Apollo.QueryResult<MeMemberQuery, MeMemberQueryVariables>;
export const MemberDocument = gql`
    query Member($id: String!) {
        member(id: $id) {
            id
            status
            maritalStatus
            cityOfResidence
            countryOfResidence {
                id
                name
            }
            user {
                id
                firstName
                lastName
                bio
                email
                phoneNumber
                dateOfBirth
            }
            profiles {
                edges {
                    node {
                        ...MemberProfileParts
                    }
                }
            }
        }
    }
    ${MemberProfilePartsFragmentDoc}
`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
}
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
}
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const MembersDocument = gql`
    query Members($where: MemberWhereInput!) {
        members(where: $where) {
            edges {
                node {
                    id
                    status
                    createdAt
                    user {
                        id
                        firstName
                        lastName
                        bio
                        email
                        phoneNumber
                        dateOfBirth
                    }
                }
            }
        }
    }
`;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
}
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
}
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods {
        paymentMethods {
            edges {
                node {
                    id
                    isDefault
                    lastFour
                    funding
                    expYear
                    expMonth
                    brand
                    cartToken
                }
            }
        }
    }
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
}
export function usePaymentMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
}
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const PostDetailDocument = gql`
    query PostDetail($id: String!) {
        post(id: $id) {
            ...PostParts
            meta {
                ... on RepostMeta {
                    post {
                        ...PostParts
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __usePostDetailQuery__
 *
 * To run a query within a React component, call `usePostDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostDetailQuery(baseOptions: Apollo.QueryHookOptions<PostDetailQuery, PostDetailQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PostDetailQuery, PostDetailQueryVariables>(PostDetailDocument, options);
}
export function usePostDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PostDetailQuery, PostDetailQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PostDetailQuery, PostDetailQueryVariables>(PostDetailDocument, options);
}
export type PostDetailQueryHookResult = ReturnType<typeof usePostDetailQuery>;
export type PostDetailLazyQueryHookResult = ReturnType<typeof usePostDetailLazyQuery>;
export type PostDetailQueryResult = Apollo.QueryResult<PostDetailQuery, PostDetailQueryVariables>;
export const PostLikesDocument = gql`
    query PostLikes($where: PostLikeWhereInput!, $page: Int) {
        postLikes(where: $where, page: $page) {
            edges {
                node {
                    id
                    createdAt
                    user {
                        id
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __usePostLikesQuery__
 *
 * To run a query within a React component, call `usePostLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostLikesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePostLikesQuery(baseOptions: Apollo.QueryHookOptions<PostLikesQuery, PostLikesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PostLikesQuery, PostLikesQueryVariables>(PostLikesDocument, options);
}
export function usePostLikesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PostLikesQuery, PostLikesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PostLikesQuery, PostLikesQueryVariables>(PostLikesDocument, options);
}
export type PostLikesQueryHookResult = ReturnType<typeof usePostLikesQuery>;
export type PostLikesLazyQueryHookResult = ReturnType<typeof usePostLikesLazyQuery>;
export type PostLikesQueryResult = Apollo.QueryResult<PostLikesQuery, PostLikesQueryVariables>;
export const PostDocument = gql`
    query Post($id: String!, $page: Int) {
        post(id: $id) {
            id
            visibility
            comments(page: $page) {
                edges {
                    node {
                        id
                        text
                        userId
                        createdAt
                        author {
                            id
                            fullName
                            role {
                                id
                                name
                            }
                            statusText
                            avatar {
                                id
                                url
                            }
                            member {
                                id
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options);
}
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options);
}
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const PostsDocument = gql`
    query Posts($page: Int, $where: PostWhereInput!) {
        posts(page: $page, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePostsQuery(baseOptions: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
}
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
}
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const RepostedUsersDocument = gql`
    query RepostedUsers($where: RepostedUserWhereInput!, $page: Int) {
        repostedUsers(where: $where, page: $page) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useRepostedUsersQuery__
 *
 * To run a query within a React component, call `useRepostedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepostedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepostedUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRepostedUsersQuery(
    baseOptions: Apollo.QueryHookOptions<RepostedUsersQuery, RepostedUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RepostedUsersQuery, RepostedUsersQueryVariables>(RepostedUsersDocument, options);
}
export function useRepostedUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RepostedUsersQuery, RepostedUsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RepostedUsersQuery, RepostedUsersQueryVariables>(RepostedUsersDocument, options);
}
export type RepostedUsersQueryHookResult = ReturnType<typeof useRepostedUsersQuery>;
export type RepostedUsersLazyQueryHookResult = ReturnType<typeof useRepostedUsersLazyQuery>;
export type RepostedUsersQueryResult = Apollo.QueryResult<RepostedUsersQuery, RepostedUsersQueryVariables>;
export const SavedPostsDocument = gql`
    query SavedPosts($page: Int, $where: PostWhereInput!) {
        bookmarkedPosts(page: $page, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __useSavedPostsQuery__
 *
 * To run a query within a React component, call `useSavedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSavedPostsQuery(baseOptions: Apollo.QueryHookOptions<SavedPostsQuery, SavedPostsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SavedPostsQuery, SavedPostsQueryVariables>(SavedPostsDocument, options);
}
export function useSavedPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SavedPostsQuery, SavedPostsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SavedPostsQuery, SavedPostsQueryVariables>(SavedPostsDocument, options);
}
export type SavedPostsQueryHookResult = ReturnType<typeof useSavedPostsQuery>;
export type SavedPostsLazyQueryHookResult = ReturnType<typeof useSavedPostsLazyQuery>;
export type SavedPostsQueryResult = Apollo.QueryResult<SavedPostsQuery, SavedPostsQueryVariables>;
export const UserPostsDocument = gql`
    query UserPosts($page: Int, $userId: String!, $where: PostWhereInput!) {
        userPosts(page: $page, userId: $userId, where: $where) {
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __useUserPostsQuery__
 *
 * To run a query within a React component, call `useUserPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      userId: // value for 'userId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserPostsQuery(baseOptions: Apollo.QueryHookOptions<UserPostsQuery, UserPostsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserPostsQuery, UserPostsQueryVariables>(UserPostsDocument, options);
}
export function useUserPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserPostsQuery, UserPostsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserPostsQuery, UserPostsQueryVariables>(UserPostsDocument, options);
}
export type UserPostsQueryHookResult = ReturnType<typeof useUserPostsQuery>;
export type UserPostsLazyQueryHookResult = ReturnType<typeof useUserPostsLazyQuery>;
export type UserPostsQueryResult = Apollo.QueryResult<UserPostsQuery, UserPostsQueryVariables>;
export const ReportsDocument = gql`
    query Reports {
        reports {
            edges {
                node {
                    code
                    embedUrl
                    id
                }
            }
        }
    }
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
}
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const SearchDocument = gql`
    query Search($keyword: String!, $communityId: String!) {
        users(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        url
                    }
                }
            }
        }
        events(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                lang
                                title
                                description
                                address
                            }
                        }
                    }
                    startTime
                    tags
                    type
                    price
                    purchased
                    images {
                        edges {
                            node {
                                id
                                order
                                image {
                                    id
                                    url
                                    name
                                    extension
                                    blurhash {
                                        hash
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        experiences(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    translations {
                        edges {
                            node {
                                id
                                lang
                                title
                                description
                                address
                            }
                        }
                    }
                    startTime
                    tags
                    type
                    price
                    purchased
                    images {
                        edges {
                            node {
                                id
                                order
                                image {
                                    id
                                    url
                                    name
                                    extension
                                    blurhash {
                                        hash
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        communities(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    logo {
                        id
                        url
                    }
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                            }
                        }
                    }
                    images {
                        edges {
                            node {
                                id
                                url
                                extension
                            }
                        }
                    }
                }
            }
        }
        partners(where: { keyword: $keyword }) {
            edges {
                node {
                    id
                    logo {
                        id
                        url
                    }
                    translations {
                        edges {
                            node {
                                id
                                lang
                                name
                                location
                                description
                            }
                        }
                    }
                    images {
                        edges {
                            node {
                                id
                                url
                                extension
                            }
                        }
                    }
                }
            }
        }
        posts(where: { keyword: $keyword, communityId: $communityId }) {
            edges {
                node {
                    ...PostParts
                    meta {
                        ... on RepostMeta {
                            post {
                                ...PostParts
                            }
                        }
                    }
                }
            }
        }
    }
    ${PostPartsFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ActivitiesDocument = gql`
    query Activities($page: Int) {
        activities(page: $page) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    type
                    createdAt
                    author {
                        id
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                    createdAt
                    meta {
                        post {
                            id
                            files {
                                edges {
                                    node {
                                        id
                                        extension
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
}
export function useActivitiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
}
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ActivitiesQuery, ActivitiesQueryVariables>;
export const ApprovedMembersDocument = gql`
    query ApprovedMembers {
        approvedMembers {
            edges {
                node {
                    id
                    fullName
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;

/**
 * __useApprovedMembersQuery__
 *
 * To run a query within a React component, call `useApprovedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useApprovedMembersQuery(
    baseOptions?: Apollo.QueryHookOptions<ApprovedMembersQuery, ApprovedMembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ApprovedMembersQuery, ApprovedMembersQueryVariables>(ApprovedMembersDocument, options);
}
export function useApprovedMembersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApprovedMembersQuery, ApprovedMembersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ApprovedMembersQuery, ApprovedMembersQueryVariables>(ApprovedMembersDocument, options);
}
export type ApprovedMembersQueryHookResult = ReturnType<typeof useApprovedMembersQuery>;
export type ApprovedMembersLazyQueryHookResult = ReturnType<typeof useApprovedMembersLazyQuery>;
export type ApprovedMembersQueryResult = Apollo.QueryResult<ApprovedMembersQuery, ApprovedMembersQueryVariables>;
export const FollowRequestsDocument = gql`
    query FollowRequests($page: Int, $where: FollowWhereInput) {
        followRequests(page: $page, where: $where) {
            edges {
                node {
                    id
                    follower {
                        id
                        fullName
                        avatar {
                            id
                            url
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
            totalCount
        }
    }
`;

/**
 * __useFollowRequestsQuery__
 *
 * To run a query within a React component, call `useFollowRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowRequestsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowRequestsQuery(
    baseOptions?: Apollo.QueryHookOptions<FollowRequestsQuery, FollowRequestsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FollowRequestsQuery, FollowRequestsQueryVariables>(FollowRequestsDocument, options);
}
export function useFollowRequestsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowRequestsQuery, FollowRequestsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FollowRequestsQuery, FollowRequestsQueryVariables>(FollowRequestsDocument, options);
}
export type FollowRequestsQueryHookResult = ReturnType<typeof useFollowRequestsQuery>;
export type FollowRequestsLazyQueryHookResult = ReturnType<typeof useFollowRequestsLazyQuery>;
export type FollowRequestsQueryResult = Apollo.QueryResult<FollowRequestsQuery, FollowRequestsQueryVariables>;
export const FollowersDocument = gql`
    query Followers($page: Int, $where: FollowWhereInput) {
        followers(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        blurhash {
                            hash
                        }
                        url
                    }
                    incognito
                    nickname
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useFollowersQuery__
 *
 * To run a query within a React component, call `useFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowersQuery(baseOptions?: Apollo.QueryHookOptions<FollowersQuery, FollowersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, options);
}
export function useFollowersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowersQuery, FollowersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, options);
}
export type FollowersQueryHookResult = ReturnType<typeof useFollowersQuery>;
export type FollowersLazyQueryHookResult = ReturnType<typeof useFollowersLazyQuery>;
export type FollowersQueryResult = Apollo.QueryResult<FollowersQuery, FollowersQueryVariables>;
export const FollowingsDocument = gql`
    query Followings($page: Int, $where: FollowWhereInput) {
        followings(page: $page, where: $where) {
            edges {
                node {
                    id
                    fullName
                    avatar {
                        id
                        blurhash {
                            hash
                        }
                        url
                    }
                    incognito
                    nickname
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useFollowingsQuery__
 *
 * To run a query within a React component, call `useFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFollowingsQuery(baseOptions?: Apollo.QueryHookOptions<FollowingsQuery, FollowingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FollowingsQuery, FollowingsQueryVariables>(FollowingsDocument, options);
}
export function useFollowingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FollowingsQuery, FollowingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FollowingsQuery, FollowingsQueryVariables>(FollowingsDocument, options);
}
export type FollowingsQueryHookResult = ReturnType<typeof useFollowingsQuery>;
export type FollowingsLazyQueryHookResult = ReturnType<typeof useFollowingsLazyQuery>;
export type FollowingsQueryResult = Apollo.QueryResult<FollowingsQuery, FollowingsQueryVariables>;
export const GuestsDocument = gql`
    query Guests {
        guests {
            edges {
                node {
                    id
                    fullName
                    role {
                        id
                        name
                    }
                    statusText
                    avatar {
                        id
                        url
                    }
                    member {
                        id
                    }
                }
            }
        }
    }
`;

/**
 * __useGuestsQuery__
 *
 * To run a query within a React component, call `useGuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestsQuery(baseOptions?: Apollo.QueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, options);
}
export function useGuestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, options);
}
export type GuestsQueryHookResult = ReturnType<typeof useGuestsQuery>;
export type GuestsLazyQueryHookResult = ReturnType<typeof useGuestsLazyQuery>;
export type GuestsQueryResult = Apollo.QueryResult<GuestsQuery, GuestsQueryVariables>;
export const UserPostsWithImagesDocument = gql`
    query UserPostsWithImages($userId: String!, $where: PostWhereInput!, $page: Int) {
        userPostsWithImages(userId: $userId, where: $where, page: $page) {
            edges {
                node {
                    id
                    files {
                        totalCount
                        edges {
                            node {
                                id
                                blurhash {
                                    hash
                                }
                                url
                                extension
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useUserPostsWithImagesQuery__
 *
 * To run a query within a React component, call `useUserPostsWithImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPostsWithImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPostsWithImagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      where: // value for 'where'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserPostsWithImagesQuery(
    baseOptions: Apollo.QueryHookOptions<UserPostsWithImagesQuery, UserPostsWithImagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserPostsWithImagesQuery, UserPostsWithImagesQueryVariables>(
        UserPostsWithImagesDocument,
        options,
    );
}
export function useUserPostsWithImagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UserPostsWithImagesQuery, UserPostsWithImagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserPostsWithImagesQuery, UserPostsWithImagesQueryVariables>(
        UserPostsWithImagesDocument,
        options,
    );
}
export type UserPostsWithImagesQueryHookResult = ReturnType<typeof useUserPostsWithImagesQuery>;
export type UserPostsWithImagesLazyQueryHookResult = ReturnType<typeof useUserPostsWithImagesLazyQuery>;
export type UserPostsWithImagesQueryResult = Apollo.QueryResult<
    UserPostsWithImagesQuery,
    UserPostsWithImagesQueryVariables
>;
export const UserDocument = gql`
    query User($userId: String!) {
        user(id: $userId) {
            ...UserParts
        }
        followerCount(where: { userId: $userId })
        followingCount(where: { userId: $userId })
    }
    ${UserPartsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
