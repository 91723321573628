import { Box, Card, IconButton, Tooltip, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import { RatioBox } from 'components/ratio-box';
import { useNotifications } from 'context/NotificationsContext';
import React from 'react';
import colorMXID from 'utils/colorMXID';
import { useStyles } from '../payment-item/payment-item.style';

export const PaymentItem: React.FC<{
    id: string;
    brand: string;
    funding: string;
    lastFour: string;
    expYear: number;
    expMonth: number;
    isDefault: boolean;
    setDefault: any;
    deletePayment: any;
}> = ({ lastFour, brand, isDefault, expMonth, expYear, id, setDefault, deletePayment }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const classes = useStyles();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { confirm } = useNotifications();

    const creditCardBrandIcon = {
        visa: require('assets/icons/visa.svg').default,
        mastercard: require('assets/icons/mastercard.svg').default,
    }[brand];

    return (
        <RatioBox ratio={0.628}>
            <Card className={classes.root} style={{ backgroundColor: colorMXID(id) }}>
                {isDefault && (
                    <Box className={classes.actions}>
                        <Tooltip title="Default payment method" placement="top">
                            <StarOutlineIcon />
                        </Tooltip>
                    </Box>
                )}
                {!isDefault && (
                    <div className={classes.actions}>
                        <IconButton size="small" onClick={handleClick}>
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem
                                onClick={() => {
                                    setDefault(id);
                                    handleClose();
                                }}
                            >
                                Make default
                            </MenuItem>
                            <MenuItem
                                onClick={(): void => {
                                    confirm({
                                        onConfirm: () => {
                                            deletePayment(id);
                                            handleClose();
                                        },
                                        title: 'Delete payment method',
                                        confirmText: 'Are you sure you want to delete this payment method?',
                                    });
                                }}
                            >
                                Delete
                            </MenuItem>
                        </Menu>
                    </div>
                )}
                <Typography className={classes.digits}>**** **** **** {lastFour}</Typography>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    color="#fff"
                    px={2}
                    pb={1}
                >
                    <Typography>
                        {expMonth}/{expYear}
                    </Typography>
                    <img style={{ width: 40, height: 40 }} src={creditCardBrandIcon} alt="payment method brand" />
                </Box>
            </Card>
        </RatioBox>
    );
};
