import { Box, Button, Grid, GridProps } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { CircleIcon, CommunityItem, CommunitySkeleton, EmptyState } from 'components';
import { Community, PageInfo } from 'graphql/generated';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CommunityListProps = {
    communities: Community[];
    renderItem?: (community: Community) => React.ReactNode;
    loading: boolean;
    pageInfo?: PageInfo;
    onLoadMore?: () => void;
    emptyStateText?: string;
    GridItemSizings?: Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
    skeletonCount?: number;
};

export const CommunityList: React.FC<CommunityListProps> = ({
    communities,
    renderItem,
    loading,
    pageInfo,
    onLoadMore,
    emptyStateText,
    GridItemSizings,
    skeletonCount = 4,
}) => {
    const { t } = useTranslation();

    const isEmpty = !loading && communities.length === 0;
    const canLoadMore = pageInfo?.hasNextPage && !loading;

    if (isEmpty) {
        return (
            <EmptyState
                media={<CircleIcon radius={80} icon={<EventBusyIcon style={{ fontSize: 70 }} />} />}
                primaryText={emptyStateText || t('emptyState:community')}
            />
        );
    }

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Grid container spacing={2}>
                {communities.map((community) =>
                    renderItem ? (
                        renderItem(community)
                    ) : (
                        <Grid key={community.id} item xs={12} sm={6} md={12} lg={6} {...GridItemSizings}>
                            <CommunityItem community={community} />
                        </Grid>
                    ),
                )}
                {loading && (
                    <>
                        {Array(skeletonCount)
                            .fill(0)
                            .map((_, index) => (
                                <Grid key={index} item xs={12} sm={6} md={12} lg={6} {...GridItemSizings}>
                                    <CommunitySkeleton />
                                </Grid>
                            ))}
                    </>
                )}
                {canLoadMore && (
                    <Grid item xs={12} container justifyContent="center">
                        <Button onClick={onLoadMore} color="primary">
                            Load more
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
