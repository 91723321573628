import { Box, Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { RatioBox } from 'components/ratio-box';
import { useAuthState } from 'context/auth/store';
import { cardP, cardPMobile, mobileSize } from 'layout-config';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down('xs')]: {
                borderRadius: 0,
            },
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(cardPMobile),
            },
        },
        avatar: {
            width: 40,
            height: 40,
            minWidth: 40,
            minHeight: 40,
        },
    };
});

export const PostSkeleton: React.FC<{ variant?: 'text' | 'image' }> = ({ variant = 'text' }) => {
    const classes = useStyles();
    const { isManager } = useAuthState();
    return (
        <Card className={classes.root}>
            <Box display="flex" className={classes.container}>
                <Skeleton variant="circle" className={classes.avatar} />
                <Box ml={1.5} flexGrow={1}>
                    <Skeleton variant="text" width="40%" />
                </Box>
                {isManager && <Skeleton variant="text" width={36} />}
            </Box>
            {variant === 'image' ? (
                <RatioBox ratio={1}>
                    <Skeleton variant="rect" width="100%" height="100%" />
                </RatioBox>
            ) : (
                <Box p={2}>
                    <Skeleton variant="text" width="90%" style={{ marginBottom: 4 }} />
                    <Skeleton variant="text" width="85%" style={{ marginBottom: 4 }} />
                    <Skeleton variant="text" width="95%" style={{ marginBottom: 4 }} />
                    <Skeleton variant="text" width="30%" style={{ marginBottom: 4 }} />
                </Box>
            )}
            <Box className={classes.container} justifyContent="space-between">
                <Box display="flex">
                    <Skeleton variant="text" width={24} height={24} />
                    <Box ml={1}>
                        <Skeleton variant="text" width={24} height={24} />
                    </Box>
                    <Box ml={1}>
                        <Skeleton variant="text" width={24} height={24} />
                    </Box>
                </Box>
                <Skeleton variant="text" width={70} />
            </Box>
            <Box className={classes.container}>
                <Skeleton variant="circle" className={classes.avatar} />
                <Box ml={1.5} flexGrow={1}>
                    <Skeleton variant="text" width="40%" />
                </Box>
                <Box>
                    <Skeleton variant="text" width={24} height={24} />
                </Box>
            </Box>
            <Box className={classes.container} style={{ paddingTop: 4 }}>
                <Skeleton variant="text" width={70} />
            </Box>
        </Card>
    );
};
