import { Box, Button, Typography } from '@material-ui/core';
import { useSettings } from 'context/SettingsContext';
import { Event } from 'graphql/generated';
import { DateTime } from 'luxon';
import React from 'react';
import browserHistory from 'utils/browser-utils';
import { useStyles } from './event-slider-footer.style';

type EventSliderFooterProps = {
    eventId: Event['id'];
    startTime?: Event['startTime'];
    portalID?: string;
    type?: 'event' | 'experience';
};

export const EventSliderFooter: React.FC<EventSliderFooterProps> = ({
    eventId,
    startTime,
    portalID,
    type = 'event',
}) => {
    const classes = useStyles();
    const { language } = useSettings();

    const navigateToDetails = () => browserHistory.push(`/d/${type}/${eventId}`);

    const lxnStartDate = DateTime.fromISO(startTime);
    const shouldShowTime = lxnStartDate.hour !== 0 || lxnStartDate.minute !== 0;
    const startDate = lxnStartDate
        .setLocale(language)
        .toFormat(shouldShowTime ? 'dd MMMM yyyy, HH:mm' : 'dd MMMM yyyy');

    return (
        <Box className={classes.root} display="flex" justifyContent="space-between" alignItems="center">
            <Box className={classes.dateContainer}>
                {startTime && (
                    <Typography color="textSecondary" variant="subtitle1">
                        {startDate}
                    </Typography>
                )}
            </Box>
            <Box className={classes.dots} id={portalID} />
            <Box className={classes.buttonContainer}>
                <Button onClick={navigateToDetails} color="primary" size="small" variant="outlined">
                    learn more
                </Button>
            </Box>
        </Box>
    );
};
