import { Box } from '@material-ui/core';
import { Post } from 'graphql/generated';
import React from 'react';
import { ReadonlyPostItem } from '../../readonly-post-item';
import { useStyles } from './repost-post-content.style';

type RepostPostContentProps = {
    repost: Post;
};

export const RepostPostContent: React.FC<RepostPostContentProps> = ({ repost }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <ReadonlyPostItem post={repost} readonly={false} portalPrefix="repost" />
        </Box>
    );
};
