import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1.5, 2),
        },
        dateContainer: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: theme.spacing(2),
        },
        dots: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flex: 0,
        },
        buttonContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
    };
});
