import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingButton } from 'components';
import { useNotifications } from 'context/NotificationsContext';
import { PaymentMethodsProvider } from 'context/payment-methods/store';
import { useSettings } from 'context/SettingsContext';
import { useEventPaymentMutation, Event } from 'graphql/generated';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { PaymentMethodSelector } from '../payment-method-selector';
import { useStyles } from './event-payment-dialog.style';
import { SummaryItem } from '../summary-item';

type EventPaymentDialogProps = Omit<DialogProps, 'onClose'> & {
    onClose?: () => void;
    productName: string;
    eventId: string;
    price: Event['price'];
    setBooked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EventPaymentDialog: React.FC<EventPaymentDialogProps> = ({
    productName,
    eventId,
    price,
    setBooked,
    ...props
}) => {
    const classes = useStyles();
    const { notify } = useNotifications();
    const { language } = useSettings();
    const today = DateTime.fromJSDate(new Date()).setLocale(language).toFormat('MMMM dd, yyyy');
    const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

    const [purchase, { loading }] = useEventPaymentMutation({
        onCompleted() {
            notify({ type: 'success', message: 'Payment completed successfully!' });
            props.onClose?.();
            setBooked(true);
        },
        onError(error) {
            notify({ type: 'error', message: error.message });
        },
    });

    const onPurchase = () => {
        if (paymentMethodId) {
            purchase({
                variables: {
                    input: { eventId, paymentMethodId },
                },
            });
        } else {
            notify({ type: 'error', message: 'Please select a payment method.' });
        }
    };

    const onPaymentMethodChange = useCallback((id: string) => setPaymentMethodId(id), []);

    return (
        <Dialog maxWidth="xs" fullWidth {...props}>
            <DialogTitle className={classes.header} disableTypography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box width={36} />
                    <Typography variant="h5">Make a payment</Typography>
                    <IconButton size="small" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box className={classes.details}>
                    <SummaryItem title="Product" text={productName} mb={2} />
                    <SummaryItem title="Price" text={`€ ${price?.toFixed(2)}`} mb={2} />
                    <SummaryItem title="Date" text={today} />
                </Box>
                <PaymentMethodsProvider>
                    <PaymentMethodSelector
                        headerProps={{ className: classes.paymentMethodsHeader }}
                        onPaymentMethodChange={onPaymentMethodChange}
                    />
                </PaymentMethodsProvider>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <LoadingButton
                    disabled={!paymentMethodId || loading}
                    loading={loading}
                    text="Confirm and pay"
                    loadingText="Confirming payment"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={onPurchase}
                />
            </DialogActions>
        </Dialog>
    );
};
