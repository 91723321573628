import { makeStyles } from '@material-ui/core';
import { mobileSize } from 'config';
import { cardP, cardPMobile } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            [theme.breakpoints.down(mobileSize)]: {
                backgroundColor: theme.palette.background.default,
                boxShadow: 'none',
            },
        },
        header: {
            display: 'flex',
            padding: theme.spacing(cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(cardPMobile),
            },
        },
        footer: {
            padding: theme.spacing(cardP),
            paddingBottom: theme.spacing(3.25),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(cardPMobile),
            },
        },
    };
});
