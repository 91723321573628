import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        dialog: {
            borderRadius: 0,
            position: 'relative',
        },
        dialogContent: {
            padding: theme.spacing(6),
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(4),
                paddingTop: theme.spacing(2),
            },
        },
        dialogList: {
            marginTop: theme.spacing(4),
            '& a': {
                textDecoration: 'none',
            },
        },
    };
});
