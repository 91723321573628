import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    IconButton,
    List,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from './contact-dialog.style';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ContactItem } from './contact-item';

type AboutDialogProps = Omit<DialogProps, 'open'> & {
    openContact: boolean;
    setOpenContact: (boolean) => void;
};

const contactItems = [
    { title: 'Email', href: 'mailto:welcome@thelobbylifestyle.com', icon: <EmailIcon fontSize="large" /> },
    { title: 'Phone', href: 'tel:+37126575953', icon: <PhoneIcon fontSize="large" /> },
    {
        title: 'Whatsapp',
        href: 'https://wa.me/+37126575953',
        icon: <WhatsAppIcon fontSize="large" />,
    },
    {
        title: 'Telegram',
        href: 'https://t.me/+37126575953',
        icon: <TelegramIcon fontSize="large" />,
    },
];

export const ContactDialog: React.FC<AboutDialogProps> = ({ openContact, setOpenContact, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpenContact(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={openContact}
            fullScreen={isMobile}
            maxWidth={isMobile ? false : 'sm'}
            classes={{ paper: classes.dialog }}
            fullWidth
            {...props}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
                <IconButton size="small" onClick={() => setOpenContact(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography style={{ marginLeft: 16 }} variant="h3">
                    Contact us
                </Typography>

                <Box mt={2}>
                    <Typography style={{ marginLeft: 16 }}>
                        Feel free to contact THE LOBBY Lifestyle team for any further inquiries and information via:
                    </Typography>
                    <List className={classes.dialogList}>
                        <Grid container spacing={1}>
                            {contactItems.map((contactItem, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <ContactItem {...contactItem} />
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
