import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        header: {
            backgroundColor: darken(theme.palette.background.paper, 0.025),
            padding: theme.spacing(1.5, 2),
        },
        content: {
            padding: 0,
            paddingBottom: theme.spacing(2),
        },
        details: {
            padding: theme.spacing(2, 3),
        },
        paymentMethodsHeader: {
            padding: theme.spacing(0, 3),
        },
        actions: {
            justifyContent: 'center',
            padding: 0,
            paddingTop: theme.spacing(2),
        },
        button: {
            ...theme.typography.h5,
            width: '100%',
            padding: theme.spacing(2, 2.5),
            borderRadius: 0,
            boxShadow: theme.shadows[1],
            borderBottomLeftRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
        },
    };
});
