import { Box, FormControlLabel, InputLabel, Paper, Switch, useTheme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData, StripeCardElementOptions } from '@stripe/stripe-js';
import { LoadingButton } from 'components';
import { useAuthState } from 'context/auth/store';
import { useNotifications } from 'context/NotificationsContext';
import { usePaymentMethodsDispatch } from 'context/payment-methods/store';
import { useAddPaymentMethodMutation } from 'graphql/generated';
import React, { useMemo, useState } from 'react';
import { useStyles } from './add-payment-method-form.style';

type AddPaymentMethodFormProps = {
    onSuccess?: () => void;
};

export const AddPaymentMethodForm: React.FC<AddPaymentMethodFormProps> = ({ onSuccess }) => {
    const classes = useStyles();
    const dispatch = usePaymentMethodsDispatch();
    const { user } = useAuthState();
    const { notify } = useNotifications();
    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();

    const [isDefault, setIsDefault] = useState(false);
    const [loading, setLoading] = useState(false);

    const [addPaymentMethod] = useAddPaymentMethodMutation({
        onCompleted({ addPaymentMethod: { paymentMethod } }) {
            dispatch({ type: 'PAYMENT_METHOD_ADDED', paymentMethod });
            notify({
                type: 'success',
                message: 'Card added succesfully!',
            });
            setLoading(false);
            onSuccess?.();
        },
        onError(error) {
            notify({
                type: 'error',
                message: error.message,
            });
            if (!stripe || !elements) {
                return;
            }

            const card = elements.getElement(CardElement);
            card?.clear();
            setLoading(false);
        },
    });

    const handleAddPaymentMethod = async () => {
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);

        if (!card) {
            return;
        }

        const billingDetails = { email: user?.email };

        const paymentMethodDetails: CreatePaymentMethodCardData = {
            type: 'card',
            card: card,
            billing_details: billingDetails,
        };

        setLoading(true);

        const response = await stripe.createPaymentMethod(paymentMethodDetails);

        if (response.error) {
            notify({
                type: 'error',
                message: 'There was an issue! Check your card details please.',
            });
            setLoading(false);
            card?.clear();
        } else {
            addPaymentMethod({
                variables: {
                    input: {
                        paymentMethod: response.paymentMethod.id,
                        isDefault,
                    },
                },
            });
        }
    };

    const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = useMemo(
        () => ({
            hidePostalCode: true,
            style: {
                base: {
                    fontSize: '16px',
                    color: theme.palette.text.primary,
                },
            },
        }),
        [theme],
    );

    return (
        <Box>
            <Box className={classes.details}>
                <InputLabel className={classes.label}>Card details</InputLabel>
                <Paper variant="outlined" className={classes.paper}>
                    <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
                </Paper>
                <FormControlLabel
                    control={
                        <Switch color="primary" value={isDefault} onChange={(_, checked) => setIsDefault(checked)} />
                    }
                    label="Make this default payment method"
                />
            </Box>
            <LoadingButton
                className={classes.button}
                loading={loading}
                text="Add payment method"
                loadingText="Adding payment method"
                variant="contained"
                color="primary"
                onClick={handleAddPaymentMethod}
            />
        </Box>
    );
};
