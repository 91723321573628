import { Typography } from '@material-ui/core';
import { EventContent } from 'components/event-item';
import { Event, LanguageCode, Post } from 'graphql/generated';
import React from 'react';
import { getTranslationContent } from 'utils/translation-helper';
import {
    CoverPhotoPostContent,
    DefaultPostContent,
    EventPostContent,
    ExperiencePostContent,
    ProfilePicturePostContent,
    RepostPostContent,
} from './post-item/post-content';

export const getActionText = (post: Post): React.ReactElement | null => {
    const metaType = post.meta.__typename;
    switch (metaType) {
        case 'CoverPhotoChangeMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    updated cover photo.
                </Typography>
            );
        case 'ProfilePictureChangeMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    updated profile picture.
                </Typography>
            );
        case 'EventMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    created an event.
                </Typography>
            );
        case 'ExperienceMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    created an experience.
                </Typography>
            );
        case 'RepostMeta':
            return (
                <Typography variant="body1" color="textSecondary" style={{ lineHeight: 1 }}>
                    shared a post.
                </Typography>
            );
        default:
            return null;
    }
};

export const getPostDescription = (post: Post, language: LanguageCode = LanguageCode.En): React.ReactElement | null => {
    const metaType = post.meta.__typename;
    switch (metaType) {
        case 'EventMeta':
            const event = (post.meta as any).event as Event;
            const eventTranslations = event.translations.edges.map(({ node }) => node) || [];
            const { title: eventTitle, description: eventDescription } = getTranslationContent({
                translations: eventTranslations,
                requiredFields: ['title'],
                language,
            });

            return <EventContent title={eventTitle} description={eventDescription} />;
        case 'ExperienceMeta':
            const experience = (post.meta as any).experience as Event;
            const experienceTranslations = experience.translations.edges.map(({ node }) => node) || [];
            const { title: experienceTitle, description: experienceDescription } = getTranslationContent({
                translations: experienceTranslations,
                requiredFields: ['title'],
                language,
            });

            return <EventContent title={experienceTitle} description={experienceDescription} />;
        default:
            return null;
    }
};

export const getPostContent = ({
    post,
    portalPrefix,
}: {
    post: Post;
    portalPrefix?: string;
}): React.ReactElement | null => {
    const metaType = post.meta.__typename;
    switch (metaType) {
        case 'CoverPhotoChangeMeta':
            const coverPhotoUrl = (post.meta as any).coverPhotoUrl;
            return <CoverPhotoPostContent imageUrl={coverPhotoUrl} />;
        case 'ProfilePictureChangeMeta':
            const profilePictureUrl = (post.meta as any).profilePictureUrl;
            return <ProfilePicturePostContent imageUrl={profilePictureUrl} />;
        case 'EventMeta':
            const event: Event = (post.meta as any).event;
            return <EventPostContent event={event} portalPrefix={portalPrefix} />;
        case 'ExperienceMeta':
            const experience: Event = (post.meta as any).experience;
            return <ExperiencePostContent experience={experience} portalPrefix={portalPrefix} />;
        case 'DefaultPostMeta':
            return <DefaultPostContent post={post} portalPrefix={portalPrefix} />;
        case 'RepostMeta':
            const repost = (post.meta as any).post;
            return <RepostPostContent repost={repost} />;
        default:
            return null;
    }
};
