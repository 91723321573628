import { Community, CommunityCategory, JoinRule, Manager } from 'graphql/generated';

export const isCommunity = (category: Community['category']) => {
    return category === CommunityCategory.Community;
};

export const isCommunityPartner = (category: Community['category']) => {
    return !isCommunity(category);
};

export const checkIfUserIsPartOfCommunity = ({
    userCommunities,
    communityId,
}: {
    userCommunities: Community[];
    communityId: Community['id'];
}) => {
    return !!userCommunities.find((uc) => uc.id === communityId);
};

export const checkIfCommunityIsLocked = ({
    userCommunities,
    communityId,
    joinRule,
}: {
    userCommunities: Community[];
    communityId: Community['id'];
    joinRule: Community['joinRule'];
}) => {
    const isUserPartOfCommunity = checkIfUserIsPartOfCommunity({ userCommunities, communityId });
    const isCommunityPrivate = joinRule === JoinRule.Knock || joinRule === JoinRule.Invite;
    return !isUserPartOfCommunity && isCommunityPrivate;
};

export const checkIfUserManagesCommunity = ({
    userCommunities,
    communityId,
    isManager,
}: {
    userCommunities: Community[];
    communityId: Community['id'];
    isManager: boolean;
}) => {
    const isUserPartOfCommunity = checkIfUserIsPartOfCommunity({ userCommunities, communityId });
    return isUserPartOfCommunity && isManager;
};

export const checkIfUserManagesCommunityPartner = ({
    communityManagers,
    me,
    isManager,
}: {
    communityManagers: Manager[];
    me: string | undefined;
    isManager: boolean;
}) => {
    const managerIds = communityManagers.map((manager) => manager.userId);
    const isUserPartOfComnunityPartner = me && managerIds.includes(me);
    return isUserPartOfComnunityPartner && isManager;
};
