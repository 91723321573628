import { darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    return {
        root: {
            height: '100%',
            transition: '.3s filter',
            '&:hover': {
                filter: 'brightness(95%)',
            },
        },
        cover: {
            height: 150,
            backgroundColor: isDarkMode
                ? lighten(theme.palette.background.paper, 0.05)
                : darken(theme.palette.background.paper, 0.1),
        },
        coverImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        content: {
            position: 'relative',
            padding: theme.spacing(2),
            height: 150,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        logo: {
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%, -50%)',
            width: 125,
            height: 125,
            border: `3px solid ${theme.palette.background.paper}`,
        },
    };
});
