import { Box, Grid, IconButton } from '@material-ui/core';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as HeartFilledIcon } from 'assets/icons/heart-filled.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { PostLikesDialog } from 'components/post/post-likes-dialog';
import { RepostedUsersDialog } from 'components/post/reposted-users-dialog';
import { SharePostDialog } from 'components/post/share-post-dialog';
import { Post, useDislikePostMutation, useLikePostMutation } from 'graphql/generated';
import React, { useState } from 'react';
import { useStyles } from './post-actions.style';

type PostActionsProps = {
    post: Post;
    inputRef: React.RefObject<HTMLInputElement>;
    portalPrefix?: string;
};

export const PostActions: React.FC<PostActionsProps> = ({ post, inputRef, portalPrefix }) => {
    const classes = useStyles();
    const { id, isLiked, likeCount, repostCount } = post;
    const [like, setLike] = useState({ isLiked, likeCount });

    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const openShareDialog = (): void => setShareDialogOpen(true);
    const closeShareDialog = (): void => setShareDialogOpen(false);

    const [likePost] = useLikePostMutation({
        onCompleted(result) {
            const likeCount = result.likePost.likeCount;
            const isLiked = true;
            setLike({ isLiked, likeCount });
        },
    });

    const [dislikePost] = useDislikePostMutation({
        onCompleted(result) {
            const likeCount = result.dislikePost.likeCount;
            const isLiked = false;
            setLike({ isLiked, likeCount });
        },
    });

    const handleLike = (): void => {
        if (like.isLiked) {
            dislikePost({ variables: { post: { postId: id } } });
        } else {
            likePost({ variables: { post: { postId: id } } });
        }
    };

    const isRepost = post.meta.__typename === 'RepostMeta';

    return (
        <Box>
            <Grid container>
                <Grid item xs={5}>
                    <Box className={classes.actions}>
                        <IconButton size="small" onClick={handleLike}>
                            {like.isLiked ? (
                                <HeartFilledIcon className={classes.likeActive} />
                            ) : (
                                <HeartIcon className={classes.icon} />
                            )}
                        </IconButton>
                        <IconButton size="small" onClick={() => inputRef.current?.focus()}>
                            <CommentIcon />
                        </IconButton>
                        <IconButton size="small" onClick={openShareDialog}>
                            <SendIcon />
                        </IconButton>
                        {!isRepost && <SharePostDialog post={post} open={shareDialogOpen} onClose={closeShareDialog} />}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        id={`${portalPrefix || ''}post-portal-${post.id}`}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Box height="100%" display="flex" justifyContent="flex-end" alignItems="center">
                        <PostLikesDialog likeCount={like.likeCount} postId={id} />
                        {repostCount > 0 && <RepostedUsersDialog repostCount={repostCount} postId={id} />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
