import { makeStyles } from '@material-ui/core/styles';
import { mobileSize } from 'layout-config';

export const useStyle = makeStyles((theme) => {
    return {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 0,
            minHeight: 40,
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down(mobileSize)]: {
                marginTop: theme.spacing(2),
            },
        },
        rightContainer: {
            justifyItems: 'flex-end',
        },
    };
});
