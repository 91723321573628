import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            flexDirection: 'column',
            paddingBottom: theme.spacing(1),
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                borderRadius: 0,
                minHeight: 'unset',
            },
        },
        headerContainer: { padding: theme.spacing(0, 2) },
        title: {
            padding: 12,
            paddingLeft: 0,
            color: theme.palette.primary.main,
            display: 'flex',
            flex: 1,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        city: {
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(0),
            },
        },
        detail: {
            padding: theme.spacing(0, 2),
        },
    };
});
