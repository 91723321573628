import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            overflow: 'hidden',
            borderRadius: 0,
            boxShadow: 'none',
            height: 650,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                borderBottom: `1px solid ${theme.palette.divider}`,
                height: 450,
            },
        },
        slider: {
            width: '55%',
            borderBottomRightRadius: '150px',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                borderRadius: 0,
            },
        },
        content: {
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(5),
            minHeight: '100%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: theme.spacing(3),
            },
        },
        description: {
            ...theme.typography.body1,
            margin: theme.spacing(4, 0),
            flex: 1,
            overflowY: 'auto',
        },
    };
});
