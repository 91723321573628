import { makeStyles } from '@material-ui/core/styles';
import { cardP, cardPMobile, mobileSize } from 'layout-config';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(0, cardP),
            [theme.breakpoints.down(mobileSize)]: {
                padding: theme.spacing(0, cardPMobile),
            },
        },
    };
});
