import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 20,
            transition: '0.2s',
        },
        cardIcon: {
            width: 40,
            height: 40,
            bottom: 0,
            right: 20,
            position: 'absolute',
        },
        cardBrand: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
        },
        actions: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            '& svg': {
                color: '#fff',
            },
        },
        digits: {
            fontSize: 16,
            whiteSpace: 'nowrap',
            color: '#fff',
        },
    };
});
